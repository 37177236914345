import { secondsToTime } from "../server/components/utils"
let headToHeadTimer = null
let headToHeadInput = document.getElementById('head-to-head-input')
let headToHeadResults = document.getElementById('head-to-head-results')
if(headToHeadInput)
{
	headToHeadInput.addEventListener("input", (e) => {
		if(headToHeadTimer != null) clearTimeout(searchTimer)

		headToHeadTimer = setTimeout(function(terms) {
			headToHeadTimer = null

			if(terms == undefined || terms.trim() == "") {
				headToHeadResults.classList.add("hide")
				return
			} 
			fetch("/api/search/" + terms, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data_search) {
				let parent = headToHeadResults
				while (parent.firstChild) {
					parent.removeChild(parent.firstChild);
				}
				let count = 1
				if(data_search.results.length || data_search.races.length)
				{
					if(!data_search.results)
					{
						headToHeadResults.classList.add("hide")
					}
					for(let r of data_search.results)
					{
						let a = document.createElement('a')
						a.href = '/head-to-head/' + headToHeadInput.dataset.link + '/' + r.link
						a.innerText = r.name
						a.tabIndex = -1
						a.addEventListener("click", function() {
							headToHeadInput.value = ""
						})
						headToHeadResults.appendChild(a);
						headToHeadResults.classList.remove("hide")
						count += 1
					}
				}
				else
				{
					let parent = headToHeadResults
					while (parent.firstChild) {
						parent.removeChild(parent.firstChild);
					}
					headToHeadResults.classList.add("hide")
				}
			})
		}, 250, e.target.value)
	})

	document.addEventListener("mouseup", (e) => {
		if(!headToHeadResults.classList.contains("hide"))
		{
			let withinSearch = e.target.closest(".search")
			if(!withinSearch) headToHeadResults.classList.add("hide")
		}
	})

	document.addEventListener("keydown", (e) => {
		if(!headToHeadResults.classList.contains("hide"))
		{
			let choices = Array.from(headToHeadResults.getElementsByTagName("a"))
			let selected = choices.findIndex(x => x.classList.contains("selected"))
			if(e.code == "ArrowDown")
			{
				if(selected != -1) choices[selected].classList.remove("selected")
				if(selected == -1 || selected == choices.length - 1) { choices[0].classList.add("selected"); }
				else { choices[selected + 1].classList.add("selected"); }
				e.preventDefault()
			}
			else if(e.code == "ArrowUp")
			{
				if(selected != -1) choices[selected].classList.remove("selected")
				if(selected == -1 || selected == 0) { choices[choices.length - 1].classList.add("selected"); }
				else { choices[selected - 1].classList.add("selected"); }
				e.preventDefault()
			}
			else if(e.code == "Enter")
			{
				headToHeadInput.value = ""
				if(selected != -1) choices[selected].click()
				e.preventDefault()
			}
			else if(e.code == "Escape")
			{
				headToHeadResults.classList.add("hide")
				e.preventDefault()
			}
		}
	})
}


function updateTimePredictor(tp)
{
	let domLeft = document.getElementById(tp.dataset.domleft)
	let domRight = document.getElementById(tp.dataset.domright)

	let time = parseInt(tp.value * 60)
	let time2 = parseInt(time / tp.dataset.ratio)

	let classLeft = "time-up", classRight = "time-down"
	if(time > time2)
	{
		classLeft = "time-down"
		classRight = "time-up"
	}


	domLeft.innerHTML =  `<span class=${classLeft}>(${secondsToTime(time-time2)})</span> ${secondsToTime(time)}`
	domRight.innerHTML =  `${secondsToTime(time2)} <span class=${classRight}>(${secondsToTime(time2-time)})</span>`
}

let timePredictors = document.getElementsByClassName("time-predictor")
if(timePredictors)
{
	for(let tp of timePredictors)
	{
		tp.addEventListener("input", (e) => {
			updateTimePredictor(e.target)
		})
		updateTimePredictor(tp)
	}
}