let formAverageTimes = document.getElementById('form-averagetimes')
if(formAverageTimes)
{
	let selects = Array.from(formAverageTimes.getElementsByTagName("select"))
	selects.forEach( function(element, index) {
		element.addEventListener("change", (e) => {
			let btn = formAverageTimes.querySelector("button[type=submit]")
			if(btn) btn.click()
		})
	});
}
