import { h, render, Component, createRef, Fragment } from 'preact'
import { useState, useEffect, useLayoutEffect } from 'preact/hooks'
import { secondsToTime } from "../server/components/utils"
import { CSSTransition } from 'preact-transitioning'
import Router from 'preact-router'
import { route } from 'preact-router';

class VideoPlayer extends Component {

	state = { time: 0, paused: true }

	componentWillMount()
	{
		document.addEventListener('keydown', this.onKeyDown)
		document.addEventListener('fullscreenchange', this.onFullscreenChange)
	}
	componentWillUnmount()
	{
		document.removeEventListener('keydown', this.onKeyDown);
		document.removeEventListener('fullscreenchange', this.onFullscreenChange)
	}

	onFullscreenChange = e => {
		this.setState({ fullscreen: document.fullscreenElement })
	}

	onKeyDown = e => {
		if(!this.video) return

		if(e.code == "ArrowRight")
		{
			this.video.currentTime += 5
		}
		else if(e.code == "ArrowLeft")
		{
			this.video.currentTime -= 5
		}
		else if(e.code == "Space")
		{
			this.videoPlayPause()
		}

		this.setState({ 
			time: this.video.currentTime, 
			timePercent: this.video.currentTime / this.video.duration
		})
		e.preventDefault()
	}

	hideUI = () => {
		if(this.hideUITimeout) clearTimeout(this.hideUITimeout)
		this.hideUITimeout = null
		if(this.state.scrubbing) return
		this.setState({ showUI: false, showPreview: false })
	}

	playerRef = player => {
		this.player = player
		if(!player) return
		player.addEventListener('mousemove', e => {
			this.setState({ showUI: true })
			if(this.hideUITimeout) clearTimeout(this.hideUITimeout)
			this.hideUITimeout = setTimeout(this.hideUI, 3000)
		})
		player.addEventListener('mouseleave', e => {
			this.hideUI()
		})
	}

	getVideoId() { return this.videoId }

	loadVideoId(video)
	{
		this.videoTitle = video.name
		this.videoId = video.videoId
		this.videoItem = video
		//let videoSrc = `https://videodelivery.net/${this.videoId}/manifest/video.m3u8`
		let videoSrc = `https://content.protriathletes.org/videos/${this.videoId}/master.m3u8`
		if(this.hls) this.hls.destroy()

		console.log("Hls.isSupported", Hls.isSupported())

		if(Hls.isSupported())
		{
			let hls = new Hls({
				startLevel: 4,
				enableWorker: true,
				lowLatencyMode: true,
				backBufferLength: 90,
	  			//debug: true
			})

			this.hls = hls

		
			hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
				//hls.autoLevelEnabled = false
				console.log(data.levels)
				this.setState({ levels: data.levels })	
				let p = this.video.play()

				if (p !== undefined) {
					p.then(_ => {
						console.log("autoplay success")
					}).catch(error => {
						this.setState({ showPlayManual: true })
						console.log("autoplay failed")
					})
				}
	        })		
		
			hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
				console.log(event, data)
				this.setState({ current_level: data.level })	
			})

			hls.on(Hls.Events.LEVEL_UPDATED, (event, data) => {
				console.log(event, hls.type, data.details.totalduration, data)
			})

			hls.on(Hls.Events.ERROR, (event, data) => {
				console.log(event, data)
			})

			hls.attachMedia(this.video)
			hls.loadSource(videoSrc)
		}
		else
		{
			this.video.src = videoSrc
		}
	}
	
	videoRef = video => {
		this.video = video

		if(!video) return

		this.setState({ muted: this.video.muted}) //, preview: 'https://content.protriathletes.org/vod/__test2__/preview.jpg'})

		//video.oncanplaythrough = function() { console.log("oncanplaythrough", this.duration); };

		video.onabort = function() { console.log("onabort"); };
		video.onemptied = function() { console.log("onemptied"); };
		video.onended = function() { console.log("onended"); };
		video.onerror = function() { console.log("onerror"); };
		video.onloadstart = function() { console.log("onloadstart"); };
		//video.onplaying = function() { console.log("onplaying"); };
		video.onratechange = function() { console.log("onratechange"); };
		video.onreadystatechange = function() { console.log("onreadystatechange"); };
		video.onsuspend = function() { console.log("onsuspend"); };
		video.onvolumechange = function() { console.log("onvolumechange"); };

		video.oncanplay = function() { 
			//console.log("oncanplay", this.duration);
		};


		video.onstalled = function() { 
			console.log("onstalled"); 
		};

		video.onwaiting = () => { 
			this.setState({ waiting: true })
			console.log("onwaiting"); 
		};

		video.targetTime = -1


		let that = this

		video.onprogress = (v) => { 
			let buffered = v.target.buffered
			let end = v.target.currentTime
			for(let tri = 0; tri < buffered.length; tri++)
			{
				if(end >= buffered.start(tri) && end < buffered.end(tri))
				{
					end = buffered.end(tri)
					break				
				}
			}
			this.setState({ 
				buffered: end,
				bufferedPercent: end / v.target.duration 
			})
		}


		video.ontimeupdate = (v) => { 
			if(this.state.scrubbing) return

			/*let buffered = v.target.buffered
			let end = v.target.currentTime
			for(let tri = 0; tri < buffered.length; tri++)
			{
				if(end >= buffered.start(tri) && end < buffered.end(tri))
				{
					end = buffered.end(tri)					
				}
			}*/

			if(this.video._seeking || this.state.waiting) return

			//console.log("ontimeupdate", v.target.currentTime, v.target.seeking)

			this.setState({ 
				time: v.target.currentTime, 
				timePercent: v.target.currentTime / v.target.duration, 
				//buffered: end,
				//bufferedPercent: end / v.target.duration 
			})
		}

		video.onseeking = e => {
			console.log("onseeking")
			e.target._seeking = true
		}
		video.onseeked = e => { 
			e.target._seeking = false
			console.log("seeked")
			if(e.target.targetTime >= 0)
			{
				e.target.currentTime = e.target.targetTime
				e.target.targetTime = -1
			}
		}

		video.onloadedmetadata = e => {
			console.log("onloadeddata"); 
			if(!Hls.isSupported())
			{
				let p = video.play()
				if (p !== undefined) {
					p.then(_ => {
						console.log("autoplay success")
					}).catch(error => {
						this.setState({ showPlayManual: true, waiting: false })
						console.log("autoplay failed")
					})
				}
			}

			this.setState({ time: 0, timePercent: 0, duration: this.video.duration })
		}

		video.onplay = e => { 
			this.setState({ paused: false }) 
		}
		video.onplaying = e => { 
			//console.log("onplaying", )
			if(this.hideUITimeout) clearTimeout(this.hideUITimeout)
			this.hideUITimeout = setTimeout(this.hideUI, 1000)
			this.setState({ paused: false, waiting: false }) 
			if(this.state.showPlayManual && !e.target.paused)
			{
				this.setState({ showPlayManual: false }) 
			}
		}
		video.onpause = e => { 
			this.setState({ paused: true }) 
		}
		video.ondurationchange = e => { this.setState({ duration: e.target.duration }) }
	}

	videoPlayPause = e => {
		if(e && this.state.showLevel)
		{
			this.setState({ showLevel: false })
			return
		}
		this.setState({ showPaused: false })
		clearTimeout(this.timerShowPaused)
		if(this.video.paused) {
			this.video.play()
			this.setState({ paused: true })
			this.timerShowPaused = setTimeout(() => { this.setState({ showPaused: true })}, 15)
		}
		else {
			if(this.hideUITimeout) clearTimeout(this.hideUITimeout)
			this.hideUITimeout = null
			this.video.pause()
			this.setState({ paused: false })
			this.timerShowPaused = setTimeout(() => { this.setState({ showPaused: true })}, 15)
		}
	}

	videoSetTimePercent = tp => {
		this.video.currentTime = this.video.duration * tp
		this.setState({ 
			time: this.video.duration * tp, 
			timePercent: tp
		})
	}

	videoMute = e => {
		this.video.muted = !this.video.muted
		//if(this.video.volume) this.video.volume = 0
		//else this.video.volume = 1
		this.setState({ muted: this.video.muted })
	}

	videoFullscreen = e => {
		if(!this.player) return

		if(!this.player.requestFullscreen)
		{
			this.video.webkitEnterFullscreen()
			return
		}

		if (!document.fullscreenElement) 
		{
			this.player.requestFullscreen()
		}
		else 
		{
			if (document.exitFullscreen) document.exitFullscreen()
		}
	}

	videoPIP = e => {
		if(!this.player) return
		if (!document.pictureInPictureElement) 
		{
			this.video.requestPictureInPicture()
		}
		else 
		{
			if (document.exitPictureInPicture) document.exitPictureInPicture()
		}
	}

	updateThumbnailPreview = (time) => {

		let offset = Math.ceil(299 * time / this.video.duration) 
		let fps = this.videoItem.fps
		let numFrames = Math.floor(fps * this.video.duration) - 2
		let skipFrames = Math.ceil(numFrames / 300)
		let newtime = (offset * skipFrames + 2) / (fps)
		
		//console.log(100 * offset, offset, fps, numFrames, skipFrames)

		let thumbTime_full = time || this.video.currentTime
		let thumbTime = Math.floor(thumbTime_full)
		let thumbDuration = 30
		this.setState({ 
			//previewUrl: `https://videodelivery.net/${this.videoId}/thumbnails/thumbnail.jpg?time=${thumbTime - (thumbTime % 30)}s&duration=${thumbDuration}s&height=90&imageCount=30`,
			previewUrl: `https://content.protriathletes.org/videos/${this.videoId}/preview.jpg`,
			previewImageTranslate: -90 * offset, //-(thumbTime % 30) * 90, 
			previewTranslate: Math.max(80, Math.min(this.progressbar.offsetWidth - 80, this.progressbar.offsetWidth * thumbTime_full / this.video.duration)) - 80,
			previewTime: secondsToTime(thumbTime)
		})


	}
 
	scrubMove = e => {
		let el = this.progressbar
		let startX = 0

		while(el)
		{
			startX += el.offsetLeft
			el = el.offsetParent
		}

		let pageX = e.pageX || parseInt(e.touches[0].pageX);

		let x = Math.max(0, Math.min(this.progressbar.offsetWidth, pageX - startX))
		//console.log("moveScrub", x / this.progressbar.offsetWidth)
		let targetTime = this.video.duration * x / this.progressbar.offsetWidth
		this.updateThumbnailPreview(targetTime)
		this.video.targetTime = targetTime
		/*if(this.video.seeking)
		{
			this.video.targetTime = targetTime
		}
		else
		{
			this.video.currentTime = targetTime
		}*/
		//console.log("scrubMove", targetTime, this.video.currentTime, this.video.seeking)
		this.setState({ 
			time: targetTime, 
			timePercent: targetTime / this.video.duration
		})
		e.preventDefault()
	}


	progressbarRef = pb => {
		if(!pb) return

		this.progressbar = pb
		/*pb.addEventListener('touchstart', e => {
			console.log("touchstart")
		})*/ 

		let iOS = navigator.maxTouchPoints != 0
		let eventDown = iOS ? "touchstart" : "pointerdown"
		let eventMove = iOS ? "touchmove" : "pointermove"
		let eventUp = iOS ? "touchend" : "pointerup"


		pb.addEventListener(eventDown, e => { 
			this.setState({ showPreview: true, showUI: true })
			//console.log(eventDown)

			this.setState({ scrubbing: true })
			this.scrubMove(e)
			document.addEventListener(eventMove, this.scrubMove, { passive: false })

			document.addEventListener(eventUp, e => {
				//console.log(eventUp)
				this.setState({ scrubbing: false })
				this.video.currentTime = this.video.targetTime
				if(iOS) { this.setState({ showPreview: false }) }
				document.removeEventListener(eventMove, this.scrubMove)
			}, { once: true, capture: false })
		})



		pb.addEventListener('mouseover', e => {
			this.setState({ showPreview: true })
		})
		pb.addEventListener('mouseleave', e => {
			this.setState({ showPreview: false })
		})

		if(!iOS)
		{
			pb.addEventListener('pointermove', e => {
				let el = this.progressbar
				let startX = 0

				while(el)
				{
					startX += el.offsetLeft
					el = el.offsetParent
				}

				let x = Math.max(0, Math.min(this.progressbar.offsetWidth, e.pageX - startX)) / this.progressbar.offsetWidth
				let thumbTime = this.video.duration * x
				this.updateThumbnailPreview(thumbTime)
				/*let thumbDuration = 30
				this.setState({ 
					previewUrl: `https://videodelivery.net/${this.videoId}/thumbnails/thumbnail.jpg?time=${thumbTime - (thumbTime % 30)}s&duration=${thumbDuration}s&height=90&imageCount=30`,
					previewImageTranslate: -(thumbTime % 30) * 90, 
					previewTranslate: Math.max(80, Math.min(this.progressbar.offsetWidth - 80, e.pageX - startX)) - 80,
					previewTime: secondsToTime(Math.floor(x * this.video.duration))
				})*/
			}) 
		}
	}

	settings = e => {
		this.setState({ showLevel: !this.state.showLevel })
	}

	setLevel = e => {
		let level = e.target.dataset.level
		this.hls.currentLevel = level
		this.setState({ showLevel: false })
		e.preventDefault()		
	}

	render(props, state)
	{
		//const [count, setCount] = useState(0);
		/*useLayoutEffect(() => {
			console.log("effect", state.showPaused, this)
		}, [state.showPaused]);*/
		//console.log(state.showUI)
		return(
			<div class='pto-video-player' ref={this.playerRef} showUI={state.showUI || state.paused}>
				<div class='mx-auto position-relative' style='min-height: 200px; width: 100%; height: calc(100vh - 140px); max-height: calc(56.25vw)'>
					<div style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;'>
						<video autoplay playsinline ref={this.videoRef} onClick={this.videoPlayPause}/>
						<div class='pto-video-feedback-container'>
							<div class="pto-video-waiting" show={state.waiting}><div class="pto-video-waiting-icon"><div></div><div></div><div></div><div></div></div></div>
							<div class='pto-video-paused' style={state.showPaused ? "" : "display: none;"}>
								<div class=''><i class={"fa " + (state.paused ? "fa-pause" : "fa-play")}></i></div>
							</div>
							{ state.showPlayManual &&
								<div class='pto-video-play-manual'>
									<i class={"fa fa-play"}></i>
								</div>
							}
						</div>
						<div class='pto-video-controls-container' hide={!state.paused && !state.showUI && !state.showLevel}>
							<div class='pto-video-progressbar-container' ref={this.progressbarRef}>
								<div class='pto-video-progressbar-padding'></div>
								<div class='pto-video-progressbar-all'></div>
								<div class='pto-video-progressbar-downloaded' style={`width: ${100*state.bufferedPercent}%`}></div>
								<div class='pto-video-progressbar-play' style={`width: ${100*state.timePercent}%`}></div>
								<div class='pto-video-preview' hidden={!state.showPreview || state.showLevel} style={`transform: translateX(${state.previewTranslate}px)`}>
									<div class='pto-video-preview-image'>
										<img src={state.previewUrl} style={`transform: translateY(${state.previewImageTranslate}px)`}/>
									</div>
									<div class='pto-video-preview-time'>{state.previewTime}</div>
								</div>
							</div>
							<div class='pto-video-controls'>
								<div class='pto-video-controls-left'>
									<div class='pto-video-controls-playpause' onClick={this.videoPlayPause}>
										<i class={"fas " + (state.paused ? "fa-play" : "fa-pause")}></i>
									</div>
									<div class='pto-video-controls-mute' onClick={this.videoMute}>
										<i class={"fas " + (state.muted ? "fa-volume-mute" : "fa-volume-up")}></i>
									</div>

									{state.duration && <div class='pto-video-controls-time'>
										{secondsToTime(Math.floor(state.time)).replace("--:--","00:00")} / {secondsToTime(Math.floor(state.duration))}
									</div>}
								</div>
								<div class='pto-video-controls-right'>
									{Hls.isSupported() && <div class='pto-video-controls-settings' onClick={this.settings}>
										<i class="fas fa-cog"></i>
									</div>}
									<div class='pto-video-controls-fullscreen' onClick={this.videoFullscreen}>
										<i class={"fas " + (state.fullscreen ? "fa-compress" : "fa-expand")}></i>
									</div>
									<div class='pto-video-controls-pip' onClick={this.videoPIP}>
										<i class="fas fa-external-link-alt"></i>
									</div>
								</div>
							</div>
						</div>
						<div class='pto-video-menus' hide={!state.paused && !state.showUI && !state.showLevel}>
							<div class='pto-video-level' hidden={!state.showLevel}>
								<div class='head'>Quality</div>
								{state.levels && state.levels.map((x, index) => {
									return(<div class='option' data-level={index} onClick={this.setLevel}><div class='checked'>{state.current_level == index && <i class="fas fa-check"></i>}</div>{x.height}p</div>)
								})}
							</div>
						</div>
					</div>
				</div>
				<div class='pto-video-player-details'>
					<div class='pto-video-player-title'>{this.videoTitle}</div>	
				</div>
			</div>
		)
	}
}

class VOD extends Component
{

	playlist = [
		{ videoId: "f48cf58f-613c-42ab-828e-2e15198513ee", duration: 544, fps: 25, name: "G.O.A.T. JAN FRODENO", thumbnail: "https://content.protriathletes.org/media/content/test/c648a868-b54f-4ad2-91e3-079957ff4d8d-thumb.jpg"},
		{ videoId: "f4cdd4f5-939f-46c5-8eaa-0ca9762b52a1", duration: 3016, fps: 25, name: "COLLINS CUP: HIGHLIGHTS", thumbnail: "https://content.protriathletes.org/media/content/test/0f18856c-d168-4c34-80e5-ecf63beeb5ea-thumb.jpg"},
		{ videoId: "7b2e1831-1378-4f1e-b673-c78154879e49", duration: 25933, fps: 25, name: "COLLINS CUP FULL REPLAY", thumbnail: "https://content.protriathletes.org/media/content/test/50545888-31f7-4db2-8c18-13fd6793c631-thumb.jpg"},
		{ videoId: "f685fe94-dbcf-4f94-8c49-eeccde2a2b3f", duration: 1580, fps: 25, name: "BEYOND HUMAN | EPISODE 02", thumbnail: "https://content.protriathletes.org/media/content/test/4b5b2866-6bd2-443c-9150-ad2a4dab2916-thumb.jpg"},
		{ videoId: "d4b25c28-0ee0-4f7e-8c7f-6c1210cb629e", duration: 3495, fps: 25, name: "COLLINS CUP: OPENING CEREMONY", thumbnail: "https://content.protriathletes.org/media/content/test/47ef6b6a-8cf9-4e38-9939-910b2ad623fe-thumb.jpg"},
		{ videoId: "2985f3ed-d7cf-467e-b8f9-e3658bf36064", duration: 1297, fps: 25, name: "COUNTDOWN TO THE COLLINSCUP EP02", thumbnail: "https://content.protriathletes.org/media/content/test/34f72e04-524d-4c47-ab60-c5abf1637c68-thumb.jpg"},
		{ videoId: "12f8006d-dffb-4f75-b29e-a5c615955b12", duration: 3131, fps: 25, name: "BEYOND HUMAN | EPISODE 01", thumbnail: "https://content.protriathletes.org/media/content/test/b6736165-75a2-4891-aa9a-73508844b038-thumb.jpg" },
		{ videoId: "078a4719-5ab7-44e6-bbc1-99a08a4ba097", duration: 1333, fps: 25, name: "COUNTDOWN TO THE COLLINSCUP EP01", thumbnail: "https://content.protriathletes.org/media/content/test/35321f4a-7b40-432e-b38f-b1c7e7ed5802-thumb.jpg"},
		{ videoId: "78ae927b-368e-4b25-af5f-8acf91bc2f97", duration: 710, fps: 25, name: "BATTLE FOR GLORY EPISODE 06: ELLIE SALTHOUSE", thumbnail: "https://content.protriathletes.org/media/content/test/569629c7-d200-48d9-8504-6e4156a7543f-thumb.jpg" },
		{ videoId: "2117db21-5679-457a-91c6-fd1af7a35d83", duration: 600, fps: 25, name: "BATTLE FOR GLORY EPISODE 05: CAM WURF", thumbnail: "https://content.protriathletes.org/media/content/test/ea838348-8322-4c8e-90e7-753a409add0d-thumb.jpg"},
		{ videoId: "2eaced56-0f39-4df3-bd42-2c2a087dd967", duration: 800, fps: 23.98, name: "BATTLE FOR GLORY EPISODE 04: SKYE MOENCH", thumbnail: "https://content.protriathletes.org/media/content/test/44b01b14-aa31-4593-abce-cafab0ab05c3-thumb.jpg" },
		{ videoId: "3c9c882a-fffe-4da6-afd9-75916b5e9b8c", duration: 600, fps: 25, name: "BATTLE FOR GLORY EPISODE 03: JAVIER GOMEZ", thumbnail: "https://content.protriathletes.org/media/content/test/580c8482-cb82-4438-9943-9460ad8805f3-thumb.jpg" },
		{ videoId: "f75d887e-a393-4ab0-a664-7b9b26880807", duration: 652, fps: 25, name: "BATTLE FOR GLORY EPISODE 02: RUDY VON BERG", thumbnail: "https://content.protriathletes.org/media/content/test/f25a977a-8e4e-41b7-99f8-89547fd2aa38-thumb.jpg" },
		{ videoId: "76dc7a8d-3ed9-475d-ab3e-b537380d8ba9", duration: 690, fps: 25, name: "BATTLE FOR GLORY EPISODE 01: LUCY CHARLES-BARCLAY", thumbnail: "https://content.protriathletes.org/media/content/test/92daa916-b393-46af-8270-6b5b2bf9b067-thumb.jpg" },
		{ videoId: "e93aea39-2f78-4a8e-9a74-4e69c9a62ace", duration: 648, fps: 25, name: "GREATEST OF ALL TIME: DANIELA RYF", thumbnail: "https://content.protriathletes.org/media/content/test/fc392e0e-d280-416f-89c0-2eb075174f25-thumb.jpg" },
		{ videoId: "2f9137dc-5ee5-43ad-b073-83b22fef1776", duration: 60, fps: 25, name: "WHO RULES TRIATHLON", thumbnail: "https://content.protriathletes.org/media/content/test/67d41714-ce14-43e5-b8d0-c2a5aabdf3c6-thumb.jpg"},
		{ videoId: "fc8f0e69-786c-470a-8e14-7aa10e4204da", duration: 127, fps: 25, name: "COLLINS CUP EXPLAINER", thumbnail: "https://content.protriathletes.org/media/content/test/64415ff6-658a-47bf-8044-b22fad67c3ab-thumb.jpg"},
		{ videoId: "fbe809ff-9b81-4f40-88df-b3f7aefff817", duration: 693, fps: 25, name: "GREATER THAN ONE EP02: REALITY", thumbnail: "https://content.protriathletes.org/media/content/test/dc51ed92-4225-417f-bfae-748fe4e28fbe-thumb.jpg" },
		{ videoId: "1cce7549-f604-4792-a24a-16d6bf4419ed", duration: 570, fps: 25, name: "GREATER THAN ONE EP01: DREAMS", thumbnail: "https://content.protriathletes.org/media/content/test/9ed9bc4d-8936-4bdb-b696-f8aed0bd1877-thumb.jpg"},
		/*{ videoId: "", duration: 0, name: "", thumbnail: ""},*/
	]

	/*playlist = [
		{ videoId: "7697fb296c269e0748817fe31d0a1c7e", duration: 3016, name: "COLLINS CUP: HIGHLIGHTS", thumbnail: "https://videodelivery.net/7697fb296c269e0748817fe31d0a1c7e/thumbnails/thumbnail.jpg?time=0m02s&height=270"},
		{ videoId: "47c3ee7aa31e64717e2e5dee50edb6ba", duration: 25933, name: "COLLINS CUP FULL REPLAY", thumbnail: "https://videodelivery.net/47c3ee7aa31e64717e2e5dee50edb6ba/thumbnails/thumbnail.jpg?time=9m24s&height=270"},
		{ videoId: "38ff342cc9c69533ef32b883b7847b0f", duration: 1580, name: "BEYOND HUMAN | EPISODE 02", thumbnail: "https://content.protriathletes.org/media/content/test/4b5b2866-6bd2-443c-9150-ad2a4dab2916-thumb.jpg"},
		{ videoId: "7dc002a4c6a15ea058332e6dd1f0958a", duration: 3495, name: "COLLINS CUP: OPENING CEREMONY", thumbnail: "https://videodelivery.net/7dc002a4c6a15ea058332e6dd1f0958a/thumbnails/thumbnail.jpg?time=50m01s&height=270"},
		{ videoId: "c1131dc3cf51fa7ab7d2b7ec268ac4ac", duration: 1297, name: "COUNTDOWN TO THE COLLINSCUP EP02", thumbnail: "https://videodelivery.net/c1131dc3cf51fa7ab7d2b7ec268ac4ac/thumbnails/thumbnail.jpg?time=0s&height=270"},
		{ videoId: "795c42f20e4d36b2ec1d5f963f9d56ff", duration: 3131, name: "BEYOND HUMAN | EPISODE 01", thumbnail: "https://content.protriathletes.org/media/content/test/b6736165-75a2-4891-aa9a-73508844b038-thumb.jpg" },
		{ videoId: "99a2a9d332747d8073d4589af13bcf9f", duration: 1333, name: "COUNTDOWN TO THE COLLINSCUP EP01", thumbnail: "https://videodelivery.net/99a2a9d332747d8073d4589af13bcf9f/thumbnails/thumbnail.jpg?time=3m07s&height=270"},
		{ videoId: "d75ff81ccfb9c94369ce1e87ea201fb6", duration: 710, name: "BATTLE FOR GLORY EPISODE 06: ELLIE SALTHOUSE", thumbnail: "https://content.protriathletes.org/media/content/test/569629c7-d200-48d9-8504-6e4156a7543f-thumb.jpg" },
		{ videoId: "5a1c7251c2015f8896218e813280e22c", duration: 600, name: "BATTLE FOR GLORY EPISODE 05: CAM WURF", thumbnail: "https://content.protriathletes.org/media/content/test/ea838348-8322-4c8e-90e7-753a409add0d-thumb.jpg"},
		{ videoId: "0ac5c348d2f7bf3af59ffc909feaa8f2", duration: 800, name: "BATTLE FOR GLORY EPISODE 04: SKYE MOENCH", thumbnail: "https://content.protriathletes.org/media/content/test/44b01b14-aa31-4593-abce-cafab0ab05c3-thumb.jpg" },
		{ videoId: "e961742dbb64653bce7d733a8c554bdd", duration: 600, name: "BATTLE FOR GLORY EPISODE 03: JAVIER GOMEZ", thumbnail: "https://content.protriathletes.org/media/content/test/580c8482-cb82-4438-9943-9460ad8805f3-thumb.jpg" },
		{ videoId: "e4e3b28971b7cc896b58ba9a4149f954", duration: 652, name: "BATTLE FOR GLORY EPISODE 02: RUDY VON BERG", thumbnail: "https://content.protriathletes.org/media/content/test/f25a977a-8e4e-41b7-99f8-89547fd2aa38-thumb.jpg" },
		{ videoId: "6ce444bf967b72ba3a322126db396016", duration: 690, name: "BATTLE FOR GLORY EPISODE 01: LUCY CHARLES-BARCLAY", thumbnail: "https://content.protriathletes.org/media/content/test/92daa916-b393-46af-8270-6b5b2bf9b067-thumb.jpg" },
		{ videoId: "65b0a4b5898a6ab2eaf10a806e3534fa", duration: 648, name: "GREATEST OF ALL TIME: DANIELA RYF", thumbnail: "https://content.protriathletes.org/media/content/test/fc392e0e-d280-416f-89c0-2eb075174f25-thumb.jpg" },
		{ videoId: "eadc9583ae3ffb920bfbcbb9343a85d4", duration: 60, name: "WHO RULES TRIATHLON", thumbnail: "https://content.protriathletes.org/media/content/test/67d41714-ce14-43e5-b8d0-c2a5aabdf3c6-thumb.jpg"},
		{ videoId: "f2ccdc2af3a968865086f91f4b99d5a8", duration: 127, name: "COLLINS CUP EXPLAINER", thumbnail: "https://content.protriathletes.org/media/content/test/64415ff6-658a-47bf-8044-b22fad67c3ab-thumb.jpg"},
		{ videoId: "fedff4ae4a00b03150db7eface5a288e", duration: 693, name: "GREATER THAN ONE EP02: REALITY", thumbnail: "https://content.protriathletes.org/media/content/test/dc51ed92-4225-417f-bfae-748fe4e28fbe-thumb.jpg" },
		{ videoId: "1f6c05b29b3b3a0af2c1d8b8f2603a7e", duration: 570, name: "GREATER THAN ONE EP01: DREAMS", thumbnail: "https://content.protriathletes.org/media/content/test/9ed9bc4d-8936-4bdb-b696-f8aed0bd1877-thumb.jpg"},
	]*/

	videoPlayer = d => {
		this.videoPlayerRef = d

	}

	selectVideo = e => {
		let entry = e.target.closest(".pto-video-media-entry")
		if(!entry) return
		let v = this.playlist.find(x => x.videoId == entry.dataset.videoid)
		if(!v) return
		route('/player/' + v.videoId)
	}

	shouldComponentUpdate = (nextProps, nextState) => {
		let videoId = nextProps.videoid || this.playlist[0].videoId
		if(this.videoPlayerRef.getVideoId() != videoId)
		{
			let v = this.playlist.find(x => x.videoId == videoId)
			if(v)
			{
				this.videoPlayerRef.loadVideoId(v)
				this.match_splits = null
				if(videoId == "47c3ee7aa31e64717e2e5dee50edb6ba")
				{
					this.loadCollinsCupSplits()
				}

			}
		}
	}

	updateArrows = s => {
		let itemWidth = s.childNodes[0].clientWidth
		let showRight = (s.scrollLeft + s.clientWidth) < (s.scrollWidth - 2)
		let showLeft = s.scrollLeft >= 2 //itemWidth

		this.setState({ showLeft: showLeft, showRight: showRight })
	}

	viewerScroll = d => {
		this.viewerScrollDom = d
		if(!d) return
		d.addEventListener("scroll", (e) => {
			this.updateArrows(e.target)
		}, false)
	}

	left = _ => {
		if(!this.viewerScrollDom) return
		this.viewerScrollDom.scrollBy({ left: -this.viewerScrollDom.clientWidth + 8, behavior: 'smooth' })
	}
	right = _ => {
		if(!this.viewerScrollDom) return
		this.viewerScrollDom.scrollBy({ left: this.viewerScrollDom.clientWidth - 8, behavior: 'smooth' })
	}

	loadCollinsCupSplits = async _ => 
	{
		let rtrt_ulr = "https://api.rtrt.me/events/"
		let rtrt_auth = "?appid=60ec66d81c51a1354121d951&token=e4948abf7483fe0480d487620e8c04f0"
		let rtrt_name = "SS-COLLINS-CUP-2021"


		//let athlete_ids = []
		//for(let aid in athletes_name_map) athlete_ids.push(athletes_name_map[aid])

		let bunch = 200
		let start = 1
		let points = []
		while(1)
		{
			const points_fetch = await fetch(rtrt_ulr + rtrt_name + "/points" + rtrt_auth + "&max=" + bunch + "&start=" + start)
			const points_json = await points_fetch.json()
			//console.log(points_json)

			if(points_json.error) break
			if(points_json.info.first == points_json.info.last) break
			for(let e of points_json.list)
			{
				if(!((e.label == "Swim") || (e.label == "T1") || (e.label == "Bike") || (e.label == "T2") || (e.label == "Finish"))) continue
				let s = {
					name: e.name,
					label: e.label,
					miles: e.miles,
					km: e.km 
				}
				if(e.isStart == 1) s.isStart = 1
				if(e.isFinish == 1) s.isFinish = 1
				points.push(s)
			}
			start = parseInt(points_json.info.last) + 1
		}

		let athletes = []
		start = 0
		while(1)
		{
			const profiles_fetch = await fetch(rtrt_ulr + rtrt_name + "/profiles" + rtrt_auth + "&max=" + bunch + "&agt=" + start)
			const profiles_json = await profiles_fetch.json()

			if(profiles_json.error) break
			if(profiles_json.info.first == profiles_json.info.last) break

			for(let e of profiles_json.list)
			{
				//let athlete_id = athletes_name_map[e.name]
				//console.log(e.name, athlete_id)
				e.division = e.sex.toUpperCase() + "PRO"
				athletes.push({
					//athlete_id: athlete_id,
					bib: e.bib,
					country_iso: e.country_iso,
					fname: e.fname,
					lname: e.lname,
					name: e.name,
					pid: e.pid,
					division: e.division,
				})
			}

			start = profiles_json.info.lasta		
		}

		let pid_list = athletes.map(x => x.pid)

		let split_list = []
		let split_index = []
		for(let p of points)
		{
			split_index[p.name] = split_list.length
			split_list.push(p.name)
		}

		this.match_splits = []
		let updateRTRTSplits_agt = 0
		while(1)
		{
			const splits_fetch = await fetch(rtrt_ulr + rtrt_name + "/profiles/" + pid_list.join(",") + "/splits/" + split_list.join(",") + rtrt_auth + "&max=" + bunch + "&agt=" + updateRTRTSplits_agt)
			const splits_json = await splits_fetch.json()

			if(splits_json.error) break

			for(let split of splits_json.list)
			{
				let a = athletes.find(x => x.pid == split.pid)
				if(!a) continue
				if(!a.splits)
				{
					a.splits = Array(5).fill(null) //Array(split_list.length).fill(null)
					//a.splits_seconds = Array(6).fill(-1)
				}

				let split_index = -1
				if(split.label == "Swim") split_index = 0
				else if(split.label == "T1") split_index = 1
				else if(split.label == "Bike") split_index = 2
				else if(split.label == "T2") split_index = 3
				else if(split.label == "Finish") split_index = 4

				let races = ["Women's Race 1", "Women's Race 2", "Women's Race 3", "Women's Race 4", "Women's Race 5", "Women's Race 6", 
					"Men's Race 1", "Men's Race 2", "Men's Race 3", "Men's Race 4", "Men's Race 5", "Men's Race 6"]
				let race_index = races.indexOf(split.division)

				if(!this.match_splits[race_index]) this.match_splits[race_index] = { splits: [] }
 
				a.splits[split_index] = split
				split.videoTime = (parseInt(split.epochTime) - 1630161015 + 3600*4) / (7*3600 + 12*60 + 12)
				this.match_splits[race_index].splits.push(split)
			}

			updateRTRTSplits_agt = splits_json.info.lasta	
			if(splits_json.info.last < bunch) break
		}

		//this.all_splits.sort((a, b) => a.videoTime - b.videoTime) 

		console.log("athletes", athletes)
		console.log("match_splits", this.match_splits)

		this.setState({})

	}

	clickEvent = e => {
		let videoTime = e.target.dataset.timepercent
		console.log(videoTime)
		this.videoPlayerRef.videoSetTimePercent(videoTime)
	}

	render(props, state)
	{
		useLayoutEffect(() => {
			if(this.viewerScrollDom) {
				this.updateArrows(this.viewerScrollDom)		
			}
		}, []);

		return(
			<div style='min-height: 100vh; background-color: #333;'>
				<VideoPlayer ref={this.videoPlayer}/>
				{this.match_splits && 
					this.match_splits.map((match, index) => { 
						return (
							<div class='pto-video-events'>
								<div class='event-title'>{`Match ${index+1}`}</div>
								{match.splits.map(split => { 
									if(split.label == "T1" || split.label == "T2") return
									return (
										<div class='event' data-team={split.team} data-timepercent={split.videoTime} style={`left: ${split.videoTime * 100}%; top: ${split.race_index*16}px`} onClick={this.clickEvent}>
											<div class='event-tooltip'>{split.name + " " + split.label}</div>
										</div>
									)
								})}
							</div>
						)
					})
				}

				<div class='pto-video-media-list-container'>
					<div class='pto-video-media-list' ref={this.viewerScroll}>
						{this.playlist.map(video => { 
							return(
								<div class='pto-video-media-entry' onClick={this.selectVideo} data-videoid={video.videoId}>
									<div class='pto-video-media-entry-thumb'>
										<img src={video.thumbnail}/>
										<div class='duration'>{secondsToTime(video.duration)}</div>
									</div>
									<div class='pto-video-media-entry-name'>{video.name}</div>
								</div>
							)
						})}
					</div>
					<div class='move-left' show={state.showLeft} onClick={this.left}><i class="fas fa-chevron-left"></i></div>
					<div class='move-right' show={state.showRight} onClick={this.right}><i class="fas fa-chevron-right"></i></div>
				</div>
			</div>
		)
	}
}

class VODRouter extends Component {

	handleRoute = e => {
		window.scrollTo({ top: 0 })
	};
	
	render(props, state) {
		return(
			<Fragment>
				<Router onChange={this.handleRoute}>	
					<VOD path="/player/:videoid?"/>
				</Router>
			</Fragment>
		); 		
	}
}

let vod = document.getElementById('vod')
if(vod)
{
	let scriptHLS = document.createElement("script")
	scriptHLS.src = "https://cdn.jsdelivr.net/npm/hls.js@latest"
	document.getElementsByTagName("head")[0].appendChild(scriptHLS)

	Promise.resolve().then(_ => {
		return new Promise(success => {
			scriptHLS.onload = function() { 
				success()
			} 
		})
	}).then(_ => {
		render(<VODRouter/>, vod)
		/*fetch("/api/media/me")
		.then((r) => r.json())
		.then((d) => {
			if(d.session)
			{
				__session__ = d.session
				__media_user__ = d.media_user
				if(__media_user__)
				{
					__media_user__.info = JSON.parse(__media_user__.info) 	
				}
				render(<MediaRouter events={d.events}/>, media)
			}
			else
			{
				render(<Login/>, media)
			}
		})*/
	})

}

