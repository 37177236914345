import Cookies from 'js-cookie'

//let pumpDevKey = 'dev-U2FsdGVkX19T7sHICVtD3YvOCY4F0qKyur5sq1VrkJNJUxn5K8f4yeqEK4xqoOEgpPjLzTtoiDr+kdKwPDSqk/0ZscwVzt5VnOHhEocDQgvx1VHmfpRwoeA1NcpINMKNINdadZM6MTLnpyWWS5j6Xt//UHWWlHBh+g/W+aSQ1E+qmcQhIPuQhlgusuYlMEoAtcIstUkd3RriUsNLU8KQquGX/QIMYO5Fpr/9QjI5RkY='
//let pumpProdKey = 'prod-U2FsdGVkX19T7sHICVtD3YvOCY4F0qKyur5sq1VrkJNJUxn5K8f4yeqEK4xqoOEgpPjLzTtoiDr+kdKwPDSqk/0ZscwVzt5VnOHhEocDQgvx1VHmfpRwoeA1NcpINMKNINdadZM6MTLnpyWWS5j6Xt//UHWWlHBh+g/W+aSQ1E+qmcQhIPuQhlgusuYlMEoAtcIstUkd3RriUsNLU8KQquGX/QIMYO5Fpr/9QjI5RkY='
//pjdwsdk.init(pumpProdKey)

let loginContainer = document.getElementById('login-container')
let loggedin = document.getElementById('loggedin')
let loggedinContainer = document.getElementById('loggedin-container')
let loggedinMenu = document.getElementById('loggedin-menu')
let loggedinName = document.getElementById('loggedin-name')
let loggedout = document.getElementById('loggedout')
if(loggedout)
{
	loggedout.addEventListener("click", (e) => {
		/*let ptoAuthWindow = window.open(
			window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback" + '&client_id=' + window.appData.oauth_client_id + "&response_mode=message",
			"_pto_auth_", "width=700,height=700"
		)	

		window.addEventListener('message', (message) => {
			if(message.data.type == "pto-auth")
			{
				Cookies.set('pto_session_token', message.data.session_token, { expires: 365 })
				location.reload()
			}
		}, false)
		*/
		/*ptoAuthWindow.addEventListener('message', (message) => {
			console.log(message)
		}, false)*/
		
		location.href = window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback" + '&client_id=' + window.appData.oauth_client_id
	})
}

if(loggedin)
{
	loggedin.addEventListener("click", (e) => {
		loggedinMenu.setAttribute("show", true)	
		document.addEventListener("click", () => {
			loggedinMenu.removeAttribute("show")	
		}, { once: true })
		e.stopPropagation()
	})
}

let logout = document.getElementById('logout')
if(logout)
{
	logout.addEventListener("click", (e) => {
		fetch("/logout", { method: "post" })
		.then(() => {
			Cookies.remove('pto_session_token')
			location.reload()
		})
	})
}

let __session__ = null
window.PTO_GetSession = function() { return __session__ }

if(loginContainer)
{
	fetch("/api/user/me")
	.then((r) => r.json())
	.then((d) => {
		loginContainer.setAttribute("loggedin", d.session != null)
		__session__ = d.session
		if(d.session)
		{
			loggedinName.innerHTML = d.session.first + " " + d.session.last
		
			/*pjdwsdk.identify(d.session.email).then(function(response) {
				if(!response)
				{
					let user = {
						id: d.session.email,
						email: d.session.email,
						first_name: d.session.first,
						last_name: d.session.last
					}
					pjdwsdk.createUser(user);
				}
			}, function(error){
				console.error("Failure calling Identify()", error);
			})*/
		}
	})
}

