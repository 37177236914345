import { h, Component } from "preact"

export class T100Logo extends Component {
	render(props) {
		return (
			<div className={ `t100-logo t100-logo--${props.tier?.toLowerCase().replace(' ', '-')}` }>
				{ props.useImage
					? <img src='../../assets/t100-logo-white.svg' alt='t100 logo' />
					:
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="78" height="20" viewBox="0 0 78 20" fill="#000000">
						<g data-name="Group 896">
							<path id="Path_1133" data-name="Path 1133" d="M21.41,1.931,24.877.3a.176.176,0,0,0,.1-.161A.157.157,0,0,0,24.816,0H1.264A.41.41,0,0,0,.91.2L.034,1.721a.23.23,0,0,0-.009.22.218.218,0,0,0,.2.119H12.682L8.63,3.963A3.877,3.877,0,0,0,6.912,5.541l-2.96,5.127a.219.219,0,0,0,.019.249.228.228,0,0,0,.18.082h5.17a.467.467,0,0,0,.4-.234l5.026-8.706h6.082a1.364,1.364,0,0,0,.576-.129" transform="translate(-0.001 0.001)" fill="#02162d" />
							<path id="Path_1134" data-name="Path 1134" d="M631.94,2.046l1.1-1.856h0a.165.165,0,0,0-.23-.174l-7.73,3.442a.141.141,0,0,0,.06.269h1.12l-4.005,6.917a.238.238,0,0,0,.206.357H626.5a.467.467,0,0,0,.4-.233L630.752,4.1Z" transform="translate(-603.595 0)" fill="#02162d" />
							<path id="Path_1135" data-name="Path 1135" d="M849.811,2.66l-4.745,8.252a.238.238,0,0,0,.206.356h9.114a.5.5,0,0,0,.251-.068l3.685-2.132a.5.5,0,0,0,.184-.184L863.251.632a.238.238,0,0,0-.206-.356h-9.114a.5.5,0,0,0-.251.067l-3.685,2.132a.5.5,0,0,0-.184.184m4.511,6.424a.252.252,0,0,1-.218.126h-4L854,2.46a.251.251,0,0,1,.218-.126h4Z" transform="translate(-819.733 -0.268)" fill="#02162d" />
							<path id="Path_1136" data-name="Path 1136" d="M1315.592,2.66l-4.745,8.252a.238.238,0,0,0,.206.356h9.114a.5.5,0,0,0,.251-.068l3.685-2.132a.5.5,0,0,0,.184-.184l4.745-8.252a.238.238,0,0,0-.206-.356h-9.114a.5.5,0,0,0-.251.067l-3.685,2.132a.5.5,0,0,0-.184.184m4.511,6.424a.252.252,0,0,1-.218.126h-4l3.9-6.749a.251.251,0,0,1,.217-.126h4Z" transform="translate(-1271.568 -0.268)" fill="#02162d" />
						</g>
					</svg>
				}
				<span>{ props.tier }{ !props.noAthleteText && " Athlete" }</span>
			</div>
		)
	}
}
