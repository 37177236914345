const url = require('url')
let formEmail = document.getElementById("form-email")
let formEmailError = document.getElementById("form-email-error")

if(formEmail)
{
	formEmail.addEventListener('submit', (e) => {
		e.preventDefault()

		let __url__ = url.parse(location.href,true)
		let form = __url__.query

		const FD = new FormData(formEmail)
		FD.forEach((value, key) => { form[key] = value })

		form.url = location.href

		fetch("/contact", { method: 'post', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(form) })
		.then((r) => r.json())
		.then((d) => {
			if(d.api_response.result_code == 0)
			{
				if(formEmailError) formEmailError.innerHTML = d.api_response.error
			}
			else
			{
				formEmail.classList.add("submitted")
				if(window.dataLayer) window.dataLayer.push({ 'GFTrackCategory': 'Forms', 'GFTrackAction': 'Submission', 'GFTrackLabel': 'Form: Lead', 'event': 'GFTrackEvent' });
			}
		})
	})
}
