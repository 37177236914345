
Array.from(document.getElementsByClassName("admin-contribution-accept")).forEach(function(b) {
	b.addEventListener('click', function(e) {
		if(e.target.classList.contains("disabled")) return
		let tr = e.target.closest("tr")
		e.target.textContent = "Accepted" 
		e.target.classList.add("disabled"); 
		e.target.classList.replace("btn-primary", "btn-outline-primary");
		Array.from(tr.getElementsByClassName("admin-contribution-reject")).forEach(function(disable) { disable.remove() })

		fetch("/api/contribution/accept", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ id: tr.dataset.id })
		})
	});
});

Array.from(document.getElementsByClassName("admin-contribution-reject")).forEach(function(b) {
	b.addEventListener('click', function(e) {
		if(e.target.classList.contains("disabled")) return
		let tr = e.target.closest("tr")
		e.target.textContent = "Rejected" 
		e.target.classList.add("disabled"); 
		e.target.classList.replace("btn-danger", "btn-outline-danger");
		Array.from(tr.getElementsByClassName("admin-contribution-accept")).forEach(function(disable) { disable.remove() })

		fetch("/api/contribution/reject", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ id: tr.dataset.id })
		})
	});
});


