import { h, render, Component, createRef, Fragment } from 'preact';

let selector_counter = 0

let div = document.createElement('div')
document.body.appendChild(div)

let current_select = null

let ULRef = null
function selectorULRef(e) { if(e) ULRef = e }

function selectorBack(e)
{
	document.body.classList.remove("noscroll")
	div.innerHTML = ''
}

function selectorLI(e)
{
	console.log('--remove')
	current_select.value = e.target.dataset.value
	current_select.dispatchEvent(new Event('change'))

	div.innerHTML = ''
}

function selectorFilter(e) 
{
	let selector_filter = e.target.value.toLowerCase()

	for(let li of ULRef.children)
	{
		let filter = li.dataset.filter
		li.hidden = filter.indexOf(selector_filter) == -1
	}
}

let InputRef = null
function selectorInputRef(e)
{
	if(e) InputRef = e
}

let current_select_selected = null
function selectorRef(e)
{
	if(e && e.hasAttribute('selected')) current_select_selected = e
}


let domSelects = document.getElementsByClassName("modal-select")
if(domSelects)
{
	for(let select of domSelects)
	{
		select.addEventListener("mousedown", function(e) {
			e.preventDefault()

			current_select = e.target

			let selector = {
				options: [],
				title: select.dataset.title
			}
			for(let option of current_select.children)
			{
				selector.options.push({ value: option.value, text: option.text, filter: option.text.toLowerCase(), selected: option.value == current_select.value })
			}

			current_select_selected = null

			render(
				<div class='selector' key={'selector_' + selector_counter}>
					<div class='selector-container'>
						<div class='selector-title h4'><div class='back' onClick={selectorBack}></div>{selector && selector.title}</div>
						<input class='form-control' placeholder="Filter..." ref={selectorInputRef} onInput={selectorFilter}/>
						<ul ref={selectorULRef}>
							{selector && selector.options.map(x => {
								return <li data-value={x.value} data-filter={x.filter} selected={x.selected} onClick={selectorLI} ref={selectorRef}>{x.text}</li>
							})}
						</ul>
					</div>
				</div>
			, div)
			
			let supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints
			if(!supportsTouch) InputRef.focus()

			ULRef.scrollTop = current_select_selected.offsetTop - (ULRef.offsetHeight - current_select_selected.offsetHeight) * 0.25

			selector_counter += 1
			document.body.classList.add("noscroll")
		})
	}
}
