let filterVideos = document.getElementById('filter-videos')
if(filterVideos)
{
	filterVideos.addEventListener("input", (e) => {
		let terms = filterVideos.value.toLowerCase().split(" ")
		Array.from(document.getElementsByClassName("youtube-video")).forEach(function(video) {
			let search = video.dataset.search
			let hidden = false

			for(let t of terms)
			{
				if(!t.length) continue
				if(search.indexOf(t) == -1) hidden = true
			}

			video.hidden = hidden
		})
	})
}
