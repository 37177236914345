import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime, getSeconds, AthletePic, ordinal_suffix_of } from "/server/components/utils"
import moment from 'moment';

export class Game_Olympics extends Component {
	state = { loading: true, game_id: 3 }

	game = {
		men: [0,0,0,0,0],
		women: [0,0,0,0,0],
		mixed: [0,0,0,0,0]
	}

	componentWillMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {

		let game_req = await fetch("/api/fantasy/games/" + this.state.game_id)
		let game_json = await game_req.json()
		let game_data = JSON.parse(game_json.game.data)

		
		console.log(game_data)
		game_data.men.entries.sort((a,b) => a.rank - b.rank)
		game_data.women.entries.sort((a,b) => a.rank - b.rank)
		game_data.mixed.entries.sort((a,b) => a.rank - b.rank)

		const play_fetch = await fetch(`/api/fantasy/${this.state.game_id}/play`)
		const play_json = await play_fetch.json()

		this.game_info = play_json.game
		let now = parseInt(new Date().getTime() / 1000)

		if(play_json.play)
		{
			let data = JSON.parse(play_json.play.data)
			this.game = data
			//this.game.athletes = data.athletes
			this.score = play_json.play.score
		}

		this.setState({ men: game_data.men, women: game_data.women, mixed: game_data.mixed, loading: false, closed: now > this.game_info.deadline })
	}

	showSelector = e => {
		let now = parseInt(new Date().getTime() / 1000)
		if(now > this.game_info.deadline)
		{
			this.setState({ closed: true })
			return
		}

		let a = e.target.closest(".athlete-selected")
		let type = a.dataset.type
		let index = a.dataset.index
		this.curSelectionType = type
		this.curSelectionIndex = index
		document.body.classList.add("noscroll")
		this.setState({ showselect: true, select_title: this.curSelectionType, select_subtitle: "Select an athlete" }, () => {})
	}

	closeSelect = e => {
		document.body.classList.remove("noscroll")
		this.setState({ showselect: false })
	}

	closeBackdropSelect = e => {
		if(!e.target.classList.contains("olympics-select-container")) return
		document.body.classList.remove("noscroll")
		this.setState({ showselect: false })
	}

	save = _ => {
		fetch(`/api/fantasy/${this.state.game_id}/play`, { 
			method: "post", 			
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ play: this.game, version: 1})
		})
		.then((r) => r.json())
		.then((d) => {
			if(d.status == 1)
			{
				alert("The race has already started.\nYou can't play anymore.")
			}
		})
	}

	closeClear = e => {
		this.game[this.curSelectionType][this.curSelectionIndex] = 0
		document.body.classList.remove("noscroll")
		this.setState({ showselect: false })
		this.save()
	}

	selectAthlete = e => {
		let a = e.target.closest(".athlete")
		if(a.hasAttribute('disabled')) return
		let id = a.dataset.id
		this.game[this.curSelectionType][this.curSelectionIndex] = id
		document.body.classList.remove("noscroll")
		this.setState({ showselect: false })
		this.save()
	}

	loadImage = async src => {
		return new Promise((resolve, reject) => {
			let img = new Image()
			img.onload = () => resolve(img)
			img.onerror = reject
			img.src = src
		})
	}


	fillTextCenter = (ctx, text, x, y, center_y = false) => {
		let m = ctx.measureText(text)
		ctx.fillText(text, x - m.width * 0.5, y + (center_y ? m.actualBoundingBoxAscent * 0.5 : 0))
		return m
	}


	generateImage = async _ => {

		let black_font = new FontFace('LatoBlack', 'url(/assets/fonts/Lato/Lato-Black.ttf)')
		await black_font.load()
		document.fonts.add(black_font)

		let regular_font = new FontFace('LatoRegular', 'url(/assets/fonts/Lato/Lato-Regular.ttf)')
		await regular_font.load()
		document.fonts.add(regular_font)

		let thin_font = new FontFace('LatoThin', 'url(/assets/fonts/Lato/Lato-Light.ttf)')
		await thin_font.load()
		document.fonts.add(thin_font)



		let canvas = document.createElement("canvas");
		canvas.width = 2000
		canvas.height = 2000
		const ctx = canvas.getContext('2d')

		ctx.clearRect(0, 0, canvas.width, canvas.height)
		ctx.beginPath()
		ctx.rect(0, 0, canvas.width, canvas.height)
		ctx.fillStyle = "white"
		ctx.fill()


		ctx.save()
			ctx.translate(1000 - 790 * 0.5, 220);
			ctx.font = '200px LatoBlack'
			let letter_spacing = 180
			let letter_x = 0
			ctx.fillStyle = '#0286c3'
			ctx.fillText('T', letter_x, 0)
			letter_x += ctx.measureText('T').width * 1.1

			ctx.fillStyle = '#fbb22e'
			ctx.fillText('O', letter_x, 0)
			letter_x += ctx.measureText('O').width * 1.1

			ctx.fillStyle = '#000000'
			ctx.fillText('K', letter_x, 0)
			letter_x += ctx.measureText('K').width * 1.1

			ctx.fillStyle = '#168c39'
			ctx.fillText('Y', letter_x, 0)
			letter_x += ctx.measureText('Y').width * 1.1

			ctx.fillStyle = '#ee2f4d'
			ctx.fillText('O', letter_x, 0)
			letter_x += ctx.measureText('O').width * 1.1
			//console.log(letter_x)
		
		ctx.restore()

		ctx.font = '70px LatoRegular'
		ctx.fillStyle = '#000000'
		this.fillTextCenter(ctx, "FANTASY GAME", 1000, 300)


		ctx.save()
			ctx.translate(0, 370);
			for(let index in this.game.men)
			{
				ctx.fillStyle = 'black'
				let hero_width = 250, flag_width = 80

				let ai = this.game.men[index]
				let a = this.state.men.entries.find(x => x.athlete_id == ai)
				let profile_image = await this.loadImage("/proxy?url=" + encodeURIComponent(a.profile_image))
				ctx.drawImage(profile_image, 1000 - hero_width - 40, index * (hero_width + 40), hero_width, hero_width * profile_image.naturalHeight / profile_image.naturalWidth)

				ctx.font = '60px LatoRegular'
				let text = a.first.toUpperCase()
				let m = ctx.measureText(text)
				ctx.fillText(text, 1000 - hero_width - 80  - m.width, 70 + index * (hero_width + 40))
	
				ctx.font = '60px LatoBlack'
				text = a.last.toUpperCase()
				m = ctx.measureText(text)
				ctx.fillText(text, 1000 - hero_width - 80 - m.width, 140 + index * (hero_width + 40))

				let flag_image = await this.loadImage("/proxy?url=https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/" + a.country_isoa2.toLowerCase() + ".svg")
				ctx.drawImage(flag_image, 1000 - hero_width - 80 - flag_width, 170 + index * (hero_width + 40), flag_width, flag_width * flag_image.naturalHeight / flag_image.naturalWidth)

				ctx.font = '50px LatoThin'
				text = a.noc
				m = ctx.measureText(text)
				ctx.fillText(text, 1000 - hero_width - 100 - flag_width - m.width, 220 + index * (hero_width + 40))

				ai = this.game.women[index]
				a = this.state.women.entries.find(x => x.athlete_id == ai)
				profile_image = await this.loadImage("/proxy?url=" + encodeURIComponent(a.profile_image))
				ctx.drawImage(profile_image, 1000 + 40, index * (hero_width + 40), hero_width, hero_width * profile_image.naturalHeight / profile_image.naturalWidth)

				ctx.font = '60px LatoRegular'
				text = a.first.toUpperCase()
				ctx.fillText(text, 1000 + hero_width + 80, 80 + index * (hero_width + 40))
	
				ctx.font = '60px LatoBlack'
				text = a.last.toUpperCase()
				ctx.fillText(text, 1000 + hero_width + 80, 150 + index * (hero_width + 40))

				if(a.country_isoa2 == 'RC')
				{
					flag_image = await this.loadImage("/proxy?url=https://content.protriathletes.org/media/content/test/1c52fd23-4981-47d8-bf6b-e10d4e2b7595.svg")
				}
				else
				{
					flag_image = await this.loadImage("/proxy?url=https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/" + a.country_isoa2.toLowerCase() + ".svg")
				}
				ctx.drawImage(flag_image, 1000 + hero_width + 80, 170 + index * (hero_width + 40), flag_width, flag_width * flag_image.naturalHeight / flag_image.naturalWidth)

				ctx.font = '50px LatoThin'
				text = a.noc
				ctx.fillText(text, 1000 + hero_width + 100 + flag_width, 220 + index * (hero_width + 40))


				console.log(a)
			}

		ctx.restore()


		ctx.save()
			ctx.translate(0, 1870);

			ctx.fillStyle = '#33363a'
			ctx.font = '50px LatoRegular'
			this.fillTextCenter(ctx, "Make your Tokyo Fantasy Team selection at", 1000, 0)
			ctx.font = '50px LatoBlack'
			this.fillTextCenter(ctx, "protriathletes.org/fantasy", 1000, 60)
		ctx.restore()

		return canvas		
	}

	imageRef = createRef()
	saveImage = async e => {

		let canvas = await this.generateImage()

		/*let a = document.createElement('a')
		a.download = "TriBattle-Prediction.jpg"
		a.target = '_blank'
		a.href = canvas.toDataURL('image/jpeg', 0.95)
		document.body.appendChild(a)
		a.click()
		a.remove()*/

		this.setState({ image_generated: true }, () => {
			if(this.imageRef.current) this.imageRef.current.src = canvas.toDataURL('image/jpeg', 0.95)
		})

	}


	render(props, state)
	{
		/*if(![6,7,25,27,19,38,40,41,57,34,1521,4004,9734,1028700000,10351,31,1704].find(x => x == props.session.id))
		{
			return (<div class='race-container'>
				<div class='h2 text-center p-4'>Coming soon...</div>
			</div>)
		}*/

		if(state.loading) return (<div class='race-container'>
			<div class='h2 text-center p-4'>Loading...</div>
		</div>)

		let budget = 350
		for(let i = 0; i < this.game.men.length; i++)
		{
			let athlete
			athlete = state.men.entries.find(x => x.athlete_id == this.game.men[i])
			if(athlete) budget -= athlete.total
			athlete = state.women.entries.find(x => x.athlete_id == this.game.women[i])
			if(athlete) budget -= athlete.total
		}

		let selected_budget = budget
		if(state.showselect)
		{
			let a = state[this.curSelectionType].entries.find(x => x.athlete_id == this.game[this.curSelectionType][this.curSelectionIndex])
			if(a) selected_budget += a.total
		}

		let all_played = this.game.men.filter(x => x == 0).length == 0 && this.game.women.filter(x => x == 0).length == 0

		return(
			<div class='olympics race-container'>
				<div class='tokyo'>
					<div class='letter-1'>T</div>
					<div class='letter-2'>O</div>
					<div class='letter-3'>K</div>
					<div class='letter-4'>Y</div>
					<div class='letter-5'>O</div>
				</div>
				<div class='text-center p-3 w-100 max-width'>
					<div class='h4 mb-3'>How to Play.</div>
					<div class='mb-3'>Select your team by choosing <b>5 Men</b> & <b>5 Women</b>.</div>
					<div class='mb-3'>You have a budget of <b>¥ 350</b> to make your 10 choices.</div>
					<div class='mb-1'>Each athlete scores points based on their finish position.</div> 
					<div class='mb-1'>The winner of each race (men & women) gets <b>1500 points</b>.</div> 
					<div class='mb-3'>The other athletes will see their points <b>decreased by 7.5% for every position</b>.</div>
					<div class='mb-1'>Your points is the sum of your athletes' points.</div>
					<div class='mb-1'>The more points you have, the higher you will be ranked.</div>
				</div>
				<div class='max-width text-center pb-4'>
					{ state.closed && <div class={'h3 text-capitalize text-center pt-4 pto-red'}>This game is now closed.</div> }
					<div class={'h3 text-capitalize text-center pt-4 ' + (budget < 0 ? "pto-red" : "")}>Your Budget</div>
					<div class={'h1 text-capitalize text-center pb-1 ' + (budget < 0 ? "pto-red" : "")}>¥ {budget}</div>
					<div class='text-center pb-4'><small>The price of each athlete is determined by their <a href='https://triathlon.org/rankings/world_triathlon_rankings'>World Triathlon Rankings</a> points.</small></div>
					{
						["men", "women"].map(gender => 
							<div class='pb-4'>
								<div class='h5 text-capitalize text-center pt-4 pb-2'>Your {gender}'s Team</div>
								<div class='athlete-selection'>
									{
										[0,1,2,3,4].map(index => {
											let athlete = state[gender].entries.find(x => x.athlete_id == this.game[gender][index])
											if(athlete)
											{
												return(
													<div class='athlete-selected' data-type={gender} data-index={index} onClick={this.showSelector}>
														<div class='image'>
															<img src={athlete.profile_image}/>
														</div>
														<div class='info'>
															<div class='name'>
																<div class='d-flex justify-content-between'>
																	<div class='first'>{athlete.first}</div>
																	<div class='country'><img src={athlete.flag}/><span class='ml-1'>{athlete.noc}</span></div>
																</div>
																<div class='d-flex justify-content-between'>
																	<div class='last'>{athlete.last}</div>
																	<div class='price'>¥{athlete.total}</div>
																</div>
															</div>
														</div>
													</div>
												)
											}
											else
											{
												return(
													<div class='athlete-selected' data-type={gender} data-index={index} onClick={this.showSelector}>
														<div class='image'>
															<i class="fas fa-user-plus"></i>
														</div>
														<div class='info'>
															<div class='text-center'>Add a {gender == "men" ? "Man" : "Woman"}<br/>to your team</div>
														</div>
													</div>
												)
											}
										})
									}
								</div>
							</div>
						)
					}
					{ (!state.closed && (!all_played || budget < 0)) && <div><b class='pto-red h5'>To play the game, you need to make your 10 choices within your budget.</b></div>}
					{ (!state.closed && all_played && budget >= 0) && <div><b class='check h5'>Well done! You made your team selection to play the Tokyo Fantasy Game.</b>
						<div class='pt-2'>You can now <a href='fantasy/leagues/create' class='pto-red'><b>create</b></a> or <a href='fantasy/leagues/join' class='pto-red'><b>join</b></a> leagues to play with your friends.</div>
					</div>}
					{ all_played && budget >= 0 && <button class='btn btn-primary mt-4' onClick={this.saveImage}>Generate Image to Share</button> }
					{ state.image_generated && <div class='d-flex flex-column justify-content-center py-4'>
						<small class='text-center py-4 mt-4 px-2 f110'>To save or share, long press on the image on mobile device, or right click on desktop.</small>
						<img class='w-100' ref={this.imageRef} style='border: 1px solid rgba(0,0,0,0.1);'/>
					</div> }
				</div>
				{state.showselect &&
					<div class='olympics-select-container' show={state.showselect} onClick={this.closeBackdropSelect}>
						<div class='olympics-select'>
							<div class='title'>{state.select_title}</div>
							<div class='subtitle pb-3'>{state.select_subtitle}</div>
							<div class='athlete-list'>
								{ state[this.curSelectionType].entries.map(x => {
									let disabled = x.total > selected_budget || this.game[this.curSelectionType].find(id => id == x.athlete_id) != null
									return <div class='athlete' data-id={x.athlete_id} disabled={disabled} onClick={this.selectAthlete}>
										<div class='profile-image'><img src={x.profile_image}/></div>
										<div class='name'>
											<div class='first'>{x.first}</div>
											<div class='last'>{x.last}</div>
											<div class='country'><img src={x.flag}/><span class='mx-1'>{x.noc}</span></div>
											<div class='rank'>{"ITU WR #" + x.rank}</div>
										</div>
										<div class='price'>¥{x.total}</div>
									</div>
								})}
							</div>
							<div class='d-flex justify-content-between pt-4'>
								<button class='btn btn-secondary' onClick={this.closeClear}>Clear</button>
								<button class='btn btn-primary' onClick={this.closeSelect}>Close</button>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}
}
