let searchToggle = document.getElementById('search-toggle')
let searchClose = document.getElementById('search-close')
let searchContainer = document.getElementById('search-container')
let searchInput = document.getElementById('search-input')
let searchResults = document.getElementById('search-results')

const htmlToElement = (html) => {
	var template = document.createElement('template');
	template.innerHTML = html;
	return template.content;
}

if(searchToggle) {
	document.addEventListener("keyup", (e) => {
		if(e.target.tagName == "INPUT") return
	})
}


if(searchToggle && searchContainer) {
	searchToggle.addEventListener("click", (e) => {
		let t = searchContainer.classList.toggle("hide")
		document.body.classList.toggle("noscroll")
		setTimeout( () => {
			if(!t) searchInput.focus()
		}, 100);
	})
}

if(searchClose && searchContainer) {
	searchClose.addEventListener("click", (e) => {
		let t = searchContainer.classList.toggle("hide")
		document.body.classList.remove("noscroll")
	})
}


let searchTimer = null
if(searchInput) {
	searchInput.addEventListener("input", (e) => {
		if(searchTimer != null) clearTimeout(searchTimer)

		searchTimer = setTimeout(function(terms) {
			searchTimer = null

			if(terms == undefined || terms.trim() == "") {
				searchResults.classList.add("hide")
				return
			}
			fetch("/api/search/" + terms, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data_search) {
				let parent = searchResults
				while (parent.firstChild) {
					parent.removeChild(parent.firstChild);
				}
				let count = 1
				if(data_search.results.length || data_search.races.length) {
					if(!data_search.results)
					{
						searchResults.classList.add("hide")
						document.body.classList.remove("noscroll")
					}

					for(let r of data_search.results) {
						const isRetired = r.retirement_date ? true : false;
						const athletePic = JSON.parse(r.pic)
						const hasPic = athletePic?.img?.src != null

						const html = `
							<div class="search-result search-result--athlete ${isRetired ? "search-result--inactive" : ""}">
								<a href="${r.url}">
									<div class='image-container'>
										${hasPic ? `<img src='${athletePic.img.src}' alt='Headshot of ${r.name}' />` : "<div class='nopic'></div>"}
									</div>
									<span class="search-result__type">Athlete</span>
									<span class="search-result__name">${r.name}</span>
								</a>
							</div>
						`
						const htmlElement = htmlToElement(html);

						htmlElement.querySelector("a").addEventListener("click", function() {
							searchInput.value = ""
						});
						
						searchResults.appendChild(htmlElement);
						searchResults.classList.remove("hide")
						document.body.classList.add("noscroll")
						count += 1
					}

					for(let r of data_search.races) {
						const isT100Race = r.t100_season_id != null

						const html = `
							<div class="search-result search-result--race">
								<a href="${r.url}">
									<span class="search-result__type">Race</span>
									<span class="search-result__name">${r.name}</span>
									${isT100Race ? '<span class="search-result__t100"><img src="../../assets/t100-logo-white.svg" alt="T100 logo" /></span>' : ""}
								</a>
							</div>
						`

						const htmlElement = htmlToElement(html);

						htmlElement.querySelector("a").addEventListener("click", function() {
							searchInput.value = ""
						});

						searchResults.appendChild(htmlElement);
						searchResults.classList.remove("hide")
						document.body.classList.add("noscroll")
						count += 1
					}
				} else {
					document.body.classList.remove("noscroll")

					const html = `
						<span>No results found</span>
					`

					const htmlElement = htmlToElement(html);
					searchResults.appendChild(htmlElement);
					searchResults.classList.remove("hide")
				}
			})
		}, 250, e.target.value)
	})

	document.addEventListener("mouseup", (e) => {
		if(!searchResults.classList.contains("hide"))
		{
			let withinSearch = e.target.closest(".search")
			if(!withinSearch)
			{
				searchResults.classList.add("hide")
				document.body.classList.remove("noscroll")
			}
		}
	})

	document.addEventListener("keydown", (e) => {
		if(!searchResults.classList.contains("hide"))
		{
			let choices = Array.from(searchResults.getElementsByTagName("a"))
			let selected = choices.findIndex(x => x.classList.contains("selected"))
			if(e.code == "ArrowDown")
			{
				if(selected != -1) choices[selected].classList.remove("selected")
				if(selected == -1 || selected == choices.length - 1) { choices[0].classList.add("selected"); }
				else { choices[selected + 1].classList.add("selected"); }
				e.preventDefault()
			}
			else if(e.code == "ArrowUp")
			{
				if(selected != -1) choices[selected].classList.remove("selected")
				if(selected == -1 || selected == 0) { choices[choices.length - 1].classList.add("selected"); }
				else { choices[selected - 1].classList.add("selected"); }
				e.preventDefault()
			}
			else if(e.code == "Enter")
			{
				searchInput.value = ""
				if(selected != -1) choices[selected].click()
				e.preventDefault()
			}
			else if(e.code == "Escape")
			{
				searchResults.classList.add("hide")
				document.body.classList.remove("noscroll")
				e.preventDefault()
			}
		}
	})
}

