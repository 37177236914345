import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime, getSeconds, AthletePic, ordinal_suffix_of } from "/server/components/utils"
import moment from 'moment';

class Time extends Component {
	render(props)
	{
		let seconds = parseInt(props.time % 60)
		let minutes = parseInt((props.time / 60) % 60)
		let hours = parseInt((props.time / 3600) % 60)
		return (
			<div class='big-time small edit' fixed={props.fixed}>
				<div class='number'>{hours}</div>
				<div class='dots'>:</div>
				<div class='number'>{minutes < 10 ? "0" : ""}{minutes}</div>
				<div class='dots'>:</div>
				<div class='number'>{seconds < 10 ? "0" : ""}{seconds}</div>
			</div>
		)
	}	
}

export class Game_TriBattle extends Component {
	rangeHoursRef = createRef()
	rangeMinutesRef = createRef()
	rangeSecondsRef = createRef()
	state = { loading: true, game_id: 2, image_generated: false, closed: true }

	score = 0

	game = {
		times: [
			{ name: "Swim", distance: "3.8km" },
			{ name: "T1", distance: "" },
			{ name: "Bike", distance: "180km" },
			{ name: "T2", distance: "" },
			{ name: "Run", distance: "42.2km" },
			{ name: "Finish", distance: "226km" },
		],
		athletes: [
			{ 
				name: "Frodeno",
				times: [0,0,0,0,0,0]
			},
			{ 
				name: "Sanders",
				times: [0,0,0,0,0,0]
			}
		]
	}

	componentWillMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {

		this.setState({ loading: true })

		const play_fetch = await fetch(`/api/fantasy/${this.state.game_id}/play`)
		const play_json = await play_fetch.json()

		this.game_info = play_json.game
		let now = parseInt(new Date().getTime() / 1000)

		if(play_json.play)
		{
			let data = JSON.parse(play_json.play.data)
			this.game.athletes = data.athletes
			this.score = play_json.play.score
		}

		if(play_json.result)
		{
			let data = JSON.parse(play_json.result.data)
			this.result = data.athletes
		}

		this.setState({ loading: false, closed: now > this.game_info.deadline })
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	showTimeSelector = e => {
		if(this.state.closed) return
		let divdata = e.target.closest(".data")
		let athlete = this.game.athletes[divdata.dataset.athlete]
		let time = this.game.times[divdata.dataset.time]
		let selectedtime = athlete.times[divdata.dataset.time]

		let seconds = parseInt(selectedtime % 60)
		let minutes = parseInt((selectedtime / 60) % 60)
		let hours = parseInt((selectedtime / 3600) % 60)

		document.body.classList.add("noscroll")
		this.setState({ 
			select_athlete: divdata.dataset.athlete, 
			select_time: divdata.dataset.time, 
			select_title: athlete.name, 
			select_subtitle: time.name + " Time", 
			select_hours: hours, 
			select_minutes: minutes, 
			select_seconds: seconds, 
			showtimeselect: true }, 
		() => {
			let event = new Event('input', { bubbles: true, cancelable: true });
			if(this.rangeHoursRef.current) this.rangeHoursRef.current.dispatchEvent(event)
			if(this.rangeMinutesRef.current) this.rangeMinutesRef.current.dispatchEvent(event)
			if(this.rangeSecondsRef.current) this.rangeSecondsRef.current.dispatchEvent(event)
		})
	}

	closeTimeSelect = (e) => {
		/*let container = e.target.closest(".athlete-modal-container")
		let close = e.target.closest(".close-modal")
		if(container != e.target && close != e.target) return;*/

		let seconds = parseInt(this.state.select_seconds)
		let minutes = parseInt(this.state.select_minutes)
		let hours = parseInt(this.state.select_hours)

		let times = this.game.athletes[this.state.select_athlete].times
		times[this.state.select_time] = hours * 3600 + minutes * 60 + seconds

		times[5] = times[0] + times[1] + times[2] + times[3] + times[4]

		/*let actual_times = [
			[2760, 120, 15000, 120, 9300, 27300],
			[3180, 120, 14880, 120, 9900, 28200]
		]

		let score = 5000
		for(let i = 0; i < 5; i++)
		{
			for(let j = 0; j < 2; j++)
			{
				score -= Math.abs(this.game.athletes[j].times[i] - actual_times[j][i])
			}
		}
		console.log(score)*/


		document.body.classList.remove("noscroll")
		this.setState({ showtimeselect: false, image_generated: false })

		fetch(`/api/fantasy/${this.state.game_id}/play`, { 
			method: "post", 			
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ play: { athletes: this.game.athletes }, version: 1})
		})
		.then((r) => r.json())
		.then((d) => {
			if(d.status == 1)
			{
				alert("The race has already started.\nYou can't play anymore.")
			}
		})

		if(this.state.image_generated) this.saveImage()

	}

	sliderInput = e => {
		const inputElement = e.target
		const percent = ((inputElement.value - inputElement.min) / (inputElement.max - inputElement.min)) * 100;
		inputElement.style.setProperty("--webkitProgressPercent", `${percent}%`);		
	
		let data = {}
		data[inputElement.dataset.target] = inputElement.value
		this.setState(data)
	}

	loadImage = async src => {
		return new Promise((resolve, reject) => {
			let img = new Image()
			img.onload = () => resolve(img)
			img.onerror = reject
			img.src = src
		})
	}


	fillTextCenter = (ctx, text, x, y, center_y = false) => {
		let m = ctx.measureText(text)
		ctx.fillText(text, x - m.width * 0.5, y + (center_y ? m.actualBoundingBoxAscent * 0.5 : 0))
		return m
	}


	generateImage = async _ => {
		let lionel_color = '#427C4D'
		let jan_color = '#AA395E'
		let logo_image = await this.loadImage("/proxy?url=" + "https://content.protriathletes.org/media/content/test/94b35c0c-2578-4342-a419-ce8d8372a19d-thumb.jpg")
		let jan_image = await this.loadImage("/proxy?url=" + "https://content.protriathletes.org/media/content/test/90d274ad-27d7-483f-a9a7-cb6e0d479fcb.jpg")
		let lionel_image = await this.loadImage("/proxy?url=" + "https://content.protriathletes.org/media/content/test/2fb3382d-e20c-413a-a204-2cefd31f5594.jpg")

		let black_font = new FontFace('Black', 'url(/assets/fonts/Metropolis/Metropolis-Black.otf)')
		await black_font.load()
		document.fonts.add(black_font)

		let bold_font = new FontFace('Bold', 'url(/assets/fonts/Metropolis/Metropolis-Bold.otf)')
		await bold_font.load()
		document.fonts.add(bold_font)

		let regular_font = new FontFace('Regular', 'url(/assets/fonts/Metropolis/Metropolis-Regular.otf)')
		await regular_font.load()
		document.fonts.add(regular_font)


		let canvas = document.createElement("canvas");
		canvas.width = 2000
		canvas.height = 2000
		const ctx = canvas.getContext('2d')

		ctx.clearRect(0, 0, canvas.width, canvas.height)
		ctx.beginPath()
		ctx.rect(0, 0, canvas.width, canvas.height)
		ctx.fillStyle = "white"
		ctx.fill()

		let hero_width = 600

		ctx.drawImage(jan_image, 100, 0, hero_width, hero_width * jan_image.naturalHeight / jan_image.naturalWidth)
		ctx.drawImage(lionel_image, 2000 - 100 - hero_width, 0, hero_width, hero_width * lionel_image.naturalHeight / lionel_image.naturalWidth)

		let logo_width = 400
		ctx.drawImage(logo_image, 1000 - logo_width * 0.5, 50, logo_width, logo_width * logo_image.naturalHeight / logo_image.naturalWidth)

		let jan_x = 100 + hero_width * 0.5
		let lionel_x = 2000 - 100 - hero_width * 0.5

		ctx.save()
			ctx.translate(0, 940);
		
			ctx.font = '120px Black'
			ctx.fillStyle = jan_color
			this.fillTextCenter(ctx, "FRODENO", jan_x, 4)

			ctx.fillStyle = lionel_color
			this.fillTextCenter(ctx, "SANDERS", lionel_x, 4)

			ctx.fillStyle = '#33363a'
			this.fillTextCenter(ctx, "FRODENO", jan_x, 0)
			this.fillTextCenter(ctx, "SANDERS", lionel_x, 0)

			let jan_times = this.game.athletes[0].times
			let lionel_times = this.game.athletes[1].times

			let disciplines = ["SWIM", "T1", "BIKE", "T2", "RUN", "FINISH"]
			for(let i = 0; i < 6; i++)
			{
				let y = 160 + i * 120

				if(i == 5) y += 40;

				ctx.fillStyle = '#33363a'
				ctx.font = '90px Bold'
				this.fillTextCenter(ctx, secondsToTime(jan_times[i]), jan_x, y)
				this.fillTextCenter(ctx, secondsToTime(lionel_times[i]), lionel_x, y)

				ctx.fillStyle = '#888'
				ctx.font = '70px Regular'
				this.fillTextCenter(ctx, disciplines[i], 1000, y)
			}
		ctx.restore()


		ctx.save()
			ctx.translate(0, 1900);

			ctx.fillStyle = '#33363a'
			ctx.font = '50px Regular'
			this.fillTextCenter(ctx, "Make your Zwift Tri Battle Royale prediction at", 1000, 0)
			ctx.font = '50px Bold'
			this.fillTextCenter(ctx, "protriathletes.org/fantasy", 1000, 60)
		ctx.restore()

		return canvas		
	}

	imageRef = createRef()
	saveImage = async e => {

		let canvas = await this.generateImage()

		/*let a = document.createElement('a')
		a.download = "TriBattle-Prediction.jpg"
		a.target = '_blank'
		a.href = canvas.toDataURL('image/jpeg', 0.95)
		document.body.appendChild(a)
		a.click()
		a.remove()*/

		this.setState({ image_generated: true }, () => {
			if(this.imageRef.current) this.imageRef.current.src = canvas.toDataURL('image/jpeg', 0.95)
		})

	}

	share = async e => {

		let canvas = await this.generateImage()
		canvas.toBlob(function(blob) {
			let file = new File([blob], "zwift-tri-battle-royale-share.jpg", {type: 'image/jpeg'});
			let filesArray = [file];
			if(navigator.share)
			{
				navigator.share({
					title: "Zwift Tri Battle Royale - PTO Fantasy Game",
					text: "Zwift Tri Battle Royale - PTO Fantasy Game",
					url: location.href,
					files: filesArray, 
				}).then(() => {
					console.log('Thanks for sharing!');
				}).catch((error) => {
					//downloadShare.click()
				});
			}
		}, "image/jpeg", 0.95)

	}

	render(props, state)
	{
		//if(state.loading) return		
		let game = this.game

		let all_played = game.athletes[0].times.filter(x => x == 0).length == 0 && game.athletes[1].times.filter(x => x == 0).length == 0

		return(
			<Fragment>
				<div class='race-container'>
					<div class='d-flex px-4 py-4 w-100' style='height: 200px;'>
						<img src="https://content.protriathletes.org/website/assets/tri-battle.png" style='object-fit: contain; max-width: 100%; margin: 0 auto;'/>
					</div>
					<div class='text-center p-3 w-100'>
						<div class='h4 mb-3'>How to Play.</div>
						<div class='mb-1'>Predict the <b>Swim, Bike, Run and Transition times</b> for Frodeno & Sanders.</div>
						<div class='mb-1'>Start with an allocation of <b>5,000 points</b>.</div>
						<div class='mb-1'>For each discipline, the difference (in seconds) from predicted to actual race time will be deduced to your total points.</div>
						<div class='mb-1'>The more points you have, the higher you will be ranked.</div>
					</div>

					<div class='w-100 py-4 px-2 text-center'>
						<a class='link-anchor h5' href='head-to-head/jan-frodeno/lionel-sanders'>Access all the head to head time comparaisons</a>
					</div>

					<div class='janvslionel w-100 py-4 px-2'>
						<div class='row mb-3 px-2'>
							<div class='left title jan'>
								<div>
									<img src='https://content.protriathletes.org/media/content/test/90d274ad-27d7-483f-a9a7-cb6e0d479fcb.jpg'/>
								</div>
								<div>
									{game.athletes[0].name}
								</div>
							</div>
							<div class='center'></div>
							<div class='right title lionel'>
								<div>
									<img src='https://content.protriathletes.org/media/content/test/2fb3382d-e20c-413a-a204-2cefd31f5594.jpg'/>
								</div>
								<div>
									{game.athletes[1].name}
								</div>
							</div>
						</div>
						{ [0,1,2,3,4].map(index => {
							return (
								<div class='row mb-3 px-2'>
									<div class='left data' onClick={this.showTimeSelector} data-athlete={0} data-time={index}><Time time={game.athletes[0].times[index]} fixed={state.closed}/></div>
									<div class='center subtitle'>
										<div>
											{ game.athletes[0].times[index] == 0 && <i class="fas fa-long-arrow-alt-left pto-red"></i> }
											{ game.athletes[0].times[index] != 0 && <i class="fas fa-check-circle check"></i> }
										</div>
										<div>
											{game.times[index].name}
											<div class='f90 text-grey distance'>{game.times[index].distance}</div>
										</div>
										<div>
											{ game.athletes[1].times[index] == 0 && <i class="fas fa-long-arrow-alt-right pto-red"></i> }
											{ game.athletes[1].times[index] != 0 && <i class="fas fa-check-circle check"></i> }
										</div>
									</div>
									<div class='right data' onClick={this.showTimeSelector} data-athlete={1} data-time={index}><Time time={game.athletes[1].times[index]} fixed={state.closed}/></div>
								</div>
							)
						})
						}
						<div class='row mb-3 px-2 finish-time'>
							<div class='left data'><Time time={game.athletes[0].times[5]} fixed/></div>
							<div class='center subtitle'>
								<div></div>
								<div>
									{game.times[5].name}
									<div class='f90 text-grey distance'>{game.times[5].distance}</div>
								</div>
								<div></div>
							</div>
							<div class='right data'><Time time={game.athletes[1].times[5]} fixed/></div>
						</div>
						<div class='w-100 p-2 bg-white text-center'>
							{ state.closed && this.result && <div>
								<div class='pb-2'>The dealine to enter your times has passed.</div>
								<h4>Your current score is:</h4>
								<h2>{this.score}</h2>
								<div class='p-4'>
									<table class='table table-results'>
										<tr>
											<th><b>FRODENO</b></th>
											<th></th>
											<th><b>SANDERS</b></th>
										</tr>
										<tr>
											<td>{this.result[0].times[0] != 0 && secondsToTime(this.result[0].times[0])}</td>
											<th>SWIM</th>
											<td>{this.result[1].times[0] != 0 && secondsToTime(this.result[1].times[0])}</td>
										</tr>
										<tr>
											<td>{this.result[0].times[1] != 0 && secondsToTime(this.result[0].times[1])}</td>
											<th>T1</th>
											<td>{this.result[1].times[1] != 0 && secondsToTime(this.result[1].times[1])}</td>
										</tr>
										<tr>
											<td>{this.result[0].times[2] != 0 && secondsToTime(this.result[0].times[2])}</td>
											<th>BIKE</th>
											<td>{this.result[1].times[2] != 0 && secondsToTime(this.result[1].times[2])}</td>
										</tr>
										<tr>
											<td>{this.result[0].times[3] != 0 && secondsToTime(this.result[0].times[3])}</td>
											<th>T2</th>
											<td>{this.result[1].times[3] != 0 && secondsToTime(this.result[1].times[3])}</td>
										</tr>
										<tr>
											<td>{this.result[0].times[4] != 0 && secondsToTime(this.result[0].times[4])}</td>
											<th>RUN</th>
											<td>{this.result[1].times[4] != 0 && secondsToTime(this.result[1].times[4])}</td>
										</tr>
										<tr>
											<td>{this.result[0].times[5] != 0 && secondsToTime(this.result[0].times[5])}</td>
											<th>FINISH</th>
											<td>{this.result[1].times[5] != 0 && secondsToTime(this.result[1].times[5])}</td>
										</tr>
									</table>
								</div>
							</div>}
							{ (!state.closed && !all_played) && <div><b class='pto-red'>To play the game, you need enter all the Swim, T1, Bike, T2 & Run times for both athletes.</b></div>}
							{ (!state.closed && all_played) && <div><b class='check'>Well done! You've entered all the required times to play the Zwift Tri Battle Royale Game.</b>
								<div class='pt-2'>You can now <a href='fantasy/leagues/create' class='pto-red'><b>create</b></a> or <a href='fantasy/leagues/join' class='pto-red'><b>join</b></a> leagues to play with your friends.</div>
							</div>}
							{ all_played && <button class='btn btn-primary mt-4' onClick={this.saveImage}>Generate Image to Share</button> }
							{ state.image_generated && <div class='d-flex flex-column justify-content-center py-4'>
								<small class='text-center py-4 mt-4 px-2 f110'>To save or share, long press on the image on mobile device, or right click on desktop.</small>
								<img class='w-100' ref={this.imageRef} style='border: 1px solid rgba(0,0,0,0.1);'/>
								{/*navigator.share*/ false && <div class='pt-4'>
									<button class='btn btn-primary' onClick={this.share}>Share</button>
								</div>}
							</div> }
						</div>
						<div class='py-4 mt-4'>
							<div class='h3 text-center pb-4'>The Course</div>
							<div class="embed-responsive embed-responsive-16by9">
								<iframe class='embed-responsive-item' src="https://player.vimeo.com/video/564537148" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</div>
				<div class='timeselect-container' show={state.showtimeselect}>
					<div class='timeselect'>
						<div class='title'>{state.select_title}</div>
						<div class='subtitle pb-3'>{state.select_subtitle}</div>
						<div class='big-time'>
							<div class='number'>{state.select_hours}</div>
							<div class='dots'>:</div>
							<div class='number'>{state.select_minutes < 10 ? "0" : ""}{state.select_minutes}</div>
							<div class='dots'>:</div>
							<div class='number'>{state.select_seconds < 10 ? "0" : ""}{state.select_seconds}</div>
						</div>
						<div>
							<div class='h5 mt-4'>Hours</div>
							<input type="range" min="0" max="10" value={state.select_hours} onInput={this.sliderInput} data-target="select_hours" ref={this.rangeHoursRef}/>
						</div>
						<div>
							<div class='h5 mt-4'>Minutes</div>
							<input type="range" min="0" max="59" value={state.select_minutes} onInput={this.sliderInput} data-target="select_minutes" ref={this.rangeMinutesRef}/>
						</div>
						<div>
							<div class='h5 mt-4'>Seconds</div>
							<input type="range" min="0" max="59" value={state.select_seconds} onInput={this.sliderInput} data-target="select_seconds" ref={this.rangeSecondsRef}/>
						</div>
						<div class='d-flex justify-content-center pt-4'>
							<button class='btn btn-primary' onClick={this.closeTimeSelect}>Close</button>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

