import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime, getSeconds, AthletePic, ordinal_suffix_of } from "/server/components/utils"
import moment from 'moment';


function loadScript(url)
{
	return new Promise(success => {
		let scriptChartjs = document.createElement("script")
		scriptChartjs.src = url
		document.getElementsByTagName("head")[0].appendChild(scriptChartjs)
		scriptChartjs.onload = success 
		scriptChartjs.onerror = () => { console.log('error loading script', url) } 
	})
}


class AthleteView extends Component {

	container = (d) => {
		this.dom = d
	}

	shouldComponentUpdate()
	{
		return false
	}

	load = (link) => {
		this.dom.innerHTML = ""
		let that = this
		fetch("/athlete/" + link + "/info/embed")
		.then(data => data.json())
		.then(data => {
			that.dom.innerHTML = data.html
		})

	}

	render(props, state)
	{
		return (
			<div ref={this.container}>
			</div>
		)
	}
}

class Game_CollinsCup_HowToPlay extends Component {
	render()
	{
		return(
			<div class='text-center p-3 max-width'>
				<div class='h4 mb-3'>How to Play.</div>
				<div class='mb-3'>Select the athlete you think will finish <b>1st, 2nd & 3rd</b> in <b>every race</b>.</div>

				<div class='mb-1'>Each athlete scores points based on their finish position:</div> 
				<div class='mb-3'><b>1st 300 points, 2nd 200 points & 3rd 100 points</b></div> 

				<div class='mb-1'>They also score points based on the time gap to their opponent(s) behind them:</div> 
				<div class='mb-3'><b>Gap in seconds (max of 6 mins) / 2.4</b></div> 

				<div class='mb-1'>For example, in this race: Jan: 3:20:57, Sam: +3:54, Lionel: +9:06</div>
				<div class='mb-1'>The scores would be as followed:</div>
				<div class='mb-3'>Jan score: 300 + 97.5 + 150 = 547.5, Sam score: 200 + 130 = 330, Lionel: 100</div>

				<div class='mb-1'><b>For each athlete's position you guessed correctly you get their points added to your total points.</b></div>
				<div class='mb-1'>The more points you have, the higher you will be ranked.</div>
			</div>
		)
	}
}

class Prize extends Component {
	render(props)
	{
		return(
			<div class='fantasy-prize'>
				<div class='pic'><img src={props.pic}/></div>
				<div class='info'>
					<div class='title'>{props.title}</div>
					<div class='description'>{props.description}</div>
				</div>
			</div>
		)
	}
}

class Game_CollinsCup_Prizes extends Component {
	render()
	{
		return(
			<div class='text-center p-3 max-width'>
				<div class='h4 mb-3'>Prizes</div>

				<div class='d-flex flex-wrap justify-content-center'>
					<Prize 
						pic="https://content.protriathletes.org/media/content/test/20fed75e-afbc-4077-b073-bdbb024bdbcc.jpg" 
						title="1st = VIP trip for 2 to visit Collins Cup 2022" 
						description="To include economy class flights, 5 nights hotel, race entry and VIP access all week."
					/>

					<Prize 
						pic="https://content.protriathletes.org/media/content/test/cd850e60-7a38-4732-b231-74d3cce080e9.png" 
						title="2 & 3rd = Courtesy of Tag Heuer" 
						description="A Tag Heuer Connected Watch as being awarded to the athletes on the winning team."
					/>

					<Prize 
						pic="https://content.protriathletes.org/media/content/test/a9533a23-fe85-49bf-b743-a873bbdcfd47-thumb.jpg" 
						title="4th = Wahoo KICKR Bike" 
						description=""
					/>

					<Prize 
						pic="https://content.protriathletes.org/media/content/test/0a3b64ab-8f09-4f2a-9d4f-168fdbc35984-thumb.jpg" 
						title="5 & 6th = Wahoo KICKR Smart Trainer" 
						description=""
					/>

					<Prize 
						pic="https://content.protriathletes.org/media/content/test/12a12d5d-e6a1-4f7c-8319-01a39c9633d2-thumb.jpg" 
						title="7, 8, 9 & 10th = Wahoo ELEMNT RIVAL" 
						description=""
					/>
				</div>
			</div>
		)
	}
}

export class Game_CollinsCup extends Component {

	componentWillMount()
	{
		this.init()

	}
	componentWillUnmount()
	{
	}

	init = async () => {

		let info_req = await fetch("/api/fantasy/cc_info")
		let info_json = await info_req.json()
		this.teams = info_json.teams
		this.athletes = info_json.athletes
		this.athletes_ids = info_json.athletes_ids

		this.setState({ athletes: this.athletes, athletes_ids: this.athletes_ids, teams: this.teams })
	}

	getAthleteTeam = (id) => {
		for(let team of this.teams)
		{
			if(team.men.indexOf(id) != -1) return team
			if(team.women.indexOf(id) != -1) return team
		}
		return null
	}

	sections = { }
	team = d => 
	{
		if(!d) return
		let shortname = d.dataset.shortname
		this.sections[shortname] = d
	}

	goToTeam = e => 
	{
		let shortname = e.target.dataset.shortname
		if(this.sections[shortname]) {
			const yOffset = -70
			const y = this.sections[shortname].getBoundingClientRect().top + window.pageYOffset + yOffset

			window.scrollTo({top: y, behavior: 'smooth'});			
			//this.sections[shortname].scrollIntoView({behavior: "auto", block: "start", inline: "start"})
		}
	}

	render(props, state)
	{
		return(
			<div class='race-container cc-info'>
				<div class='d-flex mt-4 px-4 w-100' style='height: 150px;'>
					<img class='cc-logo-home' src="https://content.protriathletes.org/media/content/test/5643c93b-0f5f-4eeb-b9f2-22955f08cd70.png" style='object-fit: contain; max-width: 100%; margin: 0 auto;'/>
				</div>
				<div>
					<div class='h4 text-center p-4'>The game will start on August 25th after the Captains made their picks for the matches!</div>
				</div>
				<div class='p-3 w-100 d-flex justify-content-center flex-wrap' style='gap: 4px;'>
					<div class='pto-btn red' onClick={this.goToTeam} data-shortname="howtoplay">How To Play</div>
					<div class='pto-btn red' onClick={this.goToTeam} data-shortname="prizes">Prizes</div>
					<a class='pto-btn red' href='/fantasy/collinscup-2021-media'>Media Competition</a>
				</div>
				{state.athletes && <div class=''>
					{state.teams.map(team => {
						return (
							<div class='team' data-shortname={team.shortname} ref={this.team}>
								<div class='h4 mb-3'>Team {team.name}</div>
								<div class='athletes'>
									{team.women.map(athlete_id => { 
										let athlete = this.athletes.find(x => x.id == athlete_id)
										return (
										<div class='athlete' team={team.shortname}>
											<div class='pic'>
												<AthletePic pic={athlete && athlete.pic} />
											</div>
										</div>

									)})}	
								</div>
								<div class='athletes'>
									{team.men.map(athlete_id => { 
										let athlete = this.athletes.find(x => x.id == athlete_id)
										return (
										<div class='athlete' team={team.shortname}>
											<div class='pic'>
												<AthletePic pic={athlete && athlete.pic} />
											</div>
										</div>

									)})}	
								</div>
							</div>
						)
					})
					}
				</div>}
				<div class='w-100 mx-auto pb-4' data-shortname="howtoplay" ref={this.team}>
					<Game_CollinsCup_HowToPlay/>
				</div>
				<div class='w-100 mx-auto pb-4' data-shortname="prizes" ref={this.team}>
					<Game_CollinsCup_Prizes/>
				</div>
			</div>
		)
	}
}


export class _Game_CollinsCup extends Component {
	state = { loading: true, game_id: 1 }

	componentDidMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		
		const info_fetch = await fetch("/api/fantasy/races")
		const info_json = await info_fetch.json()

		this.athletes = info_json.athletes
		this.teams = info_json.teams
		this.races = info_json.races

		this.play = {
			orders: {}
		}

		const play_fetch = await fetch(`/api/fantasy/${this.state.game_id}/play`)
		const play_json = await play_fetch.json()
		//console.log(play_json)
		this.game_info = play_json.game
		if(play_json.play)
		{
			let data = JSON.parse(play_json.play.data)
			this.play = data
			for(let num in this.play.orders)
			{
				//console.log(num, this.play.orders[num])
				this.races[num].order = this.play.orders[num]
				this.races[num].status = 'complete'
			}
			if(this.races[7].order)
			{
				for(let i = 0; i < this.races[7].order.length; i++)
				{
					if(this.races[7].order[i] == 3300) this.races[7].order[i] = 5236	
				}
			}
		}

		let now = parseInt(new Date().getTime() / 1000)
		this.setState({ loading: false, closed: now > this.game_info.deadline })
		//console.log(info_json)
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	sections = { }
	team = d => 
	{
		if(!d) return
		let shortname = d.dataset.shortname
		this.sections[shortname] = d
	}

	goToTeam = e => 
	{
		let shortname = e.target.dataset.shortname
		if(this.sections[shortname]) {
			const yOffset = -70
			const y = this.sections[shortname].getBoundingClientRect().top + window.pageYOffset + yOffset

			window.scrollTo({top: y, behavior: 'smooth'});			
			//this.sections[shortname].scrollIntoView({behavior: "auto", block: "start", inline: "start"})
		}
	}

	getAthleteTeam = (id) => {
		for(let team of this.teams)
		{
			if(team.men.indexOf(id) != -1) return team
			if(team.women.indexOf(id) != -1) return team
		}
		return null
	}

	onSelectPlaces = (e) => {
		let racedom = e.target.closest(".race")
		let raceindex = parseInt(racedom.dataset.raceindex)
		let race = this.races[raceindex]
		
		//console.log(race.status)
		if(race.status == 'complete')
		{
			race.edit = []
			race.status = 'edit'
		}
		else
		{
			race.status = 'complete'
		}

		this.setState({})
	}

	onAthleteSelectPlace = (e) => {
		let racedom = e.target.closest(".race")
		let raceindex = parseInt(racedom.dataset.raceindex)
		let race = this.races[raceindex]
		let place = e.target.closest(".place")

		if(place.getAttribute("status") == "complete") return

		if(race.mode == null)
		{
			race.mode = 'edit'
			race.edit = []
		}
		this.setState({})
		e.stopPropagation()
	}

	onAthleteSelect = (e) => {
		let racedom = e.target.closest(".race")
		let raceindex = parseInt(racedom.dataset.raceindex)
		let race = this.races[raceindex]
			
		let selectPlace = e.target.closest(".select-place")

		if(race.status != 'complete')
		{
			let picdom = e.target.closest(".athlete")
			let athleteindex = parseInt(picdom.dataset.athleteindex)
			let athleteid = parseInt(picdom.dataset.athleteid)
			
			if(!race.edit)
			{
				race.edit = [athleteid]
			}
			else if(!race.edit.find(x => x == athleteid))
			{
				race.edit.push(athleteid)
			}
			else
			{
				race.edit = []
			}

			if(race.edit.length == 2)
			{
				let third = race.participants.find(x => x != race.edit[0] && x != race.edit[1])
				race.edit.push(third)
				race.order = race.edit
				race.status = 'complete'
				race.mode = null
				race.instructions = null
				race.edit = null

				this.play.orders[raceindex] = race.order

				this.save()
				//console.log(JSON.stringify(this.play))
			}
		}
		/*else
		{
			let athlete = e.target.closest(".athlete")
			this.athleteViewDom.load(athlete.dataset.link)
			document.body.classList.add("noscroll")
			this.setState({ showathlete: true })
		}*/
		this.setState({})
	}

	save = _ => {
		fetch(`/api/fantasy/${this.state.game_id}/play`, { 
			method: "post", 			
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ play: this.play, version: 1})
		})
		.then((r) => r.json())
		.then((d) => {
			if(d.status == 1)
			{
				alert("The race has already started.\nYou can't play anymore.")
			}
		})
	}


	/*athleteView = (d) => { this.athleteViewDom = d }
	athleteClick = (e) => {
		let athlete = e.target.closest(".athlete")
		this.athleteViewDom.load(athlete.dataset.link)
		document.body.classList.add("noscroll")
		this.setState({ showathlete: true })
	}
	closeathlete = (e) => {
		let container = e.target.closest(".athlete-modal-container")
		let close = e.target.closest(".close-modal")
		if(container != e.target && close != e.target) return;
		document.body.classList.remove("noscroll")
		this.setState({ showathlete: false })
	}*/

	loadImage = async src => {
		return new Promise((resolve, reject) => {
			let img = new Image()
			img.onload = () => resolve(img)
			img.onerror = reject
			img.src = src
		})
	}

	loadPixiImage = async src => {
		return new Promise((resolve, reject) => {
			const loader = PIXI.Loader.shared
			loader.add({ url: src, onComplete: function() {
				resolve(loader.resources[src])
			}})
			loader.load()
		})
	}


	fillTextCenter = (ctx, text, x, y, center_y = false) => {
		let m = ctx.measureText(text)
		ctx.fillText(text, x - m.width * 0.5, y + (center_y ? m.actualBoundingBoxAscent * 0.5 : 0))
		return m
	}

	roundedRect = (ctx, x, y, width, height, radius) => {
		ctx.beginPath();
		ctx.moveTo(x, y + radius);
		ctx.lineTo(x, y + height - radius);
		ctx.arcTo(x, y + height, x + radius, y + height, radius);
		ctx.lineTo(x + width - radius, y + height);
		ctx.arcTo(x + width, y + height, x + width, y + height-radius, radius);
		ctx.lineTo(x + width, y + radius);
		ctx.arcTo(x + width, y, x + width - radius, y, radius);
		ctx.lineTo(x + radius, y);
		ctx.arcTo(x, y, x, y + radius, radius);
		//ctx.stroke();
	}
	
	generateAthletesImages = async _ => {
		await loadScript("https://pixijs.download/release/pixi.js")

		let eur_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/718fde03-b0e2-437a-b026-b18127be066d.jpg'))
		let int_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/91bbd31d-32eb-4b62-9e83-10ef8279075b.jpg'))
		let usa_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/f21bdd97-e423-4a60-9f39-0af549f3176a.jpg'))

		let flags = [eur_image, int_image, usa_image]
		let team_name = ["Team Europe", "Team Internationals", "Team US"]

		let cinzel_font = new FontFace('Cinzel', 'url(/assets/fonts/Cinzel-Bold.ttf)')
		await cinzel_font.load()
		document.fonts.add(cinzel_font)

		let pattern = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/974bae32-a514-4171-8550-7d1f9ae7c68e-thumb.jpg'))
		let cc_logo = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/4d6574df-a47f-4ea8-85de-f6c146b311c1.png'))
		let mask = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/e6f5b905-b8f6-47b1-ba10-b4fd0d26c291.jpg'))

		{
			let canvasWidth = 1920, canvasHeight = 1080
			let hero_width = 700

			PIXI.settings.MIPMAP_TEXTURES = PIXI.MIPMAP_MODES.ON

			let canvas = document.createElement("canvas");
			let app = new PIXI.Application({ width: 1920, height: 1080, antialias: true, view: canvas, resolution: 1, backgroundColor: 0x000000, preserveDrawingBuffer: true })


			const mask_sprite = new PIXI.Sprite(new PIXI.Texture(new PIXI.BaseTexture(mask)))
			mask_sprite.width = canvasWidth
			mask_sprite.height = canvasHeight


			var g = new PIXI.Graphics();
			g.alpha = 0.9
			g.beginTextureFill({ texture: new PIXI.Texture(new PIXI.BaseTexture(pattern)), matrix:  new PIXI.Matrix().scale(1.3,1) });
			g.drawRoundedRect(0, 0, hero_width * 1.3, 200, 20);
			g.endFill();
			g.x = canvasWidth * 0.5 - hero_width * 1.3 * 0.5
			g.y = 400 + hero_width * 0.5

			let renderTexture = PIXI.RenderTexture.create({ width: 1920, height: 1080 });
			let renderTextureSprite = new PIXI.Sprite(renderTexture)

			let fadeSideTexture = null
			{
				const canvas = document.createElement('canvas')
				canvas.width = hero_width
				canvas.height = 1
				const ctx = canvas.getContext('2d')
				const grd = ctx.createLinearGradient(0, 0, hero_width, 0);
				grd.addColorStop(0, 'rgba(255,255,255,0)')
				grd.addColorStop(0.1, 'rgba(255,255,255,255)')
				grd.addColorStop(0.9, 'rgba(255,255,255,255)')
				grd.addColorStop(1, 'rgba(255,255,255,0)')
				ctx.fillStyle = grd
				ctx.fillRect(0, 0, hero_width, 1)
				fadeSideTexture = PIXI.Texture.from(canvas)
				console.log(fadeSideTexture)
			}
			let fadeSideSprite = new PIXI.Sprite(fadeSideTexture)
			fadeSideSprite.x = canvasWidth * 0.5 - hero_width * 0.5
			fadeSideSprite.y = 400 - hero_width * 0.5
			fadeSideSprite.width = hero_width
			fadeSideSprite.height = hero_width


			let count = 40
			for(let athlete of this.athletes)
			{
				if(athlete.link != "collin-chartier") continue
				app.stage.removeChildren()

				let team = this.getAthleteTeam(athlete.id)
				let pic = athlete.pic && JSON.parse(athlete.pic)
				if(!pic) continue

				const flag = new PIXI.Sprite(new PIXI.Texture(new PIXI.BaseTexture(flags[team.index])))
				flag.width = canvasWidth
				flag.height = canvasHeight
				flag.alpha = 0.8
				flag.mask = mask_sprite
				app.stage.addChild(flag)

				const logo = new PIXI.Sprite(new PIXI.Texture(new PIXI.BaseTexture(cc_logo)))
				logo.anchor.set(0.5)
				logo.x = canvasWidth * 0.5
				logo.y = 1000
				logo.scale.x = 0.2
				logo.scale.y = 0.2
				app.stage.addChild(logo)

				let profile_image = await this.loadImage("/proxy?url=" + encodeURIComponent(pic.img.src))
				const profile = new PIXI.Sprite(new PIXI.Texture(new PIXI.BaseTexture(profile_image)))

				profile.x = canvasWidth * 0.5 - hero_width * 0.5
				profile.y = 400 - hero_width * 0.5
				profile.width = hero_width
				profile.height = hero_width * profile_image.naturalHeight / profile_image.naturalWidth
				profile.mask = fadeSideSprite

				app.stage.addChild(profile)
				app.stage.addChild(fadeSideSprite)

				app.stage.addChild(g)

				{
					const style = new PIXI.TextStyle({
						fontFamily: "Cinzel",
						fontSize: 70,
						fill: "white",
					});
					const text = new PIXI.Text(athlete.name, style);
					text.x = canvasWidth * 0.5
					text.y = 820
					text.anchor.set(0.5)
					app.stage.addChild(text)
				}

				{
					const style = new PIXI.TextStyle({
						fontFamily: "Cinzel",
						fontSize: 50,
						fill: "#cccccc",
					});
					const text = new PIXI.Text(team_name[team.index], style);
					text.x = canvasWidth * 0.5
					text.y = 890
					text.anchor.set(0.5)
					app.stage.addChild(text)
				}
				app.render()

				let a = document.createElement('a')
				a.download = athlete.link + ".jpg"
				a.target = '_blank'
				a.href = canvas.toDataURL('image/png')
				document.body.appendChild(a)
				a.click()
				a.remove()

				count--
				if(count <= 0) break
				//break
			}

		}
	}

	generateImage = async _ => {

		let black_font = new FontFace('LatoBlack', 'url(/assets/fonts/Lato/Lato-Black.ttf)')
		await black_font.load()
		document.fonts.add(black_font)

		let regular_font = new FontFace('LatoRegular', 'url(/assets/fonts/Lato/Lato-Regular.ttf)')
		await regular_font.load()
		document.fonts.add(regular_font)

		let thin_font = new FontFace('LatoThin', 'url(/assets/fonts/Lato/Lato-Light.ttf)')
		await thin_font.load()
		document.fonts.add(thin_font)

		let cc_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/5643c93b-0f5f-4eeb-b9f2-22955f08cd70.png'))

		/*let eur_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/3af5cc93-97f9-4da3-a702-929577f914fa-thumb.jpg'))
		let int_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/72ebb4c6-076d-41c3-a947-16bfa8c6dc3a-thumb.jpg'))
		let usa_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/media/content/test/bd9d72d9-38b6-4f1a-99ba-d33b83da2f68-thumb.jpg'))
		*/

		let eur_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/website/assets/eu_flag_wave.jpg'))
		let int_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/website/assets/int_flag_wave.jpg'))
		let usa_image = await this.loadImage("/proxy?url=" + encodeURIComponent('https://content.protriathletes.org/website/assets/us_flag_wave.jpg'))

		let flags = [eur_image, int_image, usa_image]

		let canvas = document.createElement("canvas");
		canvas.width = 2000
		canvas.height = 2000
		const ctx = canvas.getContext('2d')

		ctx.clearRect(0, 0, canvas.width, canvas.height)
		ctx.beginPath()
		ctx.rect(0, 0, canvas.width, canvas.height)
		ctx.fillStyle = "white"
		ctx.fill()

		let hero_width = 200
		ctx.drawImage(cc_image, 1000 - 500, -20, 1000, 1000 * cc_image.naturalHeight / cc_image.naturalWidth)
		ctx.font = '70px LatoRegular'
		ctx.fillStyle = '#000000'
		this.fillTextCenter(ctx, "FANTASY GAME", 1000, 290)


		ctx.save()
		ctx.translate(0, 350);
		for(let race_index = 0; race_index < this.races.length; race_index++)
		{
			let race = this.races[race_index]
			let first_id = race.order[0]
			let athlete = this.athletes.find(x => x.id == first_id)
			let team_first = this.getAthleteTeam(athlete.id)
			let pic = athlete.pic && JSON.parse(athlete.pic)
			console.log(pic)
			let profile_image = await this.loadImage("/proxy?url=" + encodeURIComponent(pic.img.src.replace(".png", "-w300.png")))

			let x = race_index >= 6 ? 1000 + 30 : 1000 - hero_width - 30
			let y = (race_index % 6) * (hero_width + 60)

			ctx.save()
			let gradient = ctx.createLinearGradient(0,0, 1000,0)
			if(x > 1000)
			{
				ctx.translate(x - 16, y);
				gradient.addColorStop(0.5, team_first.color_bright.replace(')', ',0.2)')) //rgba(151,47,53,0.1)')
				gradient.addColorStop(1, team_first.color_bright.replace(')', ',0.0)'))
				ctx.fillStyle = gradient
				//ctx.fillRect(0, 0, 800, 240)
				ctx.beginPath()
				ctx.arc(120, 120, 120, Math.PI * 0.5, 1.5 * Math.PI)
				ctx.lineTo(1000, 0)
				ctx.lineTo(1000, 240)
				ctx.closePath()
				ctx.strokeStyle = team_first.color_bright
				ctx.stroke()
				ctx.fill()

			}
			else
			{
				ctx.translate(x + 16 - 1000 + hero_width, y);
				gradient.addColorStop(0, team_first.color_bright.replace(')', ',0.0)'))
				gradient.addColorStop(1, team_first.color_bright.replace(')', ',0.2)')) //'rgba(0,0,0,0.1)')
				ctx.beginPath()
				ctx.arc(1000 - 120, 120, 120, Math.PI * 0.5, 1.5 * Math.PI, true)
				ctx.lineTo(0, 0)
				ctx.lineTo(0, 240)
				ctx.closePath()
				//ctx.fillStyle = '#000'
				//ctx.fill()

				ctx.fillStyle = gradient
				ctx.strokeStyle = team_first.color_bright
				ctx.stroke()
				ctx.fill()
				//ctx.fillRect(0, 0, 800, 240)
			}
			ctx.restore()


			{
				ctx.save()
				let flag = flags[team_first.index]
				ctx.beginPath()
				ctx.arc(x + 100, y + 120, 100, 0, 2 * Math.PI)
				ctx.clip()
				ctx.fillStyle = '#000'
				ctx.fillRect(x, y, x + 800, y + 240)
				ctx.globalAlpha = 0.8
				ctx.drawImage(flag, x - 65, y + 20, 330, 330 * flag.naturalHeight / flag.naturalWidth)

				ctx.restore()
			}

			{
				ctx.save()
				ctx.beginPath()
				ctx.arc(x + 100, y + 120, 100, 0, 2 * Math.PI)
				ctx.clip()
				ctx.drawImage(profile_image, x, y + 40, hero_width, hero_width * profile_image.naturalHeight / profile_image.naturalWidth)
				ctx.restore()
			}

			{
				ctx.save()
				ctx.globalAlpha = 1
				ctx.strokeStyle = team_first.color_bright
				ctx.lineWidth = 4
				ctx.arc(x + 100, y + 120, 100, 0, 2 * Math.PI)
				ctx.stroke()
				ctx.restore()
			}

			{
				ctx.fillStyle = '#444'
				ctx.font = '45px LatoThin'
				let text = "Match " + (race_index + 1)
				let mm = ctx.measureText(text)
				ctx.fillText(text, x > 1000 ? x + hero_width + 16 : x - 16 - mm.width, y + 50)
			}


			let down = 0, place = 1
			ctx.fillStyle = '#222'
			for(let athlete_id of race.order)
			{
				let team = this.getAthleteTeam(athlete_id)
				let a = this.athletes.find(x => x.id == athlete_id)

				let text_y = down + y + 110

				let flag = flags[team.index]


				if(x > 1000)
				{
					let text_x = x + hero_width + 16

					ctx.font = '45px LatoRegular'
					ctx.fillText(place, text_x, text_y)

					text_x += 40
					ctx.drawImage(flag, text_x + 4, text_y - 30, 60, 60 * flag.naturalHeight / flag.naturalWidth)

					text_x += 80

					ctx.font = '45px LatoThin'
					let text = a.first.toUpperCase()
					let mf = ctx.measureText(text)
					ctx.fillText(text, text_x, text_y)
					text_x += mf.width + 8
		
					ctx.font = '45px LatoBlack'
					text = a.last.toUpperCase()
					let ml = ctx.measureText(text)
					ctx.fillText(text, text_x, text_y)
				}
				else
				{
					let text_x = x - 16

					ctx.font = '45px LatoRegular'
					let mp = ctx.measureText(place)
					ctx.fillText(place, text_x - 51 + mp.width, text_y)

					text_x -= 51

					ctx.drawImage(flag, text_x - 54, text_y - 30, 60, 60 * flag.naturalHeight / flag.naturalWidth)

					text_x -= 80

					ctx.font = '45px LatoBlack'
					let text = a.last.toUpperCase()
					let ml = ctx.measureText(text)
					ctx.fillText(text, text_x - ml.width, text_y)

					text_x -= ml.width + 8
					
					ctx.font = '45px LatoThin'
					text = a.first.toUpperCase()
					let mf = ctx.measureText(text)
					ctx.fillText(text, text_x - mf.width, text_y)
				}
		
				down += 55
				place += 1

			}
		}

		ctx.restore()


		ctx.save()
			ctx.translate(0, 1960);

			ctx.fillStyle = '#33363a'
			ctx.font = '40px LatoRegular'
			this.fillTextCenter(ctx, "Make your Collins Cup Match selection at protri.org/fantasy", 1000, 0)
		ctx.restore()
	
		return canvas		
	}

	imageRef = createRef()
	saveImage = async e => {

		this.setState({ image_generating: true })
		let canvas = await this.generateImage()

		this.setState({ image_generated: true, image_generating: false }, () => {
			if(this.imageRef.current) this.imageRef.current.src = canvas.toDataURL('image/jpeg', 0.95)
		})
	}


	render(props, state)
	{
		if(state.loading) return		
			
		let completed = 0, total = 0
		for(let race of this.races)
		{
			total++
			if(race.status == 'complete') completed++
		}

		return(
			<div class='bg-white collins-cup'>
				<div class='race-container cc-game max-width pb-4'>
					<div class='d-flex mt-4 px-4 w-100' style='height: 150px;'>
						<img class='cc-logo-home' src="https://content.protriathletes.org/media/content/test/5643c93b-0f5f-4eeb-b9f2-22955f08cd70.png" style='object-fit: contain; max-width: 100%; margin: 0 auto;'/>
					</div>
					<div class='p-3 w-100 d-flex justify-content-center flex-wrap' style='gap: 4px;'>
						<div class='pto-btn red' onClick={this.goToTeam} data-shortname="howtoplay">How To Play</div>
						<div class='pto-btn red' onClick={this.goToTeam} data-shortname="prizes">Prizes</div>
						<a class='pto-btn red' href='/fantasy/collinscup-2021-media'>Media Competition</a>
					</div>

					{ state.closed && <div class={'h3 text-capitalize text-center py-4 pto-red w-100'}>This game is now closed.</div> }

					{ this.races.map((race, race_index) => {
						
						let instructions = null
						let instruction_type = "warning"
						let btnText = null, btnClass = 'btn-primary'

						if(race.status == 'complete')
						{
							btnText = 'Edit Prediction'
							btnClass = 'btn-success' 
							//instructions = "Selection completed! ✔"
							//instruction_type = "success"
						}
						else if(!race.status || race.status == 'edit')
						{
							instructions = "Select " + (!race.edit || race.edit.length == 0 ? "Winner" : "2nd place")
							instruction_type = "action"
						}
						else if(race.order) 
						{
							btnText = 'Edit Prediction'
							btnClass = 'btn-success' 
						}
						else
						{
						}
						return (
							<div class='race' data-raceindex={race_index} status={race.status}>
								<div class='interface'>
									<h4>
										{race.name}
										{ race.status == 'complete' && <i class="fas fa-check-circle check"></i> }
									</h4>
									{!state.closed && btnText && 
										<button class={'btn btn-sm ' + btnClass} onClick={this.onSelectPlaces}>
											{btnText}
										</button>
									}
									{instructions &&
										<div class='instructions' message-type={instruction_type}>{instructions} <i class="fas fa-long-arrow-alt-down"></i></div>
									}
								</div>
								<div class='participants' doneoredit={race.order || race.mode ? 1 : 0}>
									{ race.participants.map((participant_id, athlete_index) => {
										let athlete = this.athletes.find(x => x.id == participant_id)
										let team = this.getAthleteTeam(participant_id)
										let order = -1 
										if(race.order) order = race.order.indexOf(participant_id)
										if(order == -1) order = athlete_index

										let places = ["1st", "2nd", "3rd"]
										let showplace = null, showselect = null

										if(race.status == 'complete') 
										{
											showplace = places[order]
										}
										else //if(race.status == 'edit') 
										{
											let index = race.edit ? race.edit.indexOf(participant_id) : -1
											if(index != -1) 
											{
												showplace = places[index]
											}
											else
											{
												//showselect = !race.edit || race.edit.length == 0 ? "Winner" : "2nd place"
											}
										}
										return (
											<div class='athlete-container'
												style={'--order:' + (order * 93) + 'px'}
											>
											<div class='athlete'														
												onClick={this.onAthleteSelect} 
												data-athleteindex={athlete_index} 
												data-athleteid={participant_id} 
												data-link={athlete.link} 
												mode={race.mode} 
												place={race.edit && race.edit.length}
												chosen={race.edit && race.edit.findIndex(x => x == participant_id)}
												team={team.shortname}
												>
												<div class='pic'>
													<AthletePic pic={athlete && athlete.pic} />
												</div>
												<div class='info'>
													<div class='name'>
														<div class='first'>{athlete.first}</div>
														<div class='last'>{athlete.last}</div>
													</div>
													{athlete.overall_rank > 0 && 
														<div class='team'>
															<div class='team-text'>
																<div class='team-team'>PTO WORLD #{athlete.overall_rank}</div>
															</div>
														</div>
													}
												</div>
												{showplace && 
													<div class='place' _onClick={this.onAthleteSelectPlace} status={race.status}>
														{showplace}
													</div> 
												}
												{showselect && 
													<div class='select-place btn btn-sm btn-primary' _onClick={this.onAthleteSelectPlace} status={race.status}>
														{showselect}
													</div> 
												}
											</div>
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			<div class='selection-progress'>
				{!state.closed && <div class='info'>
					<div class='bar' style={'right:' + (100*(total - completed)/total) + "%"}/>
					<div class='text'>{completed == total ? "You are all set!" : `Progress ${completed}/${total}`}</div>
				</div>}
			</div>

			<div class='max-width text-center'>
				{ (!state.closed && completed == total) && <div class='pt-4'><b class='check h5'>Well done! You made your selection to play the Collins Cup Fantasy Game.</b>
					<div class='pt-2'>You can now <a href='fantasy/leagues/create' class='pto-red'><b>create</b></a> or <a href='fantasy/leagues/join' class='pto-red'><b>join</b></a> leagues to play with your friends.</div>
				</div>}
				{ completed == total && <button class='pto-btn black mt-4' onClick={this.saveImage}>Generate Image to Share</button> }

				{ state.image_generating && <div class='d-flex flex-column justify-content-center py-4'>Generating Image...</div> }

				{ state.image_generated && <div class='d-flex flex-column justify-content-center pb-4'>
					<small class='text-center py-4 px-2 f110'>To save or share, long press on the image on mobile device, or right click on desktop.</small>
					<img class='w-100' ref={this.imageRef} style='border: 1px solid rgba(0,0,0,0.1);'/>
					{/*navigator.share*/ false && <div class='pt-4'>
						<button class='btn btn-primary' onClick={this.share}>Share</button>
					</div>}
				</div> }
				<div class='w-100 mx-auto py-4' data-shortname="howtoplay" ref={this.team}>
					<Game_CollinsCup_HowToPlay/>
				</div>
				<div class='w-100 mx-auto pb-4' data-shortname="prizes" ref={this.team}>
					<Game_CollinsCup_Prizes/>
				</div>
			</div>
			<div class='athlete-modal-container' show={state.showathlete} onClick={this.closeathlete}>
				<div class='athlete-modal-header'>
					<i class="fas fa-times close-modal" onClick={this.closeathlete}></i>
				</div>
				<div class='athlete-modal'>
					<AthleteView ref={this.athleteView}/>
				</div>
			</div>
		</div>
		)
	}
}

