let instagram = document.getElementById('instagram')

if(instagram)
{
	console.log('found instagram')	
	let attributes = JSON.parse(instagram.dataset.instagrams)
	console.log(attributes)

	//for (let a of attributes)
	let a = attributes[3]
	{
		console.log(a.value)
		//fetch(a.value + (a.value[a.value.length-1] == '/' ? "" : "/") + (a.value.indexOf("?") == -1 ? "?" : "&") + "__a=1", {redirect: 'follow'})
		fetch(a.value  + "?__a=1", {})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data_search) {
			console.log(data_search.graphql.user.full_name, data_search.graphql.user.edge_followed_by.count)
		})
	}
}

