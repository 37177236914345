import { h, render, Component, createRef, Fragment } from 'preact'
import { useEffect, useLayoutEffect } from 'preact/hooks'
import { secondsToTime, getSeconds, AthletePic } from "../server/components/utils"
import moment from 'moment';

let __session__ = null

let expandDetails = document.getElementsByClassName("cc-expand-details")
if(expandDetails)
{
	for(let expand of expandDetails)
	{
		expand.addEventListener("click", function(e) {
			let details = expand.parentNode.classList.toggle('show-details') //getElementsByClassName("details")
		})
		let details = expand.parentNode.getElementsByClassName("details")
		if(details.length)
		{
			details[0].addEventListener('click', function(e) {
				expand.parentNode.classList.toggle('show-details')
			})
		}
	}
}

/*let ptoEventContainer = document.getElementById('pto-event-container')
if(ptoEventContainer)
{
	const strAMG_url = 'https://stagingpto.streamamg.com';
	const strAMG_iframe = document.createElement('iframe');
	strAMG_iframe.src = strAMG_url + '?' + window.location.href.split('?')[1];
	strAMG_iframe.allow = 'fullscreen';
	ptoEventContainer.appendChild(strAMG_iframe);
}*/


let localDates = document.getElementsByClassName("local-date")
if(localDates)
{
	let new_date = null
	for(let localDate of localDates)
	{
		let epoch = localDate.dataset.epoch
		let date = new moment(epoch * 1000)
		let dateStr = date.format(localDate.dataset.format)
		if(localDate.classList.contains('new-date'))
		{
			if(new_date != dateStr) localDate.innerHTML = dateStr
			new_date = dateStr
		}
		else
		{
			localDate.innerHTML = dateStr
		}
	}
}

class Modal extends Component {
	state = { isOpen: false }
	windowOffset = 0

	open = _ => {
		this.setState({ isOpen: true }, () => {
			this.windowOffset = window.scrollY
			//document.body.style.position = 'fixed'
			//document.body.style.top = `-${this.windowOffset}px`
			document.body.style.overflow = 'hidden'
			//document.documentElement.style.overflow = 'hidden'
		})
	}
	close = _ => {
		this.setState({ isOpen: false }, () => {
			document.body.style.overflow = ''
			//document.documentElement.style.overflow = ''
			//document.body.style.position = ''
			//document.body.style.top = ''
			window.scrollTo({ left: 0, top: parseInt(this.windowOffset || 0), behavior: 'auto'})
		})
	}

	closeBackground = e => {
		let container = e.target.closest(".pto-modal-container")
		if(container == e.target) this.close()
	}

	render(props, state)
	{
		if(!state.isOpen) return

		return(
			<div class='pto-modal-container' onClick={this.closeBackground}>
				<div class='pto-modal-dialog' maximize={props.maximize} noheader={props.noheader}>
					{!props.noheader && 
						<div class='pto-modal-header'>
							<div class='d-flex align-items-center'>
								<div class='pto-modal-close' onClick={this.close}><i class="fas fa-times-circle"></i></div>
								<div class='ml-2 pto-modal-title'>{props.title}</div>
							</div>
						</div>
					}
					{props.noheader && 
						<div class='pto-modal-noheader'>
							<div class='pto-modal-close' onClick={this.close}><i class="fas fa-times"></i></div>
						</div>
					}
					<div class='pto-modal-content'>
						{ props.children }
					</div>
				</div>
			</div>
		)
	}
}

class MediaViewer extends Component {

	currentIndex = -1
	mediasDom = {}
	loaded = []
	medias = []

	updateArrows = s => {
		let itemWidth = s.childNodes[0].clientWidth
		let showRight = (s.scrollLeft + s.clientWidth) < (s.scrollWidth - 2)
		let showLeft = s.scrollLeft >= 2 //itemWidth

		this.setState({ showLeft: showLeft, showRight: showRight })
	}

	viewerScroll = d => {
		this.viewerScrollDom = d
		if(!d) return
		d.addEventListener("scroll", (e) => {
			this.updateArrows(e.target)
		}, false)
	}

	left = _ => {
		if(!this.viewerScrollDom) return
		let x = 0
		for(let child of this.viewerScrollDom.childNodes)
		{
			if(this.viewerScrollDom.scrollLeft <= x + child.clientWidth)
			{
				this.viewerScrollDom.scrollTo({ left: x, behavior: 'smooth' })
				break
			}
			x += child.clientWidth
		}
	}
	right = _ => {
		if(!this.viewerScrollDom) return
		let x = 0
		for(let child of this.viewerScrollDom.childNodes)
		{
			if(this.viewerScrollDom.scrollLeft <= x)
			{
				this.viewerScrollDom.scrollTo({ left: x + child.clientWidth, behavior: 'smooth' })
				break
			}
			x += child.clientWidth
		}
	}


	updateFullArrows = s => {
		let itemWidth = s.childNodes[0].clientWidth
		let showRight = (s.scrollLeft + s.clientWidth) < (s.scrollWidth - 2)
		let showLeft = s.scrollLeft >= itemWidth

		let index = Math.floor((s.scrollLeft + s.clientWidth * 0.5) / s.clientWidth)
		if(index != this.currentIndex)// || index != indexPrev)
		{
			if(this.mediasDom[this.currentIndex])
			{
				this.mediasDom[this.currentIndex].pause()
			}
			this.currentIndex = index
		}
		this.setState({ showFullLeft: showLeft, showFullRight: showRight, currentIndex: index })
	}

	viewerFullScroll = d => {
		this.viewerFullScrollDom = d
		if(!d) return
		d.addEventListener("scroll", (e) => {
			this.updateFullArrows(e.target)
		}, false)
	}

	fullLeft = _ => {
		if(!this.viewerFullScrollDom) return
		let x = 0
		for(let child of this.viewerFullScrollDom.childNodes)
		{
			if(this.viewerFullScrollDom.scrollLeft <= x + child.clientWidth)
			{
				this.viewerFullScrollDom.scrollTo({ left: x, behavior: 'auto' })
				break
			}
			x += child.clientWidth
		}
	}
	fullRight = _ => {
		if(!this.viewerFullScrollDom) return
		let x = 0
		for(let child of this.viewerFullScrollDom.childNodes)
		{
			if(this.viewerFullScrollDom.scrollLeft <= x)
			{
				this.viewerFullScrollDom.scrollTo({ left: x + child.clientWidth, behavior: 'auto' })
				break
			}
			x += child.clientWidth
		}
	}

	video = v => {
		if(!v) return
		let index = parseInt(v.dataset.index)
		this.mediasDom[index] = v

		let video = v
		/*video.onabort = function() { console.log("onabort"); };
		video.oncanplaythrough = function() { console.log("oncanplaythrough", this.duration); };
		video.ondurationchange = function() { console.log("ondurationchange"); };
		video.onemptied = function() { console.log("onemptied"); };
		video.onended = function() { console.log("onended"); };
		video.onerror = function() { console.log("onerror"); };
		video.onloadeddata = function() { console.log("onloadeddata"); };
		video.onloadedmetadata = function() { console.log("onloadedmetadata"); };
		video.onloadstart = function() { console.log("onloadstart"); };
		video.onpause = function() { console.log("onpause"); };
		video.onplay = function() { console.log("onplay"); };
		video.onplaying = function() { console.log("onplaying"); };
		video.onratechange = function() { console.log("onratechange"); };
		video.onreadystatechange = function() { console.log("onreadystatechange"); };
		video.onseeked = function() { console.log("onseeked"); };
		video.onseeking = function() { console.log("onseeking"); };
		video.onsuspend = function() { console.log("onsuspend"); };
		video.ontimeupdate = function() { console.log("ontimeupdate"); };
		video.onvolumechange = function() { console.log("onvolumechange"); };
		*/


		video.addEventListener('ended', () => {
			video.currentTime = 0.01
			video.play()
		});

		/*v.addEventListener("canplaythrough", e => { 
			console.log("canplaythrough")
		})

		v.addEventListener("durationchange", e => { 
			console.log("durationchange")
		})

		v.addEventListener("loadeddata", e => { 
			console.log("loadeddata")
		})

		v.addEventListener("loadstart", e => { 
			console.log("loadstart")
		})

		v.addEventListener("waiting", e => { 
			console.log("waiting")
		})

		v.addEventListener("suspend", e => { 
			console.log("suspend")
		})

		v.addEventListener("stalled", e => { 
			console.log("stalled")
		})

		v.addEventListener("progress", e => { 
			console.log("progress")
		})

		v.addEventListener("playing", e => { 
			console.log("playing")
		})*/

		v.addEventListener("pause", e => { 
			let media = e.target.closest(".media")
			media.dataset.playing = 0
		})
		v.addEventListener("play", e => { 
			let media = e.target.closest(".media")
			media.dataset.playing = 1
		})
		v.addEventListener("timeupdate", e => { 
			if(e.target.paused) return
			//console.log("timeupdate", e.target.currentTime)
			let media = e.target.closest(".media")
			this.loaded[media.dataset.index] = 1
			this.setState({})
		})
	}

	play = e => {
		let media = e.target.closest(".media")
		let video = this.mediasDom[media.dataset.index]
		if(!video) return
		//let video = e.target.closest("video")
		if(video.paused) {
			video.play()
		}
		else {
			video.pause()
		}
	}



	onKeyDown = e => {
		
		if(e.code == "Escape")
		{
			this.close()	
		}
		else if(e.code == "ArrowRight")
		{
			this.fullRight()
		}
		else if(e.code == "ArrowLeft")
		{
			this.fullLeft()
		}
		else if(e.code == "Space")
		{
			let s = this.viewerFullScrollDom
			let index = Math.floor((s.scrollLeft + s.clientWidth * 0.5) / s.clientWidth)
			if(this.mediasDom[index])
			{
				if(this.mediasDom[index].paused) this.mediasDom[index].play()
				else this.mediasDom[index].pause()
			}
		}

		e.preventDefault()
	}


	goFull = async e => {
		let media = e.target.closest(".media")
		let index = media.dataset.index

		let remove = e.target.closest(".media-remove")
		if(remove)
		{
			if(this.props.onDelete) this.props.onDelete(media.dataset.id)
			e.preventDefault()
			return
		}

		document.addEventListener('keydown', this.onKeyDown)
		this.setState({ showFullscreen: true }, () => {
			this.windowOffset = window.scrollY
			document.body.style.overflow = 'hidden'
			document.documentElement.style.overflow = 'hidden'
			//document.body.style.position = 'fixed'
			//document.body.style.top = `-${this.windowOffset}px`

			this.viewerFullScrollDom.scrollLeft = index * this.viewerFullScrollDom.clientWidth
			this.updateFullArrows(this.viewerFullScrollDom)
		})
	}


	close = e => {
		for(let v in this.mediasDom)
		{
			if(this.mediasDom[v]) {
				this.mediasDom[v].pause()
				this.mediasDom[v].currentTime = 0
				this.loaded[v] = 0
			}
		}
		document.removeEventListener('keydown', this.onKeyDown)
		this.setState({ showFullscreen: false }, () => {
			document.body.style.overflow = ''
			document.documentElement.style.overflow = ''
			//document.body.style.position = ''
			//document.body.style.top = ''
			window.scrollTo({ left: 0, top: parseInt(this.windowOffset || 0), behavior: 'auto'})
		})
	} 

	onFullTouchStart = e => {
		this.wantFullscreen = true 
	}

	onFullTouchMove = e => {
		this.wantFullscreen = false
	}

	onFullTouchEnd = e => {
		if(this.wantFullscreen)
		{
			this.goFull(e)
			e.preventDefault()
		}
	}




	onTouchStart = e => {
		this.wantToPlay = true 
	}

	onTouchMove = e => {
		this.wantToPlay = false
	}

	onTouchEnd = e => {
		if(this.wantToPlay)
		{
			this.play(e)
			e.preventDefault()
		}
	}

	componentWillMount()
	{
		this.loaded = []
		this.medias = this.props.medias
		if(this.medias) for(let m of this.medias) this.loaded.push(0)
	}

	componentWillUpdate(nextProps, nextState)
	{
		if(!this.state.showFullscreen)
		{
			let clear = false
			if(this.medias.length != nextProps.medias.length) clear = true
			else
			{
				for(let i = 0; i < this.medias.length; i++)
				{
					if(this.medias[i].id != nextProps.medias[i].id) clear = true
				}
			}
			this.medias = nextProps.medias
			if(clear && nextProps.medias) for(let m of nextProps.medias) this.loaded.push(0)
		}
	}

	

	componentDidUpdate(prevProps, prevState, snapshot)
	{
		/*if(prevProps.medias == undefined && this.props.medias == undefined) return
		console.log('componentDidUpdate', prevProps, this.props)
		if(prevProps.medias.length != this.props.medias.length)
		{
			if(this.props.upload) {
				this.viewerScrollDom.scrollTo({ left: this.viewerScrollDom.clientWidth, behavior: 'auto' })
				//this.viewerScrollDom.scrollLeft = this.viewerScrollDom.clientWidth
			}
		}*/
		//console.log("HERExxxxx!!!", this.viewerScrollDom.clientWidth, this.viewerScrollDom.scrollWidth)
	}

	render(props, state)
	{
		if((!this.medias || this.medias.length == 0)) return
		useLayoutEffect(() => {
			if(this.viewerScrollDom) {
				this.updateArrows(this.viewerScrollDom)		
			}
		}, []);

		return (
			<div class='media-viewer'>
				<div class='media-padding'>
					<div class='media-viewer-scroll' ref={this.viewerScroll}>
						{this.medias.map((media, index) => {
							return(
								<div class='media-container' key={media.id}>
									<div class='media' 
										data-id={media.id}
										type={media.type} 
										data-loaded="0" 
										data-playing="0" 
										data-index={index}
										onClick={this.goFull}
										onTouchStart={this.onFullTouchStart}
										onTouchMove={this.onFullTouchMove}
										onTouchEnd={this.onFullTouchEnd} 
										style={`height: 150px; width: ${ 150 * media.width / media.height}px;`}
									>
									<img src={media.thumb}/>
									{props.upload && <div class='media-remove'><i class="fas fa-times-circle"></i></div>} 
								</div>
							</div>)
						})}
					</div>
				</div>
				<div class='move-left' show={state.showLeft} onClick={this.left}><i class="fas fa-chevron-left"></i></div>
				<div class='move-right' show={state.showRight} onClick={this.right}><i class="fas fa-chevron-right"></i></div>
				<div class='dot-container d-none'>
					{this.medias.map((media, index) => {
						return(
							<div class='dot' selected={state.currentIndex == index}>
							</div>
						)
					})}
				</div>
				<div class='media-viewer-fullscreen' show={state.showFullscreen}>

					<div class='media-viewer-scroll' ref={this.viewerFullScroll}>
						{props._upload && 
							<div class='media-container'>
								<div class='d-flex justify-content-center align-items-center h-100'>
									<button class='btn btn-sm btn-primary' onClick={props.onUpload}>Upload Image/Video</button>
								</div>
							</div>
						}
						{this.medias.map((media, index) => {
							return(
								<div class='media-container'>
									<div class='media' 
										type={media.type} 
										data-loaded={ this.loaded[index] } 
										data-playing="0" 
										data-index={index}
										onClick={this.play}
										onTouchStart={this.onTouchStart}
										onTouchMove={this.onTouchMove}
										onTouchEnd={this.onTouchEnd} 
										style={`background-image: url(${media.type == 'image' ? media.src : media.preview});`}
									>
									
									<div class='title'>
										Match {media.match_id+1}
										{ props.races && props.races[media.match_id] && props.races[media.match_id].actors.map(athlete => {
											return (
												<div class='actor' team={athlete.team}><div class='flag'></div> {athlete.full_name}</div>
											)
										})}
									</div>
									
									{ media.type == 'video' && 
										<Fragment>
											<video 
												data-index={index} 
												ref={this.video} 
												playsinline 
												_loop
												preload="auto"
												src={media.src}
											/>
										</Fragment>
									}
								</div>
							</div>)
						})}
					</div>
					<div class='move-left' show={state.showFullLeft} onClick={this.fullLeft}><i class="fas fa-chevron-left"></i></div>
					<div class='move-right' show={state.showFullRight} onClick={this.fullRight}><i class="fas fa-chevron-right"></i></div>
					<div class='mvf-close' onClick={this.close}><i class="fas fa-times"></i></div>

				</div>
			</div>
		)
	}
}

class ChartGraph extends Component {

	datasets = []
	canvasRef = canvas => {
		this.canvas = canvas
	}
	componentDidMount() {
		let labels = []
		for(let i = 1; i <= 100; i++) labels.push(i + " km")

		const data = {
		  labels: labels,
		  datasets: this.datasets
		};

		const config = {
			type: 'line',
			data: data,
			options: {
				animation: { duration: 0 },
				responsive: true,
				maintainAspectRatio: false,

				interaction: {
					mode: 'index',
					intersect: false,
				},				
				scales: {
					y: {
						ticks: {
							callback: function(value, index, values) {
								return value == 0 ? "Leader" : secondsToTime(value, true)
							}
						}
					}
				},
				plugins: {
					legend: {
						display: false
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								return context.dataset.label + ": " + (context.raw == 0 ? "Leader" : secondsToTime(context.raw, true))
							}
						}
                	}
				}
			}
		}

		this.chart = new Chart(this.canvas, config)		

		if(this.chart) this.chart.resize()
	}

	updateDataSets(datasets)
	{
		this.datasets = datasets
		if(!this.chart) return
		this.chart.data.datasets = datasets
		this.char.update()
	}

	shouldComponentUpdate() { return false; }
	/*componentDidUpdate(prevProps, prevState, snapshot) 
	{
		console.log(this.chart)
		if(this.chart) this.chart.resize()
	}*/

	render(props, state)
	{
		return (
			<div class='my-3' style='position: relative; width: 100%; height: 200px;'>
				<canvas ref={this.canvasRef}/>
			</div>
		)
	}
}

class CollinsCupLive_preview extends Component {
	render(props, state)
	{
		return(<div>
			<div class='collins-cup-live'>
				<div class='live-stats-title'>LIVE STATS</div>
				<div class='hero'>
					<img src='https://content.protriathletes.org/media/content/test/91b5f8d0-0f46-45cb-a0b7-5aeaf24dceb9.png'/>
				</div>
				<div class='w-100 p-3 text-center f110'>
					<div class='p-2'>Come back here on August 28th to follow the race live!</div>
					<div>The data feed will incorporate real-time positional data, split times, photos and videos.</div> 
				</div>
			</div>
		</div>
		)
	}
}

class CollinsCupLive extends Component {

	state = { loading: true, modalRaceIndex: 0 }

	event_id = 101915
	race_id = 97948
	refresh_interval = 20
	race_time_secs = null
	version = 0

	modalRef = createRef()

	races = []

	athletes_name_map = {
		"Jackie Hering": 976,
		"Daniel Baekkegard": 4495,
		"Max Neumann": 4489,
		"Skye Moench": 3155,
		"Lionel Sanders": 2190,
		"Sarah Crowley": 2506,
		"Ben Kanute": 3742,
		"Samuel Appleton": 2365,
		"Emma Pallant": 3145,
		"Anne Haug": 3853,
		"Timothy O'Donnell": 1044,
		"Teresa Adam": 4351,
		"Heather Jackson": 2143,
		"Magnus Elbaek Ditlev": 5150,
		"Tyler Butterfield": 219,
		"Collin Chartier": 5236, "Rudy Von Berg": 3300,
		"Gustav Iden": 3335,
		"Joe Skipper": 1859,
		"Daniela Ryf": 2305,
		"Alissa Doehla": 4057,
		"Sam Long": 3301,
		"Matt Hanson": 2088,
		"Meredith Kessler": 546,
		"Paula Findlay": 2428,
		"Sophie Watts": 5836,
		"Braden Currie": 2500,
		"Kyle Smith": 4332,
		"Lucy Charles-Barclay": 3160,
		"Jan Frodeno": 2179,
		"Chris Leiferman": 2566,
		"Patrick Lange": 2184,
		"Holly Lawrence": 2610,
		"Kat Matthews": 5036,
		"Jeanni Metzler": 2455,
		"Amelia Watkinson": 2442,
		"Ellie Salthouse": 3115,
	}

	teams = [
		{ name: 'Team Europe', id: 26, label: 'EUR', flag: 'https://content.protriathletes.org/media/content/test/9c3b06e7-9b51-44a8-8b5b-ad824ad3fc6f.svg', _flag: 'https://content.protriathletes.org/media/content/test/64c64c11-b320-4b49-813b-6dd9dc62eb14.jpg', points: 0 },
		{ name: 'Team Internationals', id: 28, label: 'INT', flag: 'https://content.protriathletes.org/media/content/test/c2d17dce-3e6e-4a35-8074-1665d79b673f.svg', _flag: 'https://content.protriathletes.org/media/content/test/d8b4ffd9-13f2-412e-8592-217999365d63.jpg', points: 0 },
		{ name: 'Team US', label: 'USA', id: 27, flag: 'https://content.protriathletes.org/media/content/test/51fe2cbf-3d7a-4a37-80cf-4d1263619c59.svg', _flag: 'https://content.protriathletes.org/media/content/test/3eff6eea-6651-485b-9637-f0c218c672c8.jpg', points: 0 },
	]

	componentWillMount()
	{
		this.init()
		document.addEventListener("visibilitychange", this.handleVisibilityChange, false)
	}
	componentWillUnmount()
	{
		clearTimeout(this.timer)
		clearInterval(this.advanceTimeTimer)
		document.removeEventListener("visibilitychange", this.handleVisibilityChange, false)
	}

	handleVisibilityChange = e => {
		this.refresh_interval = document.hidden ? 60 : 20
		if(!document.hidden)
		{
			clearTimeout(this.timer)
			this.tick()
		}
		//console.log("visibility", document.hidden)
	}

	distanceToHuman(d)
	{
		//window.navigator.language == "en-US"
		if(d < 1000) return Math.floor(d) + " m"
		return (d / 1000).toFixed(1) + " km"
	}

	rankingsTeamsUrl(secs)
	{
		let t = new Date(secs * 1000)
		//console.log(t, secs)
		let month = t.getUTCMonth() + 1
		let day = t.getUTCDate()
		let hour = t.getUTCHours()
		let minute = t.getUTCMinutes()
		let second = t.getUTCSeconds()
		//console.log(month, day, hour, minute)
		if(month < 10) month = "0" + month
		if(day < 10) day = "0" + day
		if(hour < 10) hour = "0" + hour
		if(minute < 10) minute = "0" + minute
		if(second < 10) second = "0" + second

		return `https://collins.georacing.io/datas/${this.event_id}/${this.race_id}/rankings_teams_${t.getUTCFullYear()}${month}${day}${hour}${minute}${second}.json`
  
		return `https://data-live.georacing.com/datas/${this.event_id}/${this.race_id}/rankings_teams_${t.getUTCFullYear()}${month}${day}${hour}${minute}${second}.json`

		//return `https://player.georacing.com/raw_datas/${this.event_id}/${this.race_id}/positions/${t.getUTCFullYear()}_${month}_${day}__${hour}_${minute}__positions.json`
	}

	init = async () => {

		const me_fetch = await fetch("/api/user/me")
		const me = await me_fetch.json()
		this.me = me
		this.canUpload = me && me.session && (me.session.tag == "pto" || me.session.tag == "admin")

		this.latest = null
		this.previous = null
		this.base = null

		{
			const start_fetch = await fetch(`https://content.protriathletes.org/media/content/live/cc/start`)
			const start = await start_fetch.json()
			this.base = start
			/*for(let s of start.start)
			{
				s.value = JSON.parse(s.value) 
				if(s.key == 'all') this.base = s.value			
				else if(s.key == 'latest') {
					this.latest = s.value
					this.previous = s.value
				}	
			}*/
			//all.all.value = JSON.parse(all.all.value)
			console.log(start)
		}

		if(this.base)
		{
			this.race = this.base.race
			this.races = this.base.races
			this.course = this.base.course

			let now = new Date().getTime()
			this.actors = []
			for(let r of this.races)
			{
				r.current_time = parseInt((now - new Date(r.start_time).getTime()) / 1000)  
				for(let a of r.actors)
				{
					a.athlete = this.base.athletes.find(x => x.id == a.athlete_id)
					this.actors.push(a)
				}
			}
			this.setState({ medias: this.base.medias })
		}
		if(this.latest)
		{
			this.race_time_secs = parseInt(new Date(this.latest.time).getTime() / 1000)
			console.log("latest", this.latest)
			this.updateTeamPoints(this.latest)
		}

		/*let athlete_ids = []
		for(let aid in this.athletes_name_map) athlete_ids.push(this.athletes_name_map[aid])
		const athletes_fetch = await fetch(`/collins-cup/athletes/${athlete_ids.join(",")}`)
		this.athletes = await athletes_fetch.json()

		const all_fetch = await fetch(`https://player.georacing.com/datas/${this.event_id}/${this.race_id}/all.json`)
		const all = await all_fetch.json()
		//console.log(all)
		this.actors = all.actors
		this.race = all.race
		this.course = all.courses[0]

		const rankings_teams_fetch = await fetch(`https://data-live.georacing.com/datas/${this.event_id}/${this.race_id}/rankings_teams.json`)
		const rankings_teams = await rankings_teams_fetch.json()
		//console.log(rankings_teams)
		this.race_time_secs = parseInt(new Date(rankings_teams.time).getTime() / 1000) - 10

		for(let a of this.actors)
		{
			a.athlete = this.athletes.athletes.find(x => x.id == this.athletes_name_map[a.full_name])
			//if(a.athlete) a.athlete.pic = JSON.parse(a.athlete.pic)
			a.rank = 0
			a.loosingtime = 0
			a.splits = []
			let race = this.races.find(x => x.categories == a.categories)
			if(!race)
			{
				race = { categories: a.categories, categories_name: a.categories_name, members: a.members, start_time: new Date(a.start_time), current_time: 1000000, actors: [] }
				this.races.push(race)
			}
			race.actors.push(a)
		}*/

		/*for(let split of all.splittimes)
		{
			for(let st of split.splittimes)
			{
				let actor = this.actors.find(x => x.id == st.actor_id)
				if(!actor) continue
				if(!actor.splits) actor.splits = []
				actor.splits.push({ id: split.id, role: split.role, name: split.name, gap: st.gap, rank: st.rank, time: st.rr })
			}
		}*/


		this.races.sort((a, b) => a.start_time - b.start_time)
		console.log(this.races)

		let start_secs = (new Date(this.race.start_time).getTime() / 1000)
		let end_secs = (new Date(this.race.end_time).getTime() / 1000)

		//this.race_time_secs = start_secs
		console.log(start_secs, end_secs)

		await this.tick()
		//this.timer = setTimeout(this.tick, 1000)

		this.advanceTimeTimer = setInterval(this.advanceTime, 1000)

		this.setState({ loading: false, start_secs: start_secs, end_secs: end_secs, time_secs: start_secs })
	}

	advanceTime = () => {
		let now = new Date().getTime()
		for(let race of this.races)
		{
			let start_time = new Date(race.start_time) 
			race.current_time = parseInt((now - start_time.getTime()) / 1000)
		}
		this.race_time_secs += 1
		this.setState({})
	}

	updateTeamPoints = rankings_teams => {
		for(let tr of rankings_teams.rankings_teams)
		{
			let t = this.teams.find(x => x.id == tr.id)
			if(!t) continue
			t.points = tr.points
			t.rank = tr.rank
		}

	}

	tick = async () => {

		let race_time = this.race_time_secs
		//let rankings_teams_url = this.rankingsTeamsUrl(race_time)
		
		let next_update = 60
		try {

			const rankings_teams_fetch = await fetch(`https://content.protriathletes.org/media/content/live/cc/latest`)
			const rankings_teams = await rankings_teams_fetch.json()

			if(rankings_teams && rankings_teams.version)
			{
				if(this.version != rankings_teams.version)
				{
					if(this.version == 0) this.version = rankings_teams.version
					else location.reload()
				}
			}

			next_update = rankings_teams.next_update

			let race_time_secs = parseInt(new Date(rankings_teams.time).getTime() / 1000)
			if(this.race_time_secs === null) {
				this.race_time_secs = race_time_secs
			}
			race_time = this.race_time_secs

			//const rankings_teams_fetch = await fetch(rankings_teams_url)
			//const rankings_teams = await rankings_teams_fetch.json()

			this.updateTeamPoints(rankings_teams)

			for(let g of rankings_teams.groups)
			{
				let race = this.races.find(x => x.id == g.category_id)
				let start_time = new Date(race.start_time) 
				/*if(race.current_time == 1000000)
				{
					race.current_time = parseInt((new Date(rankings_teams.time).getTime() - race.start_time.getTime()) / 1000)
				}*/
				//race.current_time = parseInt(race_time - (start_time.getTime() / 1000))

				for(let r of g.rankings)
				{
					let actor = race.actors.find(x => x.id == r.actor_id)
					if(!actor) continue
					actor.loosingtime = r.loosingtime
					actor.splits = r.splits
					actor.speed = r.speed
					actor.heartrate = r.heartrate
					actor.hr = r.hr
					actor.dist_splits = r.dist_splits
					actor.rank = r.rank
					actor.gap = r.gap
					actor.gap_distance = r.gap_distance
					actor.distance = r.distance
					actor.ftime = r.ftime
					actor.results = r.results
					for(let co_index = this.course.length - 1; co_index >= 0; co_index--)
					{
						let co = this.course[co_index]
						if(actor.distance > co.distance) break
						actor.distanceTo = Math.floor(co.distance - actor.distance)
						actor.distanceToLabel = co.name
					}
					actor.total_points = r.total_points
				}
			}

			for(let r of this.races)
			{
				r.actors.sort((a, b) => a.rank - b.rank)
				let finished = true
				for(let a of r.actors)
				{
					if(!a.ftime) finished = false
					a.gap_points = [null,null]
				}
				r.finished = finished
			}

			/*const split_times_fetch = await fetch(`https://player.georacing.com/datas/${this.event_id}/${this.race_id}/splittimes.json`)
			const split_times = await split_times_fetch.json()

			for(let split of split_times)
			{
				for(let st of split.splittimes)
				{
					let actor = this.actors.find(x => x.id == st.actor_id)
					if(!actor) continue
					actor.splits.push({ id: split.id, role: split.role, name: split.name, gap: st.gap, rank: st.rank, time: parseInt(new Date(st.time).getTime() / 1000) })
				}
			}*/

			for(let r of this.races)
			{
				r.actors.sort((a, b) => a.rank - b.rank)
				let index = 0
				for(let actor of r.actors)
				{
					let progress = 'Waiting to start'
					if(actor.gap !== undefined)
					{
						if(true || actor.splits.length == 6)
						{
							let finishTime = actor.results[5] //actor.splits[5].time - actor.splits[0].time
							if(index == 0)
							{
								progress = secondsToTime(Math.floor(finishTime))
							}
							else
							{
								progress = secondsToTime(Math.floor(finishTime)) + " (+" + secondsToTime(Math.floor(actor.gap)) + ")"
							}
						}
						else
						{
							if(index == 0)
							{
								if(actor.distanceToLabel) progress = this.distanceToHuman(actor.distanceTo) + " to " + actor.distanceToLabel
							}
							else
							{
								progress = (index != 0 ? "+" : "") + secondsToTime(Math.floor(actor.gap))
								if(index)
								{
									progress += " / " + this.distanceToHuman(actor.gap_distance)
								}
							}
						}
					}
			
					let speed = null, sub_speed = null
					if(actor.splits)
					{
						if(actor.splits.length == 1)
						{
							speed = secondsToTime(Math.floor(100 / actor.speed)) + " / 100m"
							sub_speed = secondsToTime(Math.floor(100 / (actor.speed * 1.09361))) + " / 100y"
						}
						else if(actor.splits.length == 3)
						{
							speed = (2.237 * actor.speed).toFixed(1) + " mph"
							sub_speed = (3.6 * actor.speed).toFixed(1) + " kmh"
						}
						else if(actor.splits.length == 5)
						{
							speed = secondsToTime(Math.floor(60 * 26.8824 / actor.speed)) + " /mi"
							sub_speed = secondsToTime(Math.floor(1000 / actor.speed)) + " /km"
						}
					}
					actor.speed_text = speed
					actor.sub_speed_text = sub_speed

					actor.progress = progress
					//actor.progress = secondsToTime(actor.results[5])

					index++
				}

				if(r.actors.length == 3)
				{
					if(r.actors[1].gap > 6*60) r.actors[0].gap_points[0] = "+1.5pts (+6:00 to 2nd)"
					else if(r.actors[1].gap > 4*60) r.actors[0].gap_points[0] = "+1pt (+4:00 to 2nd)"
					else if(r.actors[1].gap > 2*60) r.actors[0].gap_points[0] = "+0.5pts (+2:00 to 2nd)"

					if(r.actors[2].gap > 6*60) r.actors[0].gap_points[1] = "+1.5pts (+6:00 to 3rd)"
					else if(r.actors[2].gap > 4*60) r.actors[0].gap_points[1] = "+1pt (+4:00 to 3rd)"
					else if(r.actors[2].gap > 2*60) r.actors[0].gap_points[1] = "+0.5pts (+2:00 to 3rd)"

					let gap = r.actors[2].gap - r.actors[1].gap
					if(gap > 6*60) r.actors[1].gap_points[0] = "+1.5pts (+6:00 to 3rd)"
					else if(gap > 4*60) r.actors[1].gap_points[0] = "+1pt (+4:00 to 3rd)"
					else if(gap > 2*60) r.actors[1].gap_points[0] = "+0.5pts (+2:00 to 3rd)"
				}

				for(let i = 0; i < 3; i++)
				{
					let fastest_actor = null, fastest_time = 0
					
					for(let actor of r.actors)
					{
						if(!actor.splits) continue
						if(!actor.splits[1 + i * 2]) continue
						let time = actor.splits[1 + i * 2].time - actor.splits[i * 2].time
						if(fastest_actor == null || time < fastest_time)
						{
							if(fastest_actor) fastest_actor.splits[1 + i * 2].pos = 0
							actor.splits[1 + i * 2].pos = 1
							fastest_actor = actor
							fastest_time = time
						}
					}
				}

				let raceIcon = 'far fa-clock'
				let splits = r.actors[0].splits
				if(splits)
				{
					if(splits.length == 1 || splits.length == 2)
					{
						raceIcon = "fas fa-swimmer"
					}
					else if(splits.length == 3 || splits.length == 4)
					{
						raceIcon = "fas fa-biking"
					}
					else if(splits.length == 5)
					{
						raceIcon = "fas fa-running"
					}
					else if(splits.length == 6)
					{
						raceIcon = "fas fa-flag-checkered"
					}
				}
				r.icon = raceIcon
			}
			this.setState({ medias: rankings_teams.medias })
		} catch(e) {
			console.log(e)			
		}

		clearTimeout(this.timer)
		this.timer = setTimeout(this.tick, Math.max(this.refresh_interval, next_update) * 1000)
	}

	changeTime = async e => {
		let value = parseInt(e.target.value)
		let time = new Date(value * 1000)
		this.race_time_secs = value
		this.setState({ time_secs: value })

		let rankings_teams_url = this.rankingsTeamsUrl(value)
		const rankings_teams_fetch = await fetch(rankings_teams_url)
		const rankings_teams = await rankings_teams_fetch.json()

		this.tick()

		/*let positions_url = this.positionsUrlFromTime(time)

		console.log(positions_url)
		const positions_fetch = await fetch(positions_url)
		const positions = await positions_fetch.json()
		positions.sort((a, b) => a.time.localeCompare(b.time))
		console.log(positions)
			*/
	}

	toggleRace = e => {
		let showMore = e.target.closest(".show-more")
		if(!showMore) return
		this.races[showMore.dataset.index].expand = !this.races[showMore.dataset.index].expand
		this.setState({})
	}

	showDetails = async e => {
		let race = e.target.closest('.race')
		if(!race) return
		let raceIndex = parseInt(race.dataset.raceindex)
		this.setState({ modalRaceMedias: [], modalRaceIndex: raceIndex }, () => {
			this.modalRef.current.open()
		})

		const medias_fetch = await fetch(`/api/live/cc/match/${raceIndex}`)
		const medias = await medias_fetch.json()
		this.setState({ modalRaceMedias: medias.medias })
	}

	imageToBlob(image, mime)
	{
		let byteString = atob(image.split(',')[1]);
		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
		let bb = new Blob([ab], { type: mime });
		return bb
	}

	uploadFile = async (name, size, type, lastModified) => {
		let f = await fetch("/api/media/createuploadurl", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: name, size: size, type: type, lastModified: parseInt(lastModified / 1000), folder: 'live' })
		})

		return await f.json()
	}

	upload = e => {
		let raceIndex = this.state.modalRaceIndex
		console.log(raceIndex)
		let input = document.createElement('input')
		input.type = "file"
		//input.multiple = "1"
		input.accept = "image/*, video/*"
		input.addEventListener("change", async (e) => {
			let files = e.target.files
			for(let file of files)
			{
				console.log(file)
				if(file.type.startsWith("image"))
				{
					let reader = new FileReader()
					reader.onload = (e) => {
						let _image = new Image();
						_image.onload = async (e) => {
							let image = e.target
						
							let canvas = document.createElement('canvas')
							let width = image.naturalWidth, height = image.naturalHeight;
							if(height > 1080)
							{
								width *= 1080 / height
								height = 1080
							}

							let maxWidth = width, maxHeight = height

							canvas.width = width;
							canvas.height = height;


							console.log(image.naturalWidth, image.naturalHeight, width, height)

							let context = canvas.getContext('2d')
							context.imageSmoothingEnabled = true
							context.imageSmoothingQuality = "high"

							context.drawImage(image, 0, 0, width, height)

							let mime = "image/jpeg"
							let blobUrl = canvas.toDataURL(mime)
							let blob = this.imageToBlob(blobUrl, mime)

							let putInfo = await this.uploadFile("upload_match", blob.size, mime, new Date().getTime())
							let put = await fetch(putInfo.put, {
								method: "put",
								headers: { 
									'Content-Type': putInfo.type, 
									'Cache-Control': putInfo.cc 
								},
								body: blob
							})


							width = image.naturalWidth
							height = image.naturalHeight
							if(height > 300)
							{
								width *= 300 / height
								height = 300
							}
							canvas.width = width;
							canvas.height = height;

							context.drawImage(image, 0, 0, width, height)
							blobUrl = canvas.toDataURL(mime)
							blob = this.imageToBlob(blobUrl, mime)

							let putInfoThumb = await this.uploadFile("upload_match", blob.size, mime, new Date().getTime())
							await fetch(putInfoThumb.put, {
								method: "put",
								headers: { 
									'Content-Type': putInfoThumb.type, 
									'Cache-Control': putInfoThumb.cc 
								},
								body: blob
							})

							let addmedia_fetch = await fetch(`/api/live/cc/match/${raceIndex}/addmedia`, {
								method: "post",
								headers: { 'Content-Type': 'application/json' },
								body: JSON.stringify({ type: "image", src: putInfo.cdn, preview: null, thumb: putInfoThumb.cdn, width: maxWidth, height: maxHeight })
							})
							let addmedia = await addmedia_fetch.json()
							console.log(addmedia)
							this.setState({ modalRaceMedias: addmedia.medias })
						}
						_image.src = e.target.result
					}
					reader.readAsDataURL(file)
				}
				else if(file.type.startsWith("video"))
				{
					let fileURL = URL.createObjectURL(file)

					let _video = document.createElement("video");
					_video.crossOrigin = "anonymous"
					_video.preload = 'auto'
					_video.autoload = true
					_video.muted = true
					_video.playsInline = true
					_video.addEventListener('canplay', async (e) => {
						let video = e.target
						console.log('canplay')

						let videoSrc = null, videoPreview = null, videoThumb = null
						{
							let width = video.videoWidth, height = video.videoHeight;
							if(height > 1080)
							{
								width *= 1080 / height
								height = 1080
							}

							let canvas = document.createElement('canvas')
							canvas.width = width;
							canvas.height = height;

							let context = canvas.getContext('2d')
							context.imageSmoothingEnabled = true
							context.imageSmoothingQuality = "high"

							context.drawImage(video, 0, 0, width, height)

							let mime = "image/jpeg"
							let blobUrl = canvas.toDataURL(mime)
							let blob = this.imageToBlob(blobUrl, mime)

							let putInfo = await this.uploadFile("upload_match", blob.size, mime, new Date().getTime())
							console.log(putInfo)
							let put = await fetch(putInfo.put, {
								method: "put",
								headers: { 
									'Content-Type': putInfo.type, 
									'Cache-Control': putInfo.cc 
								},
								body: blob
							})
							videoPreview = putInfo.cdn

							width = video.videoWidth
							height = video.videoHeight
							if(height > 300)
							{
								width *= 300 / height
								height = 300
							}
							canvas.width = width;
							canvas.height = height;

							context.drawImage(video, 0, 0, width, height)
							blobUrl = canvas.toDataURL(mime)
							blob = this.imageToBlob(blobUrl, mime)

							let putInfoThumb = await this.uploadFile("upload_match", blob.size, mime, new Date().getTime())
							await fetch(putInfoThumb.put, {
								method: "put",
								headers: { 
									'Content-Type': putInfoThumb.type, 
									'Cache-Control': putInfoThumb.cc 
								},
								body: blob
							})
							videoThumb = putInfoThumb.cdn
						}
						{
							let putInfo = await this.uploadFile("upload_match", file.size, file.type, new Date().getTime())
							console.log(putInfo)
							let put = await fetch(putInfo.put, {
								method: "put",
								headers: { 
									'Content-Type': putInfo.type, 
									'Cache-Control': putInfo.cc 
								},
								body: file
							})
							videoSrc = putInfo.cdn
						}

						let addmedia_fetch = await fetch(`/api/live/cc/match/${raceIndex}/addmedia`, {
							method: "post",
							headers: { 'Content-Type': 'application/json' },
							body: JSON.stringify({ type: "video", src: videoSrc, preview: videoPreview, thumb: videoThumb, width: video.videoWidth, height: video.videoHeight })
						})
						let addmedia = await addmedia_fetch.json()
						this.setState({ modalRaceMedias: addmedia.medias })
					})
					_video.addEventListener('loadedmetadata', (e) => {
						let video = e.target
						console.log('loadedmetadata')
						console.log(video.videoWidth, video.videoHeight, video.duration)
					})
					_video.src = fileURL
				}
			}
		})
		input.click()
	}

	deleteMedia = async id => {
		if(confirm("Are you sure you want to delete this image/video?"))
		{
			let removemedia_fetch = await fetch(`/api/live/cc/removemedia/${id}`, {
				method: "post",
			})
			let removemedia = await removemedia_fetch.json()
			console.log(removemedia)
			//this.props.medias = this.props.medias.filter(x => x.id != media.dataset.id)
			this.setState({medias: removemedia.medias})
		}
	}

	chart = chart => {
		this.chartGraph = chart
		this.updateChart()
	}

	updateChart = _ => {
		let datasets = []
		let raceModal = this.races[this.state.modalRaceIndex]
		if(raceModal.actors.length == 3)
		{
			let color_map = { 
				"USA": "rgb(191,10,48)",
				"INTERNATIONAL": "rgb(243, 214, 62)",
				"EUROPE": "rgb(0, 51, 153)",
			}

			let data_actor = [[], [], []]
			for(let i = 0; i < 100; i++)
			{

				/*for(let j = 0; j < 3; j++)
					data_actor[j][i] = Number.NaN*/

				let min = -1
				for(let j = 0; j < 3; j++)
				{
					let actor = raceModal.actors[j]
					//console.log(actor.id, actor.dist_splits)
					if(!actor.dist_splits) continue
					//if(!actor.dist_splits[i]) continue
					
					let t = actor.dist_splits[i]
					if(min == -1 || t < min) min = t 
				}

				for(let j = 0; j < 3; j++)
				{
					let actor = raceModal.actors[j]
					if(!actor.dist_splits) {
						continue
					}

					let t = actor.dist_splits[i]

					if(!actor.dist_splits[i] || (min - t) < -30*60)
					{
						data_actor[j].push(null) //Number.NaN)
						continue
					}

					
					data_actor[j].push(min - t)
				}
			}

			let actors = raceModal.actors
			for(let i = 0; i < 3; i++)
			{
				datasets.push(
					{
						label: actors[i].full_name,
						backgroundColor: color_map[actors[i].team],
						borderColor: color_map[actors[i].team],
						pointRadius: 0,
						borderWidth: 2,
						spanGaps: false,
						data: data_actor[i],
					}
				)	
			}
		}
		if(this.chartGraph) this.chartGraph.updateDataSets(datasets)	
	}

	render(props, state)
	{
		if(state.loading) return

		let max_points = 0
		for(let t of this.teams)
		{
			if(max_points < t.points) max_points = t.points
		}

		let raceModal = this.races[state.modalRaceIndex]

		/*let max_graph = 0
		let polylines = [[], [], []]
		let polylines_colors = []
		let color_map = { 
			"USA": "rgb(191,10,48)",
			"INTERNATIONAL": "rgb(243, 214, 62)",
			"EUROPE": "rgb(0, 51, 153)",
		}
		if(raceModal.actors.length == 3)
		{
			console.log(raceModal)
			polylines_colors[0] = color_map[raceModal.actors[0].team]
			polylines_colors[1] = color_map[raceModal.actors[1].team]
			polylines_colors[2] = color_map[raceModal.actors[2].team]
			for(let i = 0; i < 100; i++)
			{
				let min = 0

				let t0 = raceModal.actors[0].dist_splits[i]
				let t1 = raceModal.actors[1].dist_splits[i]
				let t2 = raceModal.actors[2].dist_splits[i]
				let min = Math.min(t0, t1, t2)
				
				polylines[0].push([i * 3, t0 - min])
				polylines[1].push([i * 3, t1 - min])
				polylines[2].push([i * 3, t2 - min])
			
				max_graph = Math.max(max_graph, (t0 - min), (t1 - min), (t2 - min))
			}
			let scale = 150 / max_graph
			for(let i = 0; i < 100; i++)
			{
				polylines[0][i][1] *= scale
				polylines[1][i][1] *= scale
				polylines[2][i][1] *= scale
			}

		}*/



		return(<div>
			<div class='cover-image'>
				<img src='https://content.protriathletes.org/media/content/test/ab184ba3-ac24-49db-8a75-c81aad1e317c.jpg'/>
				<div class='cover-graphics'>
					<div class='cc-img'>
						<img src='https://content.protriathletes.org/media/content/test/aaccfd05-3aa0-4836-a428-8470b792b2a0.png'/>
					</div>
					<div class='live-stats-title'>LIVE STATS</div>
				</div>
			</div>

			<div class='collins-cup-live'>

				<div class='w-100 d-none'>
					<input type='range' onChange={this.changeTime} value={this.race_time_secs} min={state.start_secs} max={state.end_secs - 1}/>
				</div>

				<div class='section'>
					<div class='title'>Team Points</div>
					<div class='team-rank'>
						{ this.teams.map(team => {
							return (
								<div class='team' team={team.label}>
									<div class='image'>
										<img src={team.flag}/>
									</div>
									<div class='details'>
										<div class='bar-progress' style={'--progress: ' + (max_points == 0 ? 100 : (100*team.points / max_points)) + '%'}>
											<div class='progress-details'>
												<div class='team-name'>{team.name}</div>
												<div class='total-points'>{team.points} pts</div>
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>

				{state.medias && state.medias.length != 0 && 
					<div class='section'>
						<div class='title'>Gallery</div>
						<div class='home-media-viewer'>
							<MediaViewer medias={state.medias} races={this.races} upload={this.canUpload} onDelete={this.deleteMedia}/>
						</div>
					</div>
				}

				<div class='section'>
					<div class='title'>Matches</div>
					<div class=''>
						<span class='move-up'>Gaining Time <i class="fas fa-long-arrow-alt-up"></i></span> <span class='move-down ml-2'>Losing Time <i class="fas fa-long-arrow-alt-down"></i></span>
					</div>

					<div class='races-container'>
						{this.races.map((race, race_index) => {
							let firstActor = race.actors[0] 
							let days = parseInt(-race.current_time / (24 * 60 * 60))
							let current_time = race.current_time % (24 * 60 * 60)
							let race_time = current_time < 0 ? "Starts in " + ( days != 0 ? `${days} days & ` : "") + secondsToTime(-current_time) : secondsToTime(current_time)//firstActor.gap !== undefined ? secondsToTime(Math.floor(firstActor.gap)) : "Waiting to Start"
							//console.log(race)
							return(
								<div class='race' onClick={this.showDetails} data-raceindex={race_index}>
									<div class='head'>
										{"Match " + (race_index+1)} { race.finished ? "" : `- ${race_time}`}
										<i class={race.icon + ' text-grey'}></i> 
									</div>
									<div class='content'>
										<div class='actor-tiny header'>
											<div class='info'>
												<div class='flag'>Team</div>
												<div class='score'>Pts</div>
												<div class='name'>Name</div>
												<div class='progress'>Time</div>
											</div>
										</div>
										{race.actors.map((actor, index) => {
											//console.log(actor.splits)
											return(
												<Fragment>
													<div class='actor-tiny' team={actor.team} expand={race.expand}>
														<div class='info'>
															<div class='flag'></div>
															<div class='score'>{actor.total_points}</div>
															<div class='name'>{actor.full_name}</div>
															<div class='progress' loosingtime={actor.loosingtime}>
																{actor.progress}
															</div>
														</div>
													</div>
												</Fragment>
											)
										})}
										<div class='show-more d-none' onClick={this.toggleRace} data-index={race_index}>
											<i class={race.expand ? "fas fa-chevron-up" : "fas fa-chevron-down"}></i>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>


				<div class='section'>
					<div class='title'>Points Explainer</div>
					<div class='explainer'>
						<div class='mb-1'>For each of the 12 race matches, points are awarded as follows:</div>
						<div class='mb-2'>
							<div><b>Winner: 3 Points</b></div>
							<div><b>Second place: 2 Points</b></div>
							<div><b>Third place: 1 Point</b></div>
						</div>
						<div class='mb-1'>Bonus points are awarded to every 2-minute margin athletes beat their opponents by.</div>
						<div>
							<div><b>+2 mins diff 0.5 Points</b></div>
							<div><b>+4 mins diff 1 Point</b></div>
							<div><b>+6 mins diff 1.5 Points</b></div>
						</div>
					</div>
				</div>
			</div>
			<Modal ref={this.modalRef} title={"Match " + (state.modalRaceIndex + 1)} noheader>
				<div class='modal-content'>
					<div class='modal-title'>
						<b>{"Match " + (state.modalRaceIndex + 1)}</b>
						<i class={raceModal.icon + ' text-grey'}></i> 
					</div>
					<div class='modal-actors'>
						{raceModal.actors.map(actor => { return (
							<div class='actor' team={actor.team}>
								<div class='d-flex'>
									<div class='pic'><AthletePic pic={actor.athlete && actor.athlete.pic} /></div>
									<div class='pl-2 flex-grow-1'>
										<div class='name'><div class={'flag-icon flag-icon-' + actor.team}></div> {actor.full_name}</div>
										<div class='progress' loosingtime={actor.loosingtime}>{actor.progress || "Waiting to Start"}</div>
										<div class='points-info'>{actor.gap_points && actor.gap_points.map((points, index) => {
											return(<div class='mr-2'><small class='text-grey'>{points}</small></div>)
										})}</div>
									</div>
									{ actor.ftime && <div class='score'>{actor.total_points}<small>pts</small></div> }
									{ !actor.ftime && <div class='speed'>{actor.speed_text}<div class='sub'>{actor.sub_speed_text}</div></div> }
								</div>
							</div>
						)})}
					</div>
					{state.modalRaceMedias && state.modalRaceMedias.length != 0 && 
						<div class='modal-timesplits'>
							<div class='title'>Gallery</div>
							<MediaViewer medias={state.modalRaceMedias} races={this.races}/>
						</div>
					}
					<div class='modal-timesplits'>
						<div class='title'>Split times</div>
						<div class='timesplits'>
							<div class='athlete head'>
								<div class='name'></div>
								<div class='flag'></div>
								<div class='time'>Swim</div>
								<div class='time'>Bike</div>
								<div class='time'>Run</div>
							</div>
							{raceModal.actors.map(actor => { return (
								<div class='athlete' team={actor.team}>
									<div class='name'>
										<b>{actor.full_name}</b>
									</div>
									<div class='flag'>
									</div>
									{ actor.splits && 
										<Fragment>	
											<div class='time' pos={actor.splits[1] && actor.splits[1].pos}>
												{secondsToTime(actor.results && actor.results[0])}
											</div>
											<div class='time' pos={actor.splits[3] && actor.splits[3].pos}>
												{secondsToTime(actor.results && actor.results[2])}
											</div>
											<div class='time' pos={actor.splits[5] && actor.splits[5].pos}>
												{secondsToTime(actor.results && actor.results[4])}
											</div>
										</Fragment>
									}
								</div>
							)})}

						</div>
					</div>
					<div class='modal-timesplits'>
						<div class='title'>Time Gap</div>
						<div class='d-flex flex-column align-items-center'>
							<ChartGraph ref={this.chart}/>
							<div class='distance-splits'>
								{raceModal.actors.map(actor => { if(!actor.splits) return; return (
									<div class='athlete' team={actor.team}>
										<div class='name'>
											<b>{actor.full_name}</b>
										</div>
										<div class='flag'>
										</div>
									</div>
								)})}

							</div>
						</div>
					</div>
					<div class='modal-timesplits'>
						<div class='title'>Heart Rate <i class="fas fa-heart"></i></div>
						<div class='timesplits'>
							<div class='athlete head'>
								<div class='name'></div>
								<div class='flag'></div>
								<div class='bpm'>Current</div>
								<div class='bpm'>Last 1'</div>
								<div class='bpm'>Swim</div>
								<div class='bpm'>Bike</div>
								<div class='bpm'>Run</div>
							</div>
							{raceModal.actors.map(actor => { return (
								<Fragment>
									<div class='athlete' team={actor.team}>
										<div class='name'>
											<b>{actor.full_name}</b>
										</div>
										<div class='flag'>
										</div>
										{ actor.hr &&  
											<Fragment>
												<div class='bpm'>
													{(actor.ftime == "" && `${actor.heartrate || ''}`) || "-"}
												</div>
												<div class='bpm'>
													{actor.hr.lastfive_avg}
												</div>
												<div class='bpm'>
													{actor.hr.swim_avg}
												</div>
												<div class='bpm'>
													{actor.hr.bike_avg}
												</div>
												<div class='bpm'>
													{actor.hr.run_avg}
												</div>
											</Fragment>
										}
										
										<div class='hr-container d-none'>
											{actor.ftime == "" && 
												<div class='hr-bar current' style={`width: ${actor.heartrate / 2}%;`}>
													<span>Current</span><span>{actor.heartrate} bpm</span>
												</div>
											}
											{actor.hr && actor.hr.lastfive_avg && 
												<div class='hr-bar lastfive' style={`width: ${actor.hr.lastfive_avg / 2}%;`}>
													<span>Last 5 mins</span><span>{actor.hr.lastfive_avg} bpm</span>
												</div>
											}
											{actor.hr && actor.hr.swim_avg && 
												<div class='hr-bar swim' style={`width: ${actor.hr.swim_avg / 2}%;`}>
													<span>Swim</span><span>{actor.hr.swim_avg} bpm</span>
												</div>
											}
											{actor.hr && actor.hr.bike_avg && 
												<div class='hr-bar bike' style={`width: ${actor.hr.bike_avg / 2}%;`}>
													<span>Bike</span><span>{actor.hr.bike_avg} bpm</span>
												</div>
												}
											{actor.hr && actor.hr.run_avg && 
												<div class='hr-bar run' style={`width: ${actor.hr.run_avg / 2}%;`}>
													<span>Run</span><span>{actor.hr.run_avg} bpm</span>
												</div>
											}
										</div>
									</div>
								</Fragment>
							)})}

						</div>
					</div>
					{ this.canUpload && 
						<div class='modal-timesplits'>
							<div class='title'>Admin</div>
							<div class='d-flex justify-content-center align-items-center'>
								<button class='btn btn-sm btn-primary' onClick={this.upload}>Upload Image/Video</button>
							</div>
						</div>
					}

				</div>
			</Modal>

		</div>)
	}
}


let ccLive = document.getElementById('cc-live')
if(ccLive)
{
	Promise.resolve().then(_ => {
		return new Promise(success => {
			let scriptChartjs = document.createElement("script")
			scriptChartjs.src = "https://cdn.jsdelivr.net/npm/chart.js@3.5.1/dist/chart.min.js"
			document.getElementsByTagName("head")[0].appendChild(scriptChartjs)
			scriptChartjs.onload = success 
			scriptChartjs.onerror = () => { console.log('error loading Chartjs') } 
		})
	}).then( _ => {
		/*fetch("/api/user/me")
		.then((r) => r.json())
		.then((d) => {
			let preview = true
			if(d.session)
			{
				__session__ = d.session
				if(__session__.tag == "admin") preview = false
			}
			if(preview) render(<CollinsCupLive_preview/>, ccLive)
			else*/ render(<CollinsCupLive/>, ccLive)
		//})
	})
}




let ccCountdown = document.getElementById('cc-count-down')
function updateCCCountDown()
{
	let diff =  1630155600 - 2*3600 - 60*30 - parseInt(new Date().getTime() / 1000)
	
	if(diff < 0)
	{
		if(ccCountdown) ccCountdown.innerHTML = "" 
		return 
	}

	let days = parseInt((diff / (3600 * 24)))
	let hours = parseInt((diff / 3600) % 24)
	let minutes = parseInt((diff / 60) % 60)
	let seconds = parseInt(diff % 60)

	if(days < 10) days = "0" + days
	if(hours < 10) hours = "0" + hours
	if(minutes < 10) minutes = "0" + minutes
	if(seconds < 10) seconds = "0" + seconds

	if(ccCountdown)
	{
		ccCountdown.innerHTML = `<div class='h4 mb-2 mt-4'>Count down to the race</div>${days}<small class='ml-1 mr-2'>days</small>${hours}<small class='ml-1 mr-2'>hrs</small> ${minutes}<small class='ml-1 mr-2'>mins</small> ${seconds}<small class='ml-1 mr-2'>secs</small>`
	}
}
if(ccCountdown)
{
	updateCCCountDown()
	setInterval(() => {
		updateCCCountDown()
	}, 1000)
}








