const dropdownButtons = document.querySelectorAll('.submenu__button');

dropdownButtons.forEach(button => {
	button.addEventListener('click', () => {
		const submenu = button.nextElementSibling;

		submenu.classList.toggle('submenu__content--visible');
		button.classList.toggle('submenu__button--active');
		submenu.style.opacity = submenu.style.opacity == 0 ? '1' : '0';
	});

	document.addEventListener('click', (event) => {
		const target = event.target;
		const submenu = button.nextElementSibling;

		if (!submenu.contains(target) && !button.contains(target)) {
			submenu.classList.remove('submenu__content--visible');
			button.classList.remove('submenu__button--active');
			submenu.style.opacity = '0';
		}
	});
});
