function updateScrollX(s)
{
	let itemWidth = s.childNodes[0].clientWidth
	let showLeft = (s.scrollLeft + s.clientWidth) != s.scrollWidth
	let showRight = s.scrollLeft >= itemWidth
	let anchors = s.__anchors

	if(showRight) anchors.classList.add('show-right-anchor')
	else anchors.classList.remove('show-right-anchor')

	if(showLeft) anchors.classList.add('show-left-anchor')
	else anchors.classList.remove('show-left-anchor')
}

let scrollAnchors = document.getElementsByClassName("scroll-anchors")
if(scrollAnchors)
{
	for(let sa of scrollAnchors)
	{
		let scrollX = sa.getElementsByClassName("scroll-x")
		if(!scrollX.length) continue

		scrollX[0].__anchors = sa
		scrollX[0].addEventListener("scroll", function(e) {
			updateScrollX(this)
		})
		updateScrollX(scrollX[0])

		let goLeft = sa.getElementsByClassName("scroll-anchor-left")
		if(!goLeft.length) continue
		goLeft[0].__scrollx = scrollX[0]
		goLeft[0].addEventListener("click", function(e) {
			this.__scrollx.scrollTo({ left: this.__scrollx.scrollLeft - this.__scrollx.clientWidth, behavior: 'smooth' })
		})

		let goRight = sa.getElementsByClassName("scroll-anchor-right")
		if(!goRight.length) continue
		goRight[0].__scrollx = scrollX[0]
		goRight[0].addEventListener("click", function(e) {
			this.__scrollx.scrollTo({ left: this.__scrollx.scrollLeft + this.__scrollx.clientWidth, behavior: 'smooth' })
		})
	}
}

