let fastestFilter = document.getElementById("form-fastest")

if(fastestFilter)
{
	fastestFilter.addEventListener('submit', (e) => {
		e.preventDefault()

		var kvpairs = []
		for(let e of fastestFilter.elements) 
		{
			if(e.type == "radio" && !e.checked) continue
			if(e.value == "") continue
			kvpairs.push(encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value))
		}
		var queryString = kvpairs.join("&")
		location = location.origin + location.pathname + (queryString != "" ? "?" + queryString : "")
	})
}