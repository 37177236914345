import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime, getSeconds, AthletePic } from "../server/components/utils"
import moment from 'moment';
import Router from 'preact-router';
import { route } from 'preact-router';
import { Game_Play } from './games';
import { Game_CollinsCup, _Game_CollinsCup } from './fantasy_games/collinscup';
import { Game_TriBattle } from './fantasy_games/tri-battle';
import { Game_Olympics  } from './fantasy_games/tokyo';
import { Game_World_703_2021  } from './fantasy_games/world-703-2021';

let __session__ = null

function copyToClipboard(text) {
	const el = document.createElement('textarea')
	el.value = text
	el.setAttribute('readonly', '')
	el.style.position = 'absolute'
	el.style.left = '-9999px'
	document.body.appendChild(el)
	el.select()
	document.execCommand('copy')
	document.body.removeChild(el)
}


class Nav extends Component {
	render(props, state)
	{
		return (
			<div class='fantasy-nav'>
				<div class='fantasy-title'><a href='/fantasy'>Fantasy Game</a></div>
				<div class='menu'>
					<a href='/fantasy' selected={props.tab == "predictor"}>Games</a>
					<a href='/fantasy/leagues' selected={props.tab == "leagues"}>Leagues</a>
					<a href='/fantasy/rankings'  selected={props.tab == "rankings"}>Rankings</a>
				</div>
				{props.title && <div class='title'>{ props.title }</div>}
			</div>
		)		
	}

}

class Fantasy extends Component {
	state = { loading: true, game_id: 2 }

	componentDidMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		const games_fetch = await fetch("/api/fantasy/games")
		const games_json = await games_fetch.json()

		this.games = games_json.games
		this.default_game_id = games_json.default_game_id  

		this.setState({ loading: false })
	}


	shouldComponentUpdate (nextProps, nextState) {
		if(this.games)
		{
			let game_id = this.default_game_id
			let g = this.games.find(x => x.slug == nextProps.game)
			if(g) game_id = g.id
			nextState.game_id = game_id
		}
	}

	selectGame = e => {
		let game_id = e.target.value
		let game = this.games.find(x => x.id == game_id)
		route('/fantasy/' + game.slug)
	}


	render(props, state)
	{
		if(state.loading) return
		return(
			<Fragment>
				<Nav tab='predictor'/>
				<div class='fantasy-container'>
					<div class='menu-container d-none'>
						<div class='menu'>
							{this.games.map(x => {
								return (<a selected={state.game_id == x.id} href={"/fantasy/" + x.slug}>{x.name}</a>)
							})}
						</div>
					</div>
					<div class='pt-2 pb-4 text-center'>	
						<div class='f80'>Select game:</div>
						{ this.games && <div class='select'>
							<select class='form-control form-control-sm' onChange={this.selectGame}>
								{this.games.map(x => {
									return(
										<option value={x.id} selected={state.game_id == x.id}>{x.name}</option>
									)
								})}
							</select>	
						</div>}
					</div>

					{!__session__ && <Login/>}
					{__session__ && state.game_id == 4 && <Game_World_703_2021 session={__session__}/>}
					{__session__ && state.game_id == 3 && <Game_Olympics session={__session__}/>}
					{__session__ && state.game_id == 2 && <Game_TriBattle/>}
					{__session__ && state.game_id == 1 && <_Game_CollinsCup/>}
	
				</div>
				<div class='text-center p-2 bg-white'>
					<small><a  class='text-grey' href='/fantasy/terms'>Fantasy Game Terms & Conditions</a></small>
				</div>
			</Fragment>
		)
	}
}


class Leagues extends Component {
	state = { loading: true }

	componentDidMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		
		const info_fetch = await fetch("/api/fantasy/leagues")
		const info_json = await info_fetch.json()

		this.leagues = info_json.hits

		this.setState({ loading: false })
	}

	render(props, state)
	{
		if(!__session__) return (
			<Fragment>
				<Nav tab='leagues' title="Leagues"/>
				<NeedLogin/>
			</Fragment>
		)

		if(state.loading) return (<Nav tab='leagues' title="Leagues"/>)

		return (
			<Fragment>
				<Nav tab='leagues' title="Leagues"/>
				<div class='max-width'>
					{ this.leagues.map(x => {
						return(
							<div class='league-item'>
								<div class='title'>{decodeURI(x.name)}</div>
								<div class='details d-flex align-items-center'>
									<div class='flex-grow-1'>
										<div class='d-none'>
											<div class='league-info'>
												<div class='name'>Last Race Points</div>
												<div class='info'>--</div>
											</div>
											<div class='league-info'>
												<div class='name'>Total Points</div>
												<div class='info'>--</div>
											</div>
											<div class='league-info'>
												<div class='name'>Your Rank</div>
												<div class='info'>--</div>
											</div>
										</div>
										<div class='d-flex text-grey f90'>
											<div class='mr-2'>{x.player_count} Players</div>
											<div class='ml-2'>{x.public == 0 ? "Private" : "Public"} League</div>
										</div>
									</div>
									<div class='d-flex'>
										<a href={"/fantasy/leagues/" + x.id} class='p-3 h3'><i class="fas fa-chevron-right"></i></a>
									</div>
								</div>
							</div>
						)
					})}
					<div class='box'>
						<div class='d-flex flex-column text-center align-items-center'>
							<div class='mb-4 flex-shring-1'>Leagues you have joined are listed above. You can join and create as many leagues as you like.</div>
							<div class='d-flex flex-shrink-0 flex-grow-1 justify-content-center'>
								<a href='/fantasy/leagues/join' class='btn btn-primary mr-2'>Join a League</a>
								<a href='/fantasy/leagues/create' class='btn btn-primary'>Create a League</a>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

class LeaguesJoin extends Component {
	state = { loading: true, search: "" }

	componentDidMount()
	{
		this.state.search = this.props.search || ""
		this.init(this.state.search)
	}
	componentWillUnmount()
	{
	}

	init = async (terms) => {
		this.leagues = []
		this.setState({})

		const info_fetch = await fetch("/api/fantasy/leagues/joinable" + (terms ? "?search=" + encodeURIComponent(terms) : ""))
		const info_json = await info_fetch.json()

		this.joined = info_json.joined
		this.leagues = info_json.leagues

		this.setState({ loading: false })
	}

	searchInput = (e) => {
		if(this.searchTimer) clearTimeout(this.searchTimer)

		this.searchTimer = setTimeout((that, terms) => {
			that.searchTimer = null
			that.init(terms)
		}, 250, this, e.target.value)

		this.setState({search: e.target.value})
	}

	join = e => {
		let league_id = e.target.dataset.leagueid
		fetch(`/api/fantasy/leagues/${league_id}/join`, { method: "post", })
		.then((r) => r.json())
		.then((d) => {
			route(`/fantasy/leagues/${league_id}`)
		})
	}

	render(props, state)
	{
		if(state.loading) return (<Nav tab='leagues' title="Join a League"/>)

		return (
			<Fragment>
				<Nav tab='leagues' title="Join a League"/>
				<div class='max-width'>
					<div class='p-3'>
						<div class='d-flex flex-column'>
							<div class='f90 mb-2'>Search or enter your unique league code</div>
							<input type='search' class='form-control bg-white' placeholder="Search" onInput={this.searchInput} value={state.search}/>
						</div>
					</div>
						{ this.leagues.map(x => {
							let joined = this.joined.find(y => y.league_id == x.id)
							return(
								<div class='leagues-joinable box'>
									<div class='league-container'>
										<div class='details'>
											<div class='name'>{decodeURI(x.name)}</div>
											<div class='info f90 text-grey'>{x.player_count} Players</div>
										</div>
										<div>
											{!joined && <button class='btn btn-primary mr-2' data-leagueid={x.id} onClick={this.join}>Join</button> }
											{joined && <a href={'/fantasy/leagues/' + x.id} class='btn btn-primary mr-2'>View</a> }
										</div>
									</div>
								</div>
							)
						})}
					<div class='box'>
						<div class='d-flex flex-column text-center align-items-center'>
							<div class='mb-4 flex-shring-1'>You can create your very own league(s) and invite your friends.</div>
							<div class='d-flex flex-shrink-0 flex-grow-1 justify-content-center'>
								<a href='/fantasy/leagues/create' class='btn btn-primary'>Create a League</a>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

class LeaguesCreate extends Component {
	state = { privacy: "private", error: null, league: null }

	create = (e) => {
		this.setState({ error: null })
		let that = this
		e.preventDefault()	

		var kvpairs = []
		for(let el of e.target.elements) 
		{
			if(el.type == "radio" && !el.checked) continue
			if(el.value == "") continue
			kvpairs.push(encodeURIComponent(el.name) + "=" + encodeURIComponent(el.value))
		}
		var queryString = kvpairs.join("&")
		console.log(queryString)
		fetch("/api/fantasy/leagues/create?" + queryString, { method: "post" })
		.then((r) => r.json())
		.then((d) => {
			if(d.status == 0)
			{
				that.setState({ error: null, league: d.league })
				// success
			}
			else if(d.status == 1)
			{
				that.setState({ error: "You need to give a name to your league."})
			}
			else if(d.status == 2)
			{
				that.setState({ error: "A league with this name already exists. Try a different name."})
			}
			else if(d.status == 3)
			{
				that.setState({ error: "A league name needs to be at least 4 characters."})
			}
			console.log(d)
		})
		//location = location.origin + location.pathname + (queryString != "" ? "?" + queryString : "")
	}

	copyCode = () => {
		copyToClipboard(this.state.league.code)
	}

	render(props, state)
	{
		return (
			<Fragment>
				<Nav tab='leagues' title="Create a League"/>
				<div class='max-width'>
					{ state.error && <div class='alert alert-danger m-4'>{state.error}</div>}
					{ state.league && 
					<div class='box'>
						<div class='h5 mb-3'>Invite your friends</div>
						<div class='mb-3'>You very own league was successfully created, you can invite others to join. Your unique code for your League is: <b>{state.league.code}</b></div>
						<button class='btn btn-sm btn-primary' onClick={this.copyCode}>Copy Code</button>
					</div>
					}
					{ !state.league && 
					<div class='box'>
						<div class='h5 mb-1'>Create your League</div>
						<div class='mb-3'>Fill out the form below to create your very own league.</div>
						<form onSubmit={this.create}>
							<div class=''>
								<div class='mb-1 f90'>Give your league a name*</div>
								<div class='d-flex'>
									<input class='form-control form-control-sm' type="text" placeholder='Choose a name' name='leaguename'/>
								</div>
							</div>
							<div class='py-3 d-none'>
								<div class='mb-1 f90'>Game</div>
								<div class='d-flex'>
									<select class='form-control-sm' name='game_id'>
										<option value="1">Collins Cup</option>
									</select>
								</div>
							</div>
							<div class='py-3'>
								<div class='mb-1 f90'>Privacy</div>
								<div class='d-flex'>
									<div class='radio-group'>
										<input class='form-control-sm' type="radio" value="private" name="privacy" label="Private" checked={state.privacy == "private"}/>
										<input class='form-control-sm' type="radio" value="public" name="privacy" label="Public" checked={state.privacy == "public"}/>
									</div>
								</div>
							</div>
							<div class='d-flex justify-content-end mt-2'>
								<button class='btn btn-sm btn-primary' type="submit">Create League</button>
							</div>
						</form>
					</div>
					}
				</div>
			</Fragment>
		)
	}
}

class LeagueDetails extends Component {
	state = { loading: true, menu: "rankings", copyCodeText: "Copy Code", copyLinkText: "Copy Link" }
	league = null

	componentDidMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	fetchRankings = async (game_id) => {
		const rankings_fetch = await fetch("/api/fantasy/rankings/" + game_id + "/league/" + this.props.leagueid)
		const rankings_json = await rankings_fetch.json()

		this.rankings = rankings_json.hits
		this.setState({ game_id: game_id })
	}


	init = async () => {

		const games_fetch = await fetch("/api/fantasy/games")
		const games_json = await games_fetch.json()

		this.games = games_json.games
		this.fetchRankings(games_json.default_game_id)
		
		const info_fetch = await fetch("/api/fantasy/leagues/" + this.props.leagueid)
		const info_json = await info_fetch.json()

		this.league = info_json.league
		this.players = info_json.players

		this.setState({ loading: false, privacy: this.league.public == 1 ? "public" : "private" })
	}

	menuSelect = (e) => {
		let menu = e.target.dataset.value
		this.setState({ menu: menu })
	}

	copyCode = () => {
		copyToClipboard(this.league.code)
		this.setState({ copyCodeText: "Code copied to your clipboard"})
	}

	copyLink = () => {
		copyToClipboard(location.origin + "/fantasy/leagues/join/" + this.league.code)
		this.setState({ copyLinkText: "Link copied to your clipboard"})
	}

	leave = e => {
		fetch(`/api/fantasy/leagues/${this.league.id}/leave`, { method: "post", })
		.then((r) => r.json())
		.then((d) => {
			route("/fantasy/leagues")
		})
	}

	save = (e) => {
		this.setState({ error: null })
		let that = this
		e.preventDefault()	

		let name = null, privacy = null

		for(let el of e.target.elements) 
		{
			if(el.type == "radio" && !el.checked) continue
			if(el.name == "privacy") privacy = el.value
			if(el.name == "leaguename") name = el.value
		}
		that.league.name = name
		that.setState({ privacy: privacy})

		if(!name || name.length < 4)
		{
			that.setState({ error: "League names need to be at least 4 characters." })
			return
		}

		fetch(`/api/fantasy/leagues/${this.league.id}/update`, 
			{ 
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ leaguename: encodeURIComponent(name), privacy: privacy })
			}
		)
		.then((r) => r.json())
		.then((d) => {
			if(d.status == 0)
			{
				that.setState({ menu: "rankings"})
			}
			else
			{
				that.setState({ error: "A league with this name already exists. Try a different name."})
			}
		})
	}

	remove = e => {
		let c = confirm("Are you sure you want to delete this league?")
		if(!c) return
		fetch(`/api/fantasy/leagues/${this.league.id}/remove`, { method: "post" })
		.then((r) => r.json())
		.then((d) => {
			if(d.status == 0)
			{
				route("/fantasy/leagues")
			}
		})
	}

	expand = e => {
		let id = e.target.closest(".player").dataset.id
		let player = this.rankings.find(x => x.id == id)
		if(player)
		{
			player.expand = !player.expand			
			this.setState({})
		}
	}

	selectGame = e => {
		let game_id = e.target.value
		this.fetchRankings(game_id)
	}


	render(props, state)
	{
		if(!this.league) return (<Nav tab='leagues' title="Loading..."/>)

		return (
			<Fragment>
				<Nav tab='leagues' title={decodeURI(this.league.name)}/>
				<div class='max-width'>
					<div class='league'>
						<div class='menu'>
							<div class='item' data-value="rankings" selected={ state.menu == "rankings" } onClick={this.menuSelect}>Rankings</div>
							{ this.league.owner_id == __session__.id && 
								<Fragment>
									<div class='item' data-value="invite" selected={ state.menu == "invite" } onClick={this.menuSelect}>Invite</div> 
									<div class='item' data-value="settings" selected={ state.menu == "settings" } onClick={this.menuSelect}>Settings</div>
								</Fragment>
							}
							{ this.league.owner_id != __session__.id && 
								<div class='item' data-value="about" selected={ state.menu == "about" } onClick={this.menuSelect}>About</div>
							}
						</div>
						{ state.menu == "rankings" &&
							<div class='rankings-container'>
								{ this.games && <div class='select'>
									<select class='form-control form-control-sm' onChange={this.selectGame}>
										{this.games.map(x => {
											return(
												<option value={x.id} selected={state.game_id == x.id}>{x.name}</option>
											)
										})}
									</select>	
								</div>}
								{ this.rankings && this.rankings.length != 0 && 
									<div class='rankings'>
										<div class='player head'>
											<div class='rank'>Pos.</div>
											<div class='name'>Player</div>
											<div class='points'>Points</div>
											<div class='expand'></div>
										</div>
										{ this.rankings.map((x, index) => {
											console.log(x)
											return(
												<Fragment>
													<div class='player' me={__session__.id == x.id} data-id={x.id}>
														<div class='rank'>{ index + 1}</div>
														<div class='name'>{x.first} {x.last} {x.tag && <span class='tag'>{x.tag}</span>}</div>
														<div class='points'>{x.score || "-"}</div>
														<div class='expand' onClick={this.expand}><i class={"far fa-caret-square-" + (x.expand ? "up" : "down")}></i></div>
													</div>
													{ x.expand && <div class='player-game'>
														<Game_Play game_id={state.game_id} player_id={x.id}/>
													</div> }
												</Fragment>
											)
										})}
									</div>
								}

								{ this.rankings && this.rankings.length == 0 && 
			 						<div class='rankings-container'>
			 							<div class='text-center h5 pt-4'>No rankings yet!</div>
										<div class='rankings'>
											<div class='player head'>
												<div class='rank'>Pos.</div>
												<div class='name'>Player</div>
												<div class='points'>Points</div>
											</div>
											{ this.players && this.players.map((x, index) => {
												return(
													<Fragment>
														<div class='player' me={__session__.id == x.id} data-id={x.id}>
															<div class='rank'>-</div>
															<div class='name'>{x.first} {x.last} {x.tag && <span class='tag'>{x.tag}</span>}</div>
															<div class='points'>-</div>
														</div>
													</Fragment>
												)
											})}
										</div>
									</div>
								}
							</div>
						}
						{ state.menu == "invite" &&
							<div>
								<div class='box'>
									<div class='h5 mb-3'>Invite your friends</div>
									<div class='mb-2'>Your friends can find this league easily with your league code. Your unique code for your League is: <b>{this.league.code}</b></div>
									<button class='btn btn-sm btn-primary' onClick={this.copyCode}>{state.copyCodeText}</button>
									<div class='my-3'>- or -</div>
									<div class='mb-2'>Share a link with your friends to join this league.</div>
									<button class='btn btn-sm btn-primary' onClick={this.copyLink}>{state.copyLinkText}</button>
								</div>
							</div>
						}
						{ state.menu == "settings" &&
							<div>
								{ state.error && <div class='alert alert-danger m-4'>{state.error}</div>}
								<div class='box'>
									<form onSubmit={this.save}>
										<div class=''>
											<div class='mb-1 f90'>League name</div>
											<div class='d-flex'>
												<input class='form-control form-control-sm' type="text" placeholder='Choose a name' name='leaguename' value={decodeURI(this.league.name)}/>
											</div>
										</div>
										<div class='py-3'>
											<div class='mb-1 f90'>Privacy</div>
											<div class='d-flex'>
												<div class='radio-group'>
													<input class='form-control-sm' type="radio" value="private" name="privacy" label="Private" checked={state.privacy == "private"}/>
													<input class='form-control-sm' type="radio" value="public" name="privacy" label="Public" checked={state.privacy == "public"}/>
												</div>
											</div>
										</div>
										<div class='d-flex justify-content-end mt-2'>
											<button class='btn btn-sm btn-primary' type="submit">Save</button>
										</div>
									</form>
								</div>
								<div class='d-flex justify-content-center pt-4'>
									<button class='btn btn-sm btn-secondary' onClick={this.remove}>Remove league</button>
								</div>
							</div>
						}
						{ state.menu == "about" &&
							<div>
								<div class='box'>
									<div class='mb-1 f90'>League name:</div>
									<div class=''><b>{this.league.name}</b></div>

									<div class='mt-3 mb-1 f90'>Privacy settings:</div>
									<div class=''><b>{this.league.public == 1 ? "Public" : "Private"}</b></div>

									<div class='d-flex justify-content-end mt-2'>
										<button class='btn btn-sm btn-primary' onClick={this.leave}>Leave League</button>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</Fragment>
		)
	}
}

class Rankings extends Component {
	state = { loading: true, game_id: -1 }

	componentWillMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	fetchRankings = async (game_id) => {
		const rankings_fetch = await fetch("/api/fantasy/rankings/" + game_id)
		const rankings_json = await rankings_fetch.json()

		this.rankings = rankings_json.hits
		this.setState({ game_id: game_id })
	}

	init = async () => {
		this.setState({ loading: true })

		const games_fetch = await fetch("/api/fantasy/games")
		const games_json = await games_fetch.json()

		this.games = games_json.games
		this.fetchRankings(games_json.default_game_id)

		this.setState({ loading: false })
	}

	expand = e => {
		let id = e.target.closest(".player").dataset.id
		let player = this.rankings.find(x => x.id == id)
		if(player)
		{
			player.expand = !player.expand			
			this.setState({})
		}
	}

	selectGame = e => {
		let game_id = e.target.value
		this.fetchRankings(game_id)
	}

	render(props, state)
	{
		return (
			<Fragment>
				<Nav tab='rankings' title="Rankings"/>
				
				<div class='max-width'>
					{ this.games && <div class='select'>
						<select class='form-control form-control-sm' onChange={this.selectGame}>
							{this.games.map(x => {
								return(
									<option value={x.id} selected={state.game_id == x.id}>{x.name}</option>
								)
							})}
						</select>	
					</div>}
					{ this.rankings && this.rankings.length != 0 && 
 						<div class='rankings-container'>
							<div class='rankings'>
								<div class='player head'>
									<div class='rank'>Pos.</div>
									<div class='name'>Player</div>
									<div class='points'>Points</div>
									<div class='expand'></div>
								</div>
								{ this.rankings.map((x, index) => {
									return(
										<Fragment>
											<div class='player' me={__session__ && __session__.id == x.id} data-id={x.id}>
												<div class='rank'>{ index + 1}</div>
												<div class='name'>{x.first} {x.last} {x.tag && <span class='tag'>{x.tag}</span>}</div>
												<div class='points'>{x.score || "-"}</div>
												<div class='expand' onClick={this.expand}><i class={"far fa-caret-square-" + (x.expand ? "up" : "down")}></i></div>
											</div>
											{ x.expand && <div class='player-game'>
												<Game_Play game_id={state.game_id} player_id={x.id}/>
											</div> }
										</Fragment>
									)

								})}
							</div>
						</div>
					}
					{ this.rankings && this.rankings.length == 0 && 
 						<div class='rankings-container'>
 							<div class='text-center h5 pt-4'>No rankings yet!</div>
						</div>
					}
				</div>
			</Fragment>
		)
	}
}

class Media extends Component {
	state = { loading: true, game_id: -1 }

	componentWillMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		this.setState({ loading: true })

		const media_fetch = await fetch("/api/fantasy/cc_2021_media")
		const media = await media_fetch.json()

		this.setState({ loading: false, media: media })
	}

	bio = e => {
		let bio = e.target.closest(".bio")
		if(!bio) return
		let member = this.state.media.teams[bio.dataset.teamindex].members[bio.dataset.memberindex]
		this.setState({ member: member })
	}

	close = e => {
		this.setState({ member: null })
	}

	render(props, state)
	{
		return (
			<Fragment>
				<div class='fantasy-media'>
					<div class='cc-logo'>
						<img src='https://content.protriathletes.org/media/content/test/5643c93b-0f5f-4eeb-b9f2-22955f08cd70.png'/>
						<div class='subtitle'>MEDIA FANTASY</div>
						<div class='subtitle'>COMPETITION</div>
					</div>
					<div class='text-center px-4 w-100'>
						<div  style='max-width: 75ch; margin: 0 auto;'>
							<p>
							This is the Collins Cup Media Fantasy Competition taking place in the run up to The Collins Cup in Samorin, Slovakia on August 28th, 2021. 
							</p>
							<p>
							With pundits expressing strong opinions on the outcomes of the races, informed by their knowledge of our sport, it is time to put that knowledge to the test and determine which region has the most knowledgeable triathlon media and journalists and which are talking nonsense. 
							</p>
							<p>
							Like the Collins Cup itself, the competition with consist of three teams - USA, Europe and Internationals. Each team will have ten members who will each select the winners and second place finishers for the 12 Collins Cup race matches receiving points for correct selections.
							</p>
							<p>
							The scores of all ten team members will be aggregated and the team with the most points will be crowned the winner and claim the Golden Pen Award. The last place team will bear the shame of being presented with the Fake News Trophy.
							</p>
						</div>
					</div>
					{state.media && state.media.teams.map((team, team_index) => { return (
						<div class='team' team={team.team}>
							<div class='title'>Media Team {team.name} ({team.members.sort((a,b) => b.score - a.score).slice(0,8).reduce((c, x) => x.score + c, 0)} pts)</div>
							{team.members.sort((a,b) => b.score - a.score).map((member, member_index) => { return (
								<div class='member'>
									<div class='pic'>
										{member.pic != "" && <img src={member.pic}/>}
										{member.pic == "" && <i class="fas fa-user"></i>}
									</div>
									<div class='info'>
										<div class='score'>{member.score || 0} pts</div>
										<div class='name'>{member.name}</div>
										{member.website != "" && <div class='website link-anchor'><a href={member.website} target='_blank'>{member.publication || member.website}</a></div> }
										{member.bio != "" && <div class='bio' data-teamindex={team_index} data-memberindex={member_index} onClick={this.bio}>Read Bio</div> }
									</div>
								</div>
							)})}
						</div>
					)})}
					{state.member &&
						<div class='member-background'>
							<div class='member-dialog'>
								<div class='member'>
									<div class='pic'><img src={state.member.pic}/></div>
									<div class='info'>
										<div class='name'>{state.member.name}</div>
										<div class='website'><a href={state.member.website} target='_blank'>{state.member.publication || state.member.website}</a></div>
										<div class='bio'>{state.member.bio}</div>
									</div>
								</div>
								<div class='w-100 pt-3 d-flex justify-content-center'>
									<button class='btn btn-primary' onClick={this.close}>Close</button>
								</div>
							</div>
						</div>
					}
				</div>
			</Fragment>
		)
	}
}

class NeedLogin extends Component {
	login = () => {
		location.href = window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback&state=fantasy" + '&client_id=' + window.appData.oauth_client_id
	}

	render(props, state)
	{
		return (
			<div class='fantasy-container py-4'>
				<div class='py-4 d-flex flex-column align-items-center text-center'>
					<div class='mb-4'>You need to have an account and be logged in to access this section.</div>
					<button class='btn btn-primary' onClick={this.login}>Log in here</button>
				</div>
			</div>
		)
	}
}

class Terms extends Component {

	render(props, state)
	{
		return (
			<Fragment>
				<div class='max-width py-4'>
					<p><h5>Professional Triathletes Organisation – Fantasy Game Terms and Conditions</h5></p>
					<p class="p1">These terms and conditions apply to the PTO Fantasy Game (the &ldquo;<strong>Game</strong>&rdquo;) run and organised by PTO Commercial Limited (the &ldquo;<strong>Company</strong>&rdquo;) of Tintagel House, 92 Albert Embankment, London, England, SE1 7TY.<span class="Apple-converted-space">&nbsp;</span></p>
					<p class="p2">By submitting an entry to and/or taking part in the Game, you agree to be bound by these terms and conditions. If you do not agree or accept any of these terms then do not seek to take part in the Game.<span class="Apple-converted-space">&nbsp;</span></p>
					<p class="p2">These terms and conditions may change from time to time. Any such changes will be reflected by an update of this page and you should check these terms and conditions periodically for changes. Your continued use of the Game following any update to the terms and conditions will mean you accept those changes and such changes shall apply to your continued use of the Game.<span class="Apple-converted-space">&nbsp;</span></p>
					<ol class="ol1">
						<li class="li2"><strong>Application, Registration and Eligibility</strong></li>
					</ol>
					<ol class="ol2">
						<li class="li2">The Game is open to individuals aged 18 years or over. Employees of the Company, their families, agents or any third party directly associated with administration of the Game may enter but shall be ineligible to win any prizes. The Game has been produced to be subject to English law. Therefore, if you are resident outside of the UK you acknowledge your responsibility to determine whether you can lawfully take part in the Game and comply fully with the terms and conditions of the Game.<span class="Apple-converted-space">&nbsp;</span></li>
						<li class="li2">All applications to participate in the Game must be submitted via the website <a href="http://www.protriathletes.org"><span class="s1">www.protriathletes.org</span></a> (the &ldquo;<strong>Website</strong>&rdquo;) and only one entry per person is permitted.<span class="Apple-converted-space">&nbsp;</span></li>
						<li class="li2">No purchase or payment is required to enter the Game.</li>
						<li class="li2">You must submit your application yourself and registration must not be completed on your behalf by agents or third parties. Any such applications will be deemed void.</li>
						<li class="li2">By participating in the Game, the entrant confirms it is eligible to do so and eligible to claim any prize it may win. The Company may require the entrant to provide proof it is eligible to enter the Game.</li>
						<li class="li2">By participating in the Game, you warrant and represent all information submitted by you is true, current and complete. The Company reserves the right to verify the eligibility of an entrant and disqualify any entrant for submitting false, incomplete and/or out of date information.</li>
						<li class="li2">Individuals are not permitted to make multiple applications for registration for the Game.</li>
						<li class="li2">The Company takes safeguarding very seriously and wishes to ensure the Game remains an inclusive and safe place for all participants. The Company reserves the right to suspend, delete and/or alter the name of a registration that contains names, words or phrases which are deemed solely by the Company to be inappropriate, profane, promotional, unkind, political in nature, derogatory, discriminatory, divisive or offensive. This decision shall be made in the Company&rsquo;s sole discretion irrespective of the opinion of the participant.</li>
						<li class="li2">The Company may publish deadlines from time to time by which you must have successfully completed registration in order to be eligible for a certain round of points or prizes. Any additional information supplied on how and when to enter the Game forms part of these terms and conditions and shall be binding on you.</li>
						<li class="li2">By making an application to participate in the Game you will be providing your personal information to the Company. Any personal data submitted will be processed in accordance with our <a href="https://protriathletes.org/privacy-policy"><span class="s1">Privacy Policy</span></a> and in accordance with relevant data protection legislation.</li>
						<li class="li2">Personal data supplied during the course of the Game may be (i) held and used only by the Company or its agents and suppliers to administer the Game; and (ii) passed on to third party suppliers only insofar as required for fulfilment/delivery/arrangement of the prize.<span class="Apple-converted-space">&nbsp; </span>By participating in the Game and submitting personal data, consent is given to any storing or processing for these purposes.</li>
						<li class="li2">Where a prize involves travel outside of the EEA, data relating to winners and their guests may be transferred outside the EEA solely for the purpose of fulfilling the prize.<span class="Apple-converted-space">&nbsp; </span>By participating in the Game and submitting personal data, consent is given to this transfer for these purposes.</li>
						<li class="li2">The Company does not permit any participant, individual or organisation (save for official partners with the express consent of the Company) to use the Game for any commercial purpose, including but not limited to:</li>
					<ol class="ol1">
						<li class="li2">charging for entry into any third-party competition;<span class="Apple-converted-space">&nbsp;</span></li>
						<li class="li2">making entry into any third-party competition conditional on the purchase of goods or services; and</li>
						<li class="li2">streaming, broadcasting and/or aggregating content, data and/or results relating to the Game and/or any element of the Game.</li>
					</ol>
					</ol>
					<ol class="ol1">
						<li class="li2"><strong>Game Rules</strong></li>
					</ol>
					<ol class="ol2">
						<li class="li2">The Game will be governed by the 'How To Play' section as amended from time to time and published on the Website. The Company reserves the right to alter, amend or supplement the Game Rules in its absolute discretion. You agree that no liability shall attach to the Company as a result of any such change and so are advised to check the Game Rules regularly. The Game Rules form part of these terms and conditions and by participating in the Game you accept to be bound by the Game Rules.</li>
						<li class="li2">The Company is the sole decision-maker and authority of any matter of interpretation of both the Game Rules and all aspects of playing the Game. The Company is under no obligation to enter into correspondence relating to such matters and its decisions as to any matter arising out of or in connection with the Game Rules shall be final and conclusive.</li>
					</ol>
					<ol class="ol1">
						<li class="li2"><strong>Details of Winners and Prizes</strong></li>
					</ol>
					<ol class="ol2">
						<li class="li2">Subject to participant&rsquo;s compliance with these terms, the Company may, at its sole discretion, choose to award prizes in respect of the Game. Winners shall receive the prize as set out in the Game&rsquo;s details on the Website and if such a prize is not available then the Company shall seek to procure a sensible alternative.</li>
						<li class="li2">There may be restrictions imposed on when prizes may be redeemed, for example holidays, equipment or specific events. These will be provided in the Game&rsquo;s details on the Website.</li>
						<li class="li2">Prizes are non-exchangeable, non-transferable and no cash alternative is offered. The Company does not accept any responsibility if the winner is not able to table the prize.</li>
						<li class="li2">Unless expressly stated otherwise, no prize will include travel insurance, the cost of transfers to and from airports or stations, food and drink, spending money, tax or personal expenses (including meals or personal expenses, upgrades etc.). Any other costs incurred in addition to those set out above and that are incidental to the fulfilment of the prize are the responsibility of the winner(s).</li>
						<li class="li2">If a prize includes foreign travel, the winners and their guests are solely responsible for ensuring they have valid passports with no restrictions on travel to the winning destination, relevant visas and comprehensive travel insurance and to familiarise themselves with local laws and customs, as well as ensuring all travellers have the appropriate and up to date vaccinations and inoculations at the time of travel.</li>
						<li class="li2">If any aspect of a prize, is cancelled or postponed due to circumstances beyond the Company&rsquo;s control, including but not limited to, travel cancellations, delays or interruptions due to acts of God, acts and advice of Governments, acts of war, epidemic, pandemic, natural disasters, weather, acts of terrorism, national catastrophes, advice against any travel by any Government or official authority for any reason (including by way of example only and without limitation by reason of any actual or potential terrorist threat) and/or the World Health Organisation or any internationally recognised medical authority for any reason or an event of force majeure, the Company shall not be responsible and that aspect of the prize will not be awarded and no additional compensation will be provided. No refunds or credit for changes to prizes are allowed.</li>
						<li class="li2">Third party terms and conditions apply to a prize where stated in the Game details on the Website.</li>
						<li class="li2">Winners will be notified by email or telephone (using details provided at entry) before and must provide a postal address to claim their prize. If a winner does not respond to the Company within 48 hours of being notified by the Company, then the winner's prize will be forfeited and the Company will select another winner in accordance with the process described above.</li>
						<li class="li2">Any prizes will be sent to the winner by post within 5 days of the winner responding to the notification of their win.<span class="Apple-converted-space">&nbsp; </span>The prize will not be delivered to addresses outside of the UK. The prize may not be claimed by a third party on your behalf.</li>
						<li class="li2">Unless stated otherwise in the Game details, the prize is supplied by the Company. The Company reserves the right to replace the prize with an alternative prize of equal or higher value if circumstances beyond the Company&rsquo;s control make it necessary to do so.</li>
						<li class="li2">Winners may be required to provide proper proof of age and/or residency to claim a prize. Such proof must be provided within 7 days of the Company asking you for it and failure to do so, or if the Company has any doubts as to such proof&rsquo;s validity or authenticity, may result in forfeiture of the prize and another winner being selected.</li>
						<li class="li2">Winners consent to and may be required to participate in publicity related to the Game which may include the publication of their name and photograph in any media, filming by the Company, providing a photographs and/or video of himself/herself enjoying the prize, or submit a report of the same for the Company&rsquo;s ongoing use (each the &ldquo;<strong>Materials</strong>&rdquo;).</li>
					</ol>
					<ol class="ol1">
						<li class="li2"><strong>Participant Responsibilities</strong></li>
					</ol>
					<ol class="ol2">
						<li class="li2">All participants warrant and represent that, while participating in the Game, they shall not upload, post, transmit, distribute or otherwise publish through the Game or in relation to the Game any information, content or materials that:</li>
					</ol>
					<ol class="ol1">
						<li class="li2">are protected by any copyright, proprietary or intellectual property right, or derivative works with respect thereto without first obtaining permission from the copyright owner;<span class="Apple-converted-space">&nbsp;</span></li>
						<li class="li2">are unlawful, threatening, harassing, profane, tortious, defamatory, discriminatory, vulgar, obscene, libellous, deceptive, fraudulent, invasive of another&rsquo;s privacy, or hateful;</li>
						<li class="li2">restrict, inhibit, impede or otherwise hinder others from using and enjoying the Game;</li>
						<li class="li2">constitute, encourage or contribute to conduct that would constitute a criminal offence or give rise to civil liability; or</li>
						<li class="li2">contain any kind of virus, harmful component or computer code designed to adversely affect the operation of the Game, computer software or hardware.</li>
					</ol>
					<ol class="ol2">
					<li class="li2">All participants warrant and represent that they shall not:</li>
					</ol>
					<ol class="ol1">
					<li class="li2">impersonate, misrepresent or otherwise mislead others in relation to an affiliation with the Company or any other person or entity;</li>
					<li class="li2">upload, post, publish, transmit, reproduce, distribute, download, host or in any way exploit any information or material obtained through the Game for commercial purposes;</li>
					<li class="li2">engage in any spamming, flooding, doxing or any other kind of abusive online activity;</li>
					<li class="li2">use any automated systems to scrape data or otherwise extract information from the Game and related systems;</li>
					<li class="li2">attempt to gain unauthorised access to the Company&rsquo;s computer systems or any other such systems related to the Game; or</li>
					<li class="li2">bring the Game or the Company into disrepute.<span class="Apple-converted-space">&nbsp;</span></li>
					</ol>
					<ol class="ol2">
					<li class="li2">Except as otherwise expressly permitted herein, you may not upload, post, publish, reproduce, transmit or distribute in any way any component of the Game itself or derivative works with respect thereto, as the Game is copyrighted as a collective work under the copyright laws of England and Wales.</li>
					</ol>
					<ol class="ol1">
					<li class="li2"><strong>Company Responsibility and Liability</strong></li>
					</ol>
					<ol class="ol2">
					<li class="li2">The extent of the Company&rsquo;s responsibility to participants has been determined in the context of the following:</li>
					</ol>
					<ol class="ol1">
					<li class="li2">the Game is provided to participants free of charge and on an &ldquo;as is&rdquo; basis;</li>
					<li class="li2">participants are responsible for any action they do or do not take as a consequence of the Game and information contained therein; and</li>
					<li class="li2">participants are responsible for ensuring their computer hardware, software and equipment is enabled with appropriate up-to-date virus checking software and other appropriate safeguards.</li>
					</ol>
					<ol class="ol2">
					<li class="li2">The Company will always endeavour to ensure the Game is available to participants at all times, without faults and that the contents are correct and accurate to the best of the Company&rsquo;s knowledge, however, it cannot make any legal commitment that this will be the case.</li>
					<li class="li2">The Company reserves the right at any time to temporarily or permanently alter or discontinue the Game with or without prior notice. In the event the Company permanently discontinues the Game pursuant to this paragraph, you shall be released from your obligations to the Company under these terms and conditions.</li>
					<li class="li2">In no event shall the Company or its subsidiaries have liability for:</li>
					</ol>
					<ol class="ol1">
					<li class="li2">loss of business, use, profit, anticipated profit, contracts, revenues, goodwill or anticipated savings;</li>
					<li class="li2">loss of data or use of data;</li>
					<li class="li2">product recall costs;<span class="Apple-converted-space">&nbsp;</span></li>
					<li class="li2">damage to participant&rsquo;s reputation; or</li>
					<li class="li2">consequential, special or indirect loss or damage (including, without limitation, damages for loss of business profits, business interruption, or loss of business information);</li>
					</ol>
					<p class="p3">however caused and on any theory of liability, even if the Company and its subsidiaries have been advised of the possibility of such damages. These limitations shall apply not withstanding any failure of essential purpose of any limited remedy.</p>
					<ol class="ol2">
					<li class="li2">Nothing in these terms and conditions excludes or limits the Company&rsquo;s liability for:</li>
					</ol>
					<ol class="ol1">
					<li class="li2">death or personal injury caused by its negligence;</li>
					<li class="li2">fraudulent misrepresentation; or</li>
					<li class="li2">any other type of liability which cannot by law be excluded or limited.</li>
					</ol>
					<ol class="ol1">
					<li class="li2"><strong>Other important information<span class="Apple-converted-space">&nbsp;</span></strong></li>
					</ol>
					<ol class="ol2">
					<li class="li2">Entrants are deemed to have accepted and agreed to be bound by these terms and conditions upon entry. The Company reserves the right to refuse entry, or refuse to award the prize to anyone in breach of these terms and conditions.</li>
					<li class="li2">The Company reserves the right to hold void, cancel, suspend, or amend the Game or these terms without prior notice where it becomes necessary to do so including in the event of human error or circumstances beyond our reasonable control. Any changes will be posted on the Website and such posting shall be adequate notice to all participants. <span class="Apple-converted-space">&nbsp;</span></li>
					<li class="li2">Insofar as is permitted by law, the Company, its agents or distributors will not in any circumstances be responsible or liable to compensate the winner or accept any liability for any loss, damage, personal injury or death occurring as a result of taking up the prize except where it is caused by the negligence of the Company, its agents or distributors or that of their employees. The entrant&rsquo;s statutory rights are not affected.</li>
					<li class="li2">Any breach by you of the Game Rules from time to time shall also be a breach of these terms and conditions.</li>
					<li class="li2">In any event of any breach by you of these terms and conditions the Company reserves the right in its sole discretion to:</li>
					</ol>
					<ol class="ol1">
					<li class="li4">permanently or temporarily refuse you entry to the Game;</li>
					<li class="li4">disqualify you from the Game;</li>
					<li class="li4">modify, delete and/or suspend your registration;</li>
					<li class="li4">delete all related information associated with your registration; and/or</li>
					<li class="li4">require any reasonable amendment to your registration.</li>
					</ol>
					<p class="p5">All such decisions will be final and no correspondence will be entered into.</p>
					<ol class="ol2">
					<li class="li2">If you are barred or disqualified from being registered in the Game, you shall not be eligible to participate in the Game under any other identity or team name. Any such decision by the Company shall be final.<span class="Apple-converted-space">&nbsp;</span></li>
					<li class="li2">If any of these terms and conditions are determined to be illegal, invalid, or otherwise enforceable then the remaining terms shall continue in full force and effect.</li>
					<li class="li2">The Game and Game Rules will be governed by English law and entrants to the Game submit to the exclusive jurisdiction of the courts of England.</li>
					</ol>
					<p class="p6">&nbsp;</p>
					<p class="p7">Last Updated: <span class="s2"><strong>[30 July 2021]</strong></span></p>
				</div>
			</Fragment>
		)
	}
}

class Login extends Component {
	login = () => {
		location.href = window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback&state=fantasy" + '&client_id=' + window.appData.oauth_client_id
	}

	render(props, state)
	{
		return (
			<div class='fantasy-container py-4'>
				<div class='py-4 d-flex flex-column align-items-center text-center'>
					<h2 class='mb-2 d-none'>Welcome to the PTO Fantasy Game.</h2>
					<div class='mb-4'>You need to have an account and logged in to score points and take on your friends.</div>
					<button class='btn btn-primary' onClick={this.login}>Log in to play</button>
				</div>
			</div>
		)
	}
}

class FantasyRouter extends Component {

	handleRoute = e => {
		window.scrollTo({ top: 0 })
		
		//console.log("ROUTE", e)
		return
		if(e.url == "/login" || e.url == "/signup")
		{

		}
		else
		{
			let token = localStorage.removeItem('ptotoken')
			if(!token) route("/login", true)
			/*let data_fetch = await fetchAPI("/auth/verifysession", { method: "post" })
			let data = await data_fetch.json()
			if(data.status != 0) route("/login", true)*/
		}
		/*switch (e.url) {
			case '/profile':
				const isAuthed = await this.isAuthenticated();
				if (!isAuthed) route('/', true);
			break;
		}*/
	};
	
	render(props, state) {
		return(
			<Fragment>
				<Router onChange={this.handleRoute}>	
					<Media path="/fantasy/collinscup-2021-media"/>
					<Leagues path="/fantasy/leagues"/>
					<Terms path="/fantasy/terms"/>
					<LeaguesJoin path="/fantasy/leagues/join/:search?"/>
					<LeaguesCreate path="/fantasy/leagues/create"/>
					<LeagueDetails path="/fantasy/leagues/:leagueid"/>
					<Rankings path="/fantasy/rankings/:game?"/>
					<Fantasy path="/fantasy/:game?"/>
				</Router>
			</Fragment>
		); 		
	}
}


let fantasy = document.getElementById('fantasy')
if(fantasy)
{
	fetch("/api/user/me")
	.then((r) => r.json())
	.then((d) => {
		if(d.session)
		{
			__session__ = d.session
		}
		render(<FantasyRouter/>, fantasy)
		/*else
		{
			render(<Login/>, fantasy)
		}*/
	})
}

