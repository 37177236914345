// js for pro events directory
const sortables = document.querySelectorAll(".sortable")
if (sortables) {
	for (const sortable of sortables) {
		const controls = sortable.getElementsByClassName("sortable-control")
		for (const control of controls) {
			control._sortable = sortable
			control.addEventListener("click", function() {
				const sortable = this._sortable
				const items = sortable.getElementsByClassName("sortable-item")
				const allItems = []
				while (items.length !== 0) {
					let _item = items[0].parentElement.removeChild(items[0]);
					allItems.push(_item)
				}

				const sortClass = this.dataset.sortClass

				// toggle icon states
				if (this.classList.contains("active")) {
					this.children[0].classList.toggle("fa-sort-amount-up-alt")
					this.children[0].classList.toggle("fa-sort-amount-down-alt")
					if (!sortable.sortOverride && !sortable.sortClass) {
						// click on default sort control
						sortable.sortClass = sortClass
						sortable.sortAsc = true
					}
				}
				else {
					this.classList.add("active")
				}

				// reset other controls
				sortable.querySelectorAll(".sortable-control").forEach((otherControl) => {
					if (otherControl !== control) {
						otherControl.classList.remove("active")
						otherControl.children[0].classList.remove("fa-sort-amount-up-alt")
						otherControl.children[0].classList.add("fa-sort-amount-down-alt")
					}
				})

				if (!sortable.sortOverride) {
					if (sortable.sortClass === sortClass) {
						sortable.sortAsc = !sortable.sortAsc
					}
					else {
						sortable.sortClass = sortClass
						sortable.sortAsc = true
					}
				}
				sortable.sortOverride = false

				const sortedItems = allItems.sort((a, b) => {
					const aValue = parseInt(a.querySelector('.' + sortClass).dataset.sortValue)
					const bValue = parseInt(b.querySelector('.' + sortClass).dataset.sortValue)

					if (aValue < bValue) {
						return sortable.sortAsc ? -1 : 1
					}
					else if (aValue > bValue) {
						return sortable.sortAsc ? 1 : -1
					}

					return 0
				});

				for (const item of sortedItems) {
					sortable.children[1].appendChild(item)
				}
			})
		}
	}
}

const sortableSelectControl = document.querySelector(".pro-events-directory .sortable-select-control")
if (sortableSelectControl) {
	sortableSelectControl.addEventListener("change", function(e) {
		const sortValue = e.target.value.split('_')
		const sortable = document.querySelector(".pro-events-directory .sortable")
		sortable.sortClass = sortValue[0]
		sortable.sortAsc = sortValue[1] === 'asc'
		sortable.sortOverride = true
		sortable.querySelector(".sortable-control." + sortValue[0]).click()
	})
}

const expandEventDetails = document.querySelectorAll(".pro-events-directory .expand-event-details")
if (expandEventDetails) {
	for (const expand of expandEventDetails) {
		expand.addEventListener("click", function(e) {
			const eventId = e.target.dataset.id || e.target.parentNode.dataset.id
			document.getElementById('event-details-' + eventId).classList.toggle('show')
		})
	}
}
