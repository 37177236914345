import { h, render, Component, createRef, Fragment } from 'preact';
import moment from 'moment';
import { secondsToTime } from "../server/components/utils"


/*let yt_tag = document.createElement('script');
yt_tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(yt_tag, firstScriptTag);
*/

let __yt_cbs__ = []

window.onYouTubeIframeAPIReady = function () {
	for(let cb of __yt_cbs__) cb(window.YT)
}

window.getYouTubeAPI = function(cb) {
	if(window.YT) cb(window.YT)
	else __yt_cbs__.push(cb)	
}


function loadImage(url)
{
	return new Promise(resolve => {
		const image = new Image()
		image.addEventListener("load", () => {
			resolve(image)
		})
		image.src = url
	})	
}

let broadcast = document.getElementById("broadcast")
class Broadcast extends Component {

	state = { vsType: "scores" }

	domSvgRef = null
	athletes = []
	athlete_data = null
	svg = null

	selected_athletes = [null,null]
	selected_athletes_data = [null, null]

	div_player = createRef()
	versusType = createRef()

	current_timeline = null

	loadAthleteList()
	{
		let that = this
		fetch("/api/athletes", {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.athletes = data.athletes
			//that.loadAthlete(data.athletes[0].id)
			that.setState({ })
		})
	}

	playVideo = () =>
	{
		if(this.yt_player)
		{
			this.yt_player.destroy()
			this.yt_player = null
			return
		}
		window.getYouTubeAPI(yt => {
			this.yt_player = new yt.Player('yt-player', {
				width: '100%',
				height: '100%',
				videoId: 'Rf2LbK6BhG4',
				playerVars: {
					controls: 0,
					disablekb: 1,
					enablejsapi: 1,
					loop: 1,
					modestbranding: 1,
					playsinline: 1,
				},
				events: {
					'onReady': function(event) { event.target.setVolume(0); event.target.playVideo(); console.log(event.target) },
					//'onStateChange': onPlayerStateChange
				}
			});
		})
	}	

	clearVideo()
	{
		if(this.yt_player) this.yt_player.clearVideo()
	}

	componentDidMount()
	{
		console.log("////////////////////", location)

		let connection = new WebSocket("ws://" + location.host)

		connection.onopen = (event) => {
			console.log("open")
			//event.target.send("Hello websocket!")
		}

		connection.onerror = (error) => {
			console.log("error")
		};

		connection.onclose = (event) => {
			console.log("close")
		};

		connection.onmessage = (message) => {
			console.log(message)
			/*try {
				var data = JSON.parse(message.data);
			} catch (e) {
				console.log('This doesn\'t look like a valid JSON or something else went wrong.');
				console.log(message);
				return
			}*/
		}

	}

	createCircleScore(color, percent, name, options)
	{
		let textColor = options?.color == undefined ? 0xffffff : options.color

		const centerStyle = new PIXI.TextStyle({
			fontFamily: 'Lato,sans-serif',
			fontWeight: 'bold',
			fontSize: 30,
			fill: textColor,
		});

		const nameStyle = new PIXI.TextStyle({
			fontFamily: 'Lato,sans-serif',
			fontSize: 24,
			fill: textColor,
		});

		const container = new PIXI.Container();
		let pi = 3.14159265359
		let circle = new PIXI.Graphics()

		let x = 53, y = 53
		let angleStart = - pi * 0.5
		let angleEnd = 2 * pi * (percent / 100.0) - pi * 0.5
		let radius = 53
		let inner = radius - 10
		circle.beginFill(0x444444)
		.drawTorus(x, y, inner, radius, 0, 2*pi)
		.endFill()
		.beginFill(color)
		.drawTorus(x, y, inner, radius, angleStart, angleEnd)
		.endFill()
		container.addChild(circle);

		const percentText = new PIXI.Text(percent + "%", centerStyle);
		percentText.anchor.set(0.5)
		percentText.x = x
		percentText.y = y
		container.addChild(percentText);

		if(name && name != "")
		{
			const nameText = new PIXI.Text(name.toUpperCase(), nameStyle);
			nameText.anchor.x = 0.5
			nameText.x = x
			nameText.y = y + radius + nameStyle.fontSize * 0.25
			container.addChild(nameText);
		}

		return container
	}

	createSubText(text, x, y, options)
	{
		let textColor = options?.color == undefined ? 0xc0c0c0 : options.color
		const subText = new PIXI.Text(text, {
			fontFamily: 'Lato,sans-serif',
			fontSize: 24,
			fill: textColor,
		});
		subText.anchor.x = 0.5
		subText.anchor.y = 0.5
		subText.x = x
		subText.y = y
		return subText
	}

	cacheAthlete(athlete_data)
	{
		let athlete = athlete_data.athlete
		let country = athlete.country.toLowerCase()
		let pic = athlete_data.attributes.find(x => x.name == "pic")
		if(pic)
		{
			let image = new Image()
			image.src = "/proxy?url="+pic.value
		}

		let flagImage = new Image()
		flagImage.src = "https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/" + country + ".svg"
	}

	hide = () =>
	{
		if(this.current_timeline)
		{
			this.current_timeline.play().then(() => {
				this.current_timeline = null
			})
		}
	}

	queueDrawAthlete(options)
	{
		if(this.current_timeline)
		{
			this.current_timeline.play().then(() => {
				this.current_timeline = null
				this.drawAthlete(options)
			})
			return
		}
		else
		{
			this.drawAthlete(options)
		}
	}

	queueDrawVS(options) 
	{
		if(this.current_timeline)
		{
			this.current_timeline.play().then(() => {
				this.current_timeline = null
				this.drawVS(options)
			})
			return
		}
		else
		{
			this.drawVS(options)
		}
	}

	createRankContainer(rank, options)
	{
		const container = new PIXI.Container()

		let textColor = options?.color == undefined ? 0xffffff : options.color

		const rankingHashText = new PIXI.Text("#", { fontFamily: 'Avenir Next LT W04 Heavy Cn It,sans-serif', fontSize: 32,fill: 0xD10B0C });
		rankingHashText.x = 0 
		container.addChild(rankingHashText);

		const rankingText = new PIXI.Text(rank, { fontFamily: 'Avenir Next LT W04 Heavy Cn It,sans-serif', fontSize: 48, fill: textColor });
		rankingText.x = 12 
		container.addChild(rankingText);

		return container
	}

	addBackground(container, options)
	{
		let margin = options.margin
		let localBounds = container.getLocalBounds()
		if(options.x != undefined) localBounds.x = options.x 
		if(options.width != undefined) localBounds.width = options.width 
		let backgroundRect = new PIXI.Graphics()
		container.addChildAt(backgroundRect, 0)
		backgroundRect
		.beginFill(options.color, options.alpha)
		.drawRoundedRect(localBounds.x - margin, localBounds.y - margin, localBounds.width + margin*2, localBounds.height + margin*2, 0)
		.endFill()
	}

	drawVS(options)
	{
		let typeComp = this.state.vsType 

		let athletes = [null, null]
		let pics = [null, null]
		let rankings = [null, null]
		let results = []
		for(let index = 0; index < 2; index++)
		{
			athletes[index] = this.selected_athletes_data[index].athlete
			pics[index] = this.selected_athletes_data[index].attributes.find(x => x.name == "pic")
			rankings[index] = this.selected_athletes_data[index].rankings[0]
		}

		let stage = this.app.stage

		stage.removeChildren()
		const container = new PIXI.Container()
		stage.addChild(container)

		let backgroundRect = new PIXI.Graphics()
		container.addChild(backgroundRect)

		const containerAthletes = new PIXI.Container()
		container.addChild(containerAthletes)

		let left = 40
		for(let index = 0; index < 2; index++)
		{
			let textColor = 0xffffff //index ? 0xffffff : 0x000000

			const contAthlete = new PIXI.Container()
			containerAthletes.addChild(contAthlete);
			
			const forceWidth = new PIXI.Graphics()
			forceWidth.beginFill(0x000000, 0).drawRect(index ? left : - 800 - left, -1, 800, 0).endFill()
			contAthlete.addChild(forceWidth)
			
			let athlete = athletes[index]
			let picSprite = null, pic = pics[index]
			if(pic)
			{
				const pictexture = PIXI.Texture.from("/proxy?url="+pic.value)
				picSprite = new PIXI.Sprite(pictexture)
				picSprite.width = 200
				picSprite.height = 200
			}
			else
			{
				picSprite = new PIXI.Graphics()
				picSprite.beginFill(0x000000, 0.5).drawRoundedRect(0,0,200,200,0).endFill()
			}
			picSprite.x = index ? left : -(200+left)
			contAthlete.addChild(picSprite);

			let anchorX = index ? 0 : 1
			let side = index ? 1 : -1

			let country = athlete.country.toLowerCase()
			const flagTexture = PIXI.Texture.from("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/" + country + ".svg", {resourceOptions: { width: 240, height: 180 }})
			const flagSprite = new PIXI.Sprite(flagTexture)
			flagSprite.anchor.x = anchorX
			flagSprite.x = side * (left + 220)
			flagSprite.y = 10
			flagSprite.width = 90
			flagSprite.height = 3 * flagSprite.width / 4
			contAthlete.addChild(flagSprite);

			const firstText = new PIXI.Text(athlete.first.toUpperCase(), { fontFamily: 'Lato,sans-serif', fontWeight: 100, fontSize: 38, fill: textColor });
			firstText.anchor.x = anchorX
			firstText.x = side * (left + 320)
			firstText.y = 0
			contAthlete.addChild(firstText);
			const nameText = new PIXI.Text(athlete.last.toUpperCase(), { fontFamily: 'Avenir Bold,sans-serif', fontSize: 38, fill: textColor });
			nameText.anchor.x = anchorX
			nameText.x = side * (left + 320)
			nameText.y = 40
			contAthlete.addChild(nameText);

			const ptoTexture = PIXI.Texture.from("/assets/logo.svg", {resourceOptions: { width: 121, height: 52 }})
			const ptoSprite = new PIXI.Sprite(ptoTexture)
			ptoSprite.anchor.x = 0.5
			ptoSprite.anchor.y = 0.5
			ptoSprite.x = side * (left + 265)
			ptoSprite.y = 180
			ptoSprite.width = 121 * 0.5
			ptoSprite.height = 52 * 0.5
			ptoSprite.tint = textColor
			contAthlete.addChild(ptoSprite)

			let rankContainer = this.createRankContainer(rankings[index].rank, { color: textColor })
			rankContainer.x = side * (left + 265)
			rankContainer.y = 120
			rankContainer.pivot.x = rankContainer.width * 0.5
			rankContainer.pivot.y = rankContainer.height * 0.5
			contAthlete.addChild(rankContainer)

			let age = athlete.birthday ? moment().diff(moment.utc(athlete.birthday), 'years') : "-"
			const ageText = new PIXI.Text(age, { fontFamily: 'Avenir Bold,sans-serif', fontSize: 38, fill: textColor });
			ageText.anchor.set(0.5)
			ageText.setTransform(side * (left + 340), 120)
			contAthlete.addChild(ageText);

			let ageSubText = this.createSubText("AGE", side * (left + 340), 180, { color: textColor })
			ageSubText.anchor.set(0.5)
			contAthlete.addChild(ageSubText)

			if(typeComp != "scores")
			{
				let swim = this.createCircleScore(0x6CACE4, rankings[index].swim_score, null, { color: textColor })
				swim.pivot.x = swim.width * 0.5
				swim.pivot.y = swim.height * 0.5
				swim.scale.set(0.6)
				swim.y = 120
				swim.x = side * (left + 420)
				contAthlete.addChild(swim)

				let bike = this.createCircleScore(0xFFCC00, rankings[index].bike_score, null, { color: textColor })
				bike.pivot.x = bike.width * 0.5
				bike.pivot.y = bike.height * 0.5
				bike.scale.set(0.6)
				bike.y = 120
				bike.x = side * (left + 500)
				contAthlete.addChild(bike)

				let run = this.createCircleScore(0x009639, rankings[index].run_score, null, { color: textColor })
				run.pivot.x = run.width * 0.5
				run.pivot.y = run.height * 0.5
				run.scale.set(0.6)
				run.y = 120
				run.x = side * (left + 580)
				contAthlete.addChild(run)

				let overall = this.createCircleScore(0xD10B0C, rankings[index].overall_score, null, { color: textColor })
				overall.pivot.x = overall.width * 0.5
				overall.pivot.y = overall.height * 0.5
				overall.scale.set(0.6)
				overall.y = 120
				overall.x = side * (left + 660)
				contAthlete.addChild(overall)


				let swimSubText = this.createSubText("SWIM", side * (left + 420), 180, { color: textColor })
				let bikeSubText = this.createSubText("BIKE", side * (left + 500), 180, { color: textColor })
				let runSubText = this.createSubText("RUN", side * (left + 580), 180, { color: textColor })
				let overallSubText = this.createSubText("TRI", side * (left + 660), 180, { color: textColor })
				contAthlete.addChild(swimSubText, bikeSubText, runSubText, overallSubText)
			}

			this.addBackground(contAthlete, { color: index ? 0x000000 : 0x000000, alpha: 0.85, margin: 20 })
		}

		const vsCircle = new PIXI.Graphics()
		vsCircle.y = 100 
		vsCircle.beginFill(0xD10B0C, 1).drawCircle(0,0,50).endFill()
		container.addChild(vsCircle);

		const vsText = new PIXI.Text("VS", { fontFamily: 'Avenir Next LT W04 Heavy Cn It,sans-serif', fontSize: 52,fill: 0xffffff });
		vsText.anchor.set(0.5)
		vsText.y = 100 
		container.addChild(vsText);

		let typeTitle = ""
		switch(typeComp)
		{
			case "swim": typeTitle = "SWIM TIMES"; break;
			case "bike": typeTitle = "BIKE TIMES"; break;
			case "run": typeTitle = "RUN TIMES"; break;
			case "overall": typeTitle = "OVERALL TIMES"; break;
			case "scores": typeTitle = "PTO SCORES"; break;
		}

		const contTitle = new PIXI.Container()
		contTitle.y = 240
		container.addChild(contTitle)

		const titleText = new PIXI.Text(typeTitle, { fontFamily: 'Avenir Bold,sans-serif', fontSize: 52, fill: 0xffffff });
		titleText.anchor.set(0.5, 0)
		contTitle.addChild(titleText)

		let contResults = [titleText]

		let contData = new PIXI.Container()
		contData.y = 375
		container.addChild(contData)

		if(typeComp == "scores")
		{
			let startY = 25
			for(let index = 0; index < 4; index++)
			{
				let r = rankings[index]
				let left = null, right = null, scoreLeft = 0, scoreRight = 0, title = null
				switch(index)
				{
				case 0:
					title = "Swim"
					scoreLeft = rankings[0].swim_score
					scoreRight = rankings[1].swim_score
					left = this.createCircleScore(0x6CACE4, scoreLeft)
					right = this.createCircleScore(0x6CACE4, scoreRight)
					break
				case 1:
					title = "Bike"
					scoreLeft = rankings[0].bike_score
					scoreRight = rankings[1].bike_score
					left = this.createCircleScore(0xFFCC00, scoreLeft)
					right = this.createCircleScore(0xFFCC00, scoreRight)
					break
				case 2:
					title = "Run"
					scoreLeft = rankings[0].run_score
					scoreRight = rankings[1].run_score
					left = this.createCircleScore(0x009639, scoreLeft)
					right = this.createCircleScore(0x009639, scoreRight)
					break
				case 3:
					title = "Overall"
					scoreLeft = rankings[0].overall_score
					scoreRight = rankings[1].overall_score
					left = this.createCircleScore(0xD10B0C, scoreLeft)
					right = this.createCircleScore(0xD10B0C, scoreRight)
					break
				}

				left.y = right.y = startY
				left.pivot.x = left.width * 0.5
				right.pivot.x = right.width * 0.5
				left.pivot.y = left.height * 0.5
				right.pivot.y = right.height * 0.5

				left.x = -350
				right.x = 350

				const containerResults = new PIXI.Container()
				contData.addChild(containerResults)

				contResults.push(containerResults)

				const titleText = new PIXI.Text(title.toUpperCase(), { fontFamily: 'Lato,sans-serif', fontSize: 38, fill: '#ffffff' });
				titleText.anchor.set(0.5)
				titleText.setTransform(0, startY)

				containerResults.addChild(left, right, titleText)
				
				startY += 120	
			}
		}
		else
		{
			let startY = 0
			let count_races = 0
			for(let race1 of this.selected_athletes_data[0].results)
			{
				let race2 = this.selected_athletes_data[1].results.find(x => x.race_id == race1.race_id)
				if(!race2) continue
				if(race1.rank == 99999 || race2.rank == 99999) continue

				let time1 = race1[typeComp]
				let time2 = race2[typeComp]

				if(!time1 || !time2) continue

				const containerResults = new PIXI.Container()
				contData.addChild(containerResults)

				contResults.push(containerResults)

				let contText1 = new PIXI.Container()
				let contText2 = new PIXI.Container()
				contText1.setTransform(-500, startY)
				contText2.setTransform(500, startY)
				containerResults.addChild(contText1)
				containerResults.addChild(contText2)

				const time1Text = new PIXI.Text(secondsToTime(time1), { fontFamily: 'Avenir Bold,sans-serif', fontSize: 38, fill: 0xffffff });
				const time2Text = new PIXI.Text(secondsToTime(time2), { fontFamily: 'Avenir Bold,sans-serif', fontSize: 38, fill: 0xffffff });

				contText1.addChild(time1Text)
				contText2.addChild(time2Text)

				let fastestRect = new PIXI.Graphics()
				if(time1 < time2) contText1.addChildAt(fastestRect, 0)
				else contText2.addChildAt(fastestRect, 0)

				contText1.pivot.x = contText1.width * 0.5
				contText1.pivot.y = contText1.height * 0.5
				contText2.pivot.x = contText2.width * 0.5
				contText2.pivot.y = contText2.height * 0.5
				
				fastestRect.beginFill(0xD10B0C, 1).drawRoundedRect(-10, -6, (time1 < time2 ? contText1.width : contText2.width) + 20, (time1 < time2 ? contText1.height : contText2.height) + 12, 0).endFill()

				const raceText = new PIXI.Text(race1.serie + " " + race1.year, { fontFamily: 'Lato', fontSize: 38, fill: '#ffffff' });
				raceText.anchor.set(0.5)
				raceText.setTransform(0, startY)
				containerResults.addChild(raceText)

				startY += 80
				count_races += 1
				if(count_races >= 5) break
			}
		}

		this.addBackground(contTitle, { color: 0x000000, alpha: 0.85, margin: 10, x: -container.width * 0.5 + 10, width: container.width - 20 })
		this.addBackground(contData, { color: 0x000000, alpha: 0.85, margin: 20, x: -container.width * 0.5 + 20, width: container.width - 40 })

		container.pivot.y = container.height * 0.5
		container.x = this.app.screen.width * 0.5
		container.y = this.app.screen.height * 0.5

		/*backgroundRect
		.beginFill(0x101010, 0.95)
		//.drawRoundedRect(-800, -40, 800*2, container.height + 80, 0)
		.drawRoundedRect(-800, -20, 800*2, 240, 0)
		.endFill()
		.beginFill(0x101010, 0.75)
		.drawRoundedRect(-800, 220, 800*2, container.height - 200, 0)
		.endFill()*/

		const ptoFooterTexture = PIXI.Texture.from("/assets/logo-long.png")
		const ptoFooterSprite = new PIXI.Sprite(ptoFooterTexture)
		ptoFooterSprite.anchor.x = 0.5
		ptoFooterSprite.scale.set(0.4)
		ptoFooterSprite.x = 0
		ptoFooterSprite.y = container.height
		ptoFooterSprite.tint = 0xffffff
		container.addChild(ptoFooterSprite);


		this.current_timeline = gsap.timeline()
		this.current_timeline
		.from(backgroundRect, { pixi: { alpha: 0, width: "-=1200" }, duration: 0.5 })
		.from(container, { pixi: { alpha: 0 }, duration: 0.5 }, 0)
		.from(contResults, { pixi: { alpha: 0, y: "+=40" }, duration: 0.25, stagger: 0.1 }, 0)
		.addPause()
		.to(container, { pixi: { alpha: 0 }, duration: 0.5 })
	} 

	drawAthlete(options) 
	{
		let athlete = this.athlete_data.athlete
		let pic = this.athlete_data.attributes.find(x => x.name == "pic")
		let rankings = this.athlete_data.rankings[0]

		let stage = this.app.stage

		stage.removeChildren()
		const container = new PIXI.Container();
		stage.addChild(container);
		let backgroundRect = new PIXI.Graphics()
		container.addChild(backgroundRect)

		const containerTop = new PIXI.Container();
		container.addChild(containerTop)

		let picSprite = null
		if(pic)
		{
			const pictexture = PIXI.Texture.from("/proxy?url="+pic.value)
			picSprite = new PIXI.Sprite(pictexture)
			picSprite.width = 200
			picSprite.height = 200
		}
		else
		{
			picSprite = new PIXI.Graphics()
			picSprite.beginFill(0x000000, 0.5).drawRoundedRect(0,0,200,200,0).endFill()
		}
		containerTop.addChild(picSprite);


		const containerAthleteInfo = new PIXI.Container();
		containerTop.addChild(containerAthleteInfo)

		let country = athlete.country.toLowerCase()
		const flagTexture = PIXI.Texture.from("https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.1.0/flags/4x3/" + country + ".svg", {resourceOptions: { width: 240, height: 180 }})
		const flagSprite = new PIXI.Sprite(flagTexture)
		flagSprite.x = 220
		flagSprite.y = 10
		flagSprite.width = 90
		flagSprite.height = 3 * flagSprite.width / 4
		containerAthleteInfo.addChild(flagSprite);

		const firstText = new PIXI.Text(athlete.first.toUpperCase(), { fontFamily: 'Lato', fontWeight: 100, fontSize: 38, fill: '#ffffff' });
		firstText.x = 320
		firstText.y = 0
		containerAthleteInfo.addChild(firstText);
		const nameText = new PIXI.Text(athlete.last.toUpperCase(), { fontFamily: 'Avenir Bold,sans-serif', fontSize: 38, fill: '#ffffff' });
		nameText.x = 320
		nameText.y = 40
		containerAthleteInfo.addChild(nameText);


		let rankContainer = this.createRankContainer(rankings.rank)
		rankContainer.x = 265
		rankContainer.y = 120
		rankContainer.pivot.x = rankContainer.width * 0.5
		rankContainer.pivot.y = rankContainer.height * 0.5
		containerAthleteInfo.addChild(rankContainer)

		const ptoTexture = PIXI.Texture.from("/assets/logo.svg", {resourceOptions: { width: 121, height: 52 }})
		const ptoSprite = new PIXI.Sprite(ptoTexture)
		ptoSprite.anchor.y = 0.5
		ptoSprite.x = 320
		ptoSprite.y = 120
		ptoSprite.width = 121 * 0.75
		ptoSprite.height = 52 * 0.75
		ptoSprite.tint = 0xc0c0c0
		containerAthleteInfo.addChild(ptoSprite);


		let age = athlete.birthday ? moment().diff(moment.utc(athlete.birthday), 'years') : "-"
		const ageText = new PIXI.Text(age, { fontFamily: 'Avenir Bold,sans-serif', fontSize: 48, fill: 0xffffff });
		ageText.anchor.set(0.5)
		ageText.setTransform(265, 180)
		containerAthleteInfo.addChild(ageText);

		let ageSubText = this.createSubText("AGE", 320, 180)
		ageSubText.anchor.x = 0
		containerAthleteInfo.addChild(ageSubText)

		containerTop.pivot.x = containerTop.width * 0.5;

		const containerCircles = new PIXI.Container();

		let swim = this.createCircleScore(0x6CACE4, rankings.swim_score, "Swim").setTransform(0, 0)
		let bike = this.createCircleScore(0xFFCC00, rankings.bike_score, "Bike").setTransform(140, 0)
		let run = this.createCircleScore(0x009639, rankings.run_score, "Run").setTransform(280, 0)
		let overall = this.createCircleScore(0xD10B0C, rankings.overall_score, "Overall").setTransform(420, 0)

		containerCircles.addChild(swim);
		containerCircles.addChild(bike);
		containerCircles.addChild(run);
		containerCircles.addChild(overall);

		containerCircles.pivot.x = containerCircles.width * 0.5
		containerCircles.x = 0 //container.width * 0.5
		containerCircles.y = 230
		container.addChild(containerCircles);

		//container.pivot.x = container.width * 0.5;
		container.pivot.y = container.height * 0.5;
		container.x = this.app.screen.width * 0.5
		container.y = this.app.screen.height * 0.5

		backgroundRect.beginFill(0x101010, 0.95).drawRoundedRect(-container.width * 0.5 - 40, -40, container.width + 80, container.height + 80, 0).endFill()

		this.current_timeline = gsap.timeline()
		
		this.current_timeline
		.from(backgroundRect, { pixi: { alpha: 0, width: "-=400" }, duration: 0.5 })
		.addLabel("inside", 0.25)
		.from(flagSprite, { pixi: { alpha: 0, x: "-=20" }, duration: 0.5 }, "inside")
		.from([firstText, nameText], { pixi: { alpha: 0, x: "+=20" }, duration: 0.5, stagger: 0.25 }, "inside")
		.from(picSprite, { pixi: { alpha: 0, x: "-=20" }, duration: 0.5 }, "inside")
		.from([rankContainer, ageText], { pixi: { alpha: 0, x: "+=20" }, duration: 0.5, stagger: 0.25 }, "inside+=0.1")
		.from([ptoSprite, ageSubText], { pixi: { alpha: 0, x: "+=20" }, duration: 0.5, stagger: 0.25 }, "inside+=0.1")
		//.from([ageSubText], { pixi: { alpha: 0, x: "+=20" }, duration: 0.5, stagger: 0.1 }, "inside+=0.2")
		//.from([ageText], { pixi: { alpha: 0, x: "-=20" }, duration: 0.5 }, "inside+=0.2")
		.from([swim, bike, run, overall], { pixi: { alpha: 0, x: "+=20" }, duration: 0.5, stagger: 0.1 }, "inside+=0.3")
		.addPause()
		.to(container, { pixi: { alpha: 0 }, duration: 0.5 })


		//container.setTransform()

	}

	loadAthlete(id)
	{
		let that = this
		fetch("/api/athlete/" + id, {
			headers: { 'Content-Type': 'application/json' },
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			that.athlete_data = data			
			that.queueDrawAthlete()
		})
	}

	capture = () => {
		let svgElement = this.domSvgRef

		let c = document.createElement('canvas')
		c.width = 1920;
		c.height = 1080;
		c.getContext('2d').drawImage(svgElement, 0, 0, svgElement.width, svgElement.height, 0, 0, c.width, c.height);

		let png = c.toDataURL();
		let link = document.createElement('a');
		link.download = 'screenshot.png';
		link.href = png;
		link.click();
	}

	athleteOnChange = e => {
		let index = e.target.dataset.index
		let athlete = this.athletes.find(x => x.name == e.target.value)
		if(athlete) 
		{
			e.target.value = ""
			this.selected_athletes[index] = athlete

			let that = this
			fetch("/api/athlete/" + athlete.id, {
				headers: { 'Content-Type': 'application/json' },
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) {
				that.selected_athletes_data[index] = data			
				that.cacheAthlete(data)
				that.setState({})
				//that.queueDrawAthlete()
			})


		}
	}

	showAthlete = e => {
		let index = e.target.dataset.index
		let athlete = this.selected_athletes[index]
		if(!athlete) return
		this.loadAthlete(athlete.id)
	}

	showVS = e => {
		this.queueDrawVS()
	}

	domsvg = (d) => {
		this.domSvgRef = d

		PIXI.GRAPHICS_CURVES.adaptive = false
		this.app = new PIXI.Application({ 
			width: 1920, 
			height: 1080, 
			antialias: true, 
			view: d, 
			resolution: 2, //window.devicePixelRatio, 
			transparent: true,
			//backgroundColor: 0x000000, 
			preserveDrawingBuffer: true 
		});

		this.app.loader
		.add("logo", "/assets/logo.svg")
		.add("logo_long", "/assets/logo-long.png")
		//.load((loader, res) => {})

		this.loadAthleteList()

		const tempText = new PIXI.Text("", { fontFamily: 'Avenir Bold,sans-serif' });
		const temp2Text = new PIXI.Text("", { fontFamily: 'Lato,sans-serif' });
		const temp3Text = new PIXI.Text("", { fontFamily: 'Lato,sans-serif', fontWeight: 'bold' });
		const temp4Text = new PIXI.Text("", { fontFamily: 'Lato,sans-serif', fontWeight: 100 });
		const temp5Text = new PIXI.Text("", { fontFamily: 'Avenir Next LT W04 Heavy Cn It,sans-serif' });

		this.app.stage.addChild(tempText, temp2Text, temp3Text, temp4Text, temp5Text);

		//this.svg = SVG().addTo(d).size(1920, 1080)
		if(!d) return
	}

	player = (d) =>	{
	}

	render(props, state) {
		let a1 = this.selected_athletes[0]
		let a2 = this.selected_athletes[1]

		let scores = [], races = []
		if(a1 && a2 && this.selected_athletes_data[0] && this.selected_athletes_data[1])
		{
			scores.push({ title: "Swim", left: a1.swim_score, right: a2.swim_score, unit: "%" })
			scores.push({ title: "Bike", left: a1.bike_score, right: a2.bike_score, unit: "%" })
			scores.push({ title: "Run", left: a1.run_score, right: a2.run_score, unit: "%" })
			scores.push({ title: "Overall", left: a1.overall_score, right: a2.overall_score, unit: "%" })

			let typeComp = state.vsType

			if(typeComp != "scores")
			{
				for(let race1 of this.selected_athletes_data[0].results)
				{
					let race2 = this.selected_athletes_data[1].results.find(x => x.race_id == race1.race_id)
					if(!race2) continue
					if(race1.rank == 99999 || race2.rank == 99999) continue
					let time1 = race1[typeComp]
					let time2 = race2[typeComp]
					if(!time1 || !time2) continue

					races.push({ title: race1.serie + " " + race1.year, left: time1, right: time2 })
					if(races.length >= 5) break
				}
		}
		}

		return(
			<div class='d-flex'>
				<div class='d-flex flex-column align-items-center my-2 mx-2' style='width: 350px; min-width: 350px;'>
					<div class='d-flex row w-100'>
						<div class='d-flex flex-column align-items-center col-6'>
							<div>Athlete 1</div>
							<input type='text' list="athletes" autocomplete="off" class='form-control form-control-sm' data-index="0" onChange={this.athleteOnChange}/>
							<datalist id="athletes">
								{this.athletes.map(x => {
									return <option value={x.name}/>
								})}
							</datalist>
							{ this.selected_athletes[0] && 
								<div class='d-flex flex-column align-items-center mt-2'>
									<div class='h5' style='height: 2.5em; line-height: 1.25em;'>
										<div class='mb-0 text-center'>{this.selected_athletes[0].name}</div>
									</div>
									<button class='btn btn-sm btn-secondary' data-index="0" onClick={this.showAthlete}>Show</button>
								</div>
							}
						</div>
						<div class='d-flex flex-column align-items-center col-6'>
							<div>Athlete 2</div>
							<input type='text' list="athletes" autocomplete="off" class='form-control form-control-sm' data-index="1" onChange={this.athleteOnChange}/>
							<datalist id="athletes">
								{this.athletes.map(x => {
									return <option value={x.name}/>
								})}
							</datalist>
							{ this.selected_athletes[1] && 
								<div class='d-flex flex-column align-items-center mt-2'>
									<div class='h5' style='height: 2.5em; line-height: 1.25em;'>
										<div class='mb-0 text-center'>{this.selected_athletes[1].name}</div>
									</div>
									<button class='btn btn-sm btn-secondary' data-index="1" onClick={this.showAthlete}>Show</button>
								</div>
							}
						</div>
					</div>
					{ a1 && a2 &&
						<div class='mt-3 py-2 d-flex border-top border-dark w-100 row text-center compare-table'>
							{ scores.map(s => {
								return (
									<div class='row w-100'>
										<div class='col-3'><div class='my-1' better={s.left > s.right}>{s.left+s.unit}</div></div>
										<div class='col-6 my-1'>{s.title}</div>
										<div class='col-3'><div class='my-1' better={s.left < s.right}>{s.right+s.unit}</div></div>
									</div>
								)							
							})}
						</div>
					}
					{ this.selected_athletes[0] && this.selected_athletes[1] &&
						<Fragment>
							<div class='mt-1 pt-3 d-flex border-top border-dark w-100 row'>
								<div class='radio-buttons col-12 d-flex justify-content-center'>
									{[{ label: "PTO Scores", value: "scores" },
										{ label: "Swim", value: "swim" },
										{ label: "Bike", value: "bike" },
										{ label: "Run", value: "run" },
										{ label: "Overall", value: "overall" },
									].map(r => {
										return(
											<input type="radio" name="vs-type" value={r.value} autocomplete="off" checked={r.value == state.vsType} label={r.label} onChange={() => this.setState({vsType: r.value})}/>
										)
									})
									}
								</div>
							</div>
							<div class='py-2 d-flex border-bottom border-dark w-100 row text-center compare-table'>
								{ races.map(r => {
									return (
										<div class='row w-100'>
											<div class='col-3'><div class='my-1' better={r.left < r.right}>{secondsToTime(r.left)}</div></div>
											<div class='col-6 my-1'>{r.title}</div>
											<div class='col-3'><div class='my-1' better={r.left > r.right}>{secondsToTime(r.right)}</div></div>
										</div>
									)							
								})}

								<button class='btn btn-sm btn-secondary col-6 offset-3 mt-1' data-index="0" onClick={this.showVS}>Show Versus</button>
							</div>
						</Fragment>
					}
					<div class='mt-4'></div>
					<div class='mt-4'>
						<button class='btn btn-sm btn-secondary ml-2' onClick={this.hide}>Clear</button>
					</div>
					<div class='mt-4'>
						<button class='btn btn-sm btn-secondary ml-2' onClick={this.playVideo}>Play/Clear Video</button>
						<button class='btn btn-sm btn-secondary ml-2' onClick={this.capture}>Save Image</button>
					</div>
				</div>
				<div class='position-relative dlex-shrink' >
					<img src='/assets/daytona2.jpg' class='position-absolute' style="width: 100%; top: 0; left: 0; z-index: -1;"/>
					<div ref={this.div_player} id="yt-player"></div>
					<canvas width="1920" height="1080" ref={this.domsvg} style='width: 100%;'></canvas>
				</div>

			</div>
			
		)
	}
}
if(broadcast) render(<Broadcast />, broadcast);
