import { h, render, Component, createRef, Fragment } from 'preact'

export class TermsConditionsContent extends Component {
	render(props, state)
	{
		let type = props.type
		
		return (
			<div class='terms-content'>
				<h5><strong>Intro</strong></h5>
				<p></p>
				<p>Our Terms and Conditions surrounding the &nbsp;PTO and all owned and operated events are aimed to maximise distribution whilst ensuring that the commercial opportunities flow through the PTO with the intention of benefiting the sport of Triathlon, the Professional Triathletes as well as those content creators contributing to the sport.</p>
				<p><br/></p>
				<p>As demonstrated in the below we grant all third parties free editorial use and with regards to commercial use of the content we will &nbsp; outlined here&nbsp;</p>
				<p><br/></p>
				<p>These terms&nbsp;and conditions set out the agreement between you and PTO Commercial Limited incorporated&nbsp;and registered in England and Wales with company number&nbsp;12477232 whose&nbsp;registered office is at B24 Lighterman Towers, Harbour Avenue, London, United&nbsp;Kingdom, SW10 0HQ (“PTO”) that explains&nbsp;how you can use photos, video&nbsp;clips and other content (individually and&nbsp;collectively, “content”) which you access&nbsp;and license from the PTO via the PTO Media Portal website (the “Media Portal”).&nbsp;</p>
				<p><br/></p>
				<p>The Professional Triathletes Organisation (PTO) reserves &nbsp;all rights to The Collins Cup, all the content is therefore owned by the PTO. &nbsp;PTO Own all rights in perpetuity to all&nbsp;images,&nbsp;video, recordings and any manner of content recorded by anyone granted access to The Collins Cup.&nbsp;All rights not expressly granted in this agreement are fully reserved by the PTO.</p>
				<p><br/>As it regards to any and all third parties, PTO retain all rights to all content taken at PTO owned or operated events non exclusively and in perpetuity.&nbsp;Any third party commercial use of such content will be approved by PTO.&nbsp;<br/><br/>Any content provided by the PTO is for editorial use only. Any party wishing to use the PTO content for commercial purposes needs to notify PTO in advance. This applies to any&nbsp;photographers/film crews or persons attending and or as part of an athlete camp or solo freelancers.<br/><br/>Any third party commercial use of photography/video/content related to will be approved by PTO</p>
				<p>PTO are to be notified of any commercial opportunity relating to the content which, in the instance it generates revenue and at PTO’s sole discretion, PTO would look to share the revenue&nbsp;generated 50/50 with the content creator.<br/><br/>Any third party looking to capture and distribute content related to PTO owned and operated events must register with PTO and deliver all content to PTO highest resolution and raw within&nbsp;7 days of the event and or upon request. PTO, at their sole discretion, will choose to tag or not tag photographers / organisations in communications using images. All distribution of photos shall be handled either directly through PTO or approval of PTO. All images provided should have minimum meta tagging of Athlete Name, Event, Date<br/><br/>These conditions are subject to change and relate to all future PTO owned or operated event by&nbsp;agreeing to the Terms and Conditions&nbsp;on this website you are&nbsp;bound by and agree to the relevant Terms and Conditions of the PTO.&nbsp;These terms and conditions are subject to change without notice, from time to time at our&nbsp;sole discretion. We will notify you of amendments to these terms and conditions by posting them to this website.&nbsp;<br/><br/></p>
				<p>These terms apply to any photographers/film crews attending with as part of an athlete camp or solo freelancers.</p>
				<p><br/>No use of drones for the purpose of photo/video capture or any other purpose shall be allowed by any persons while on site at the X-Bionic Sphere during The Collins Cup event week (23/08/21-28/08/21) unless expressly allowed by the Professional Triathletes Organisation.</p>
				<p><br/>PTO at their sole discretion reserve the right to immediately request removal of images relating to and not limited to the PTO, their owned and operated events, athletes, staff and any&nbsp;related third parties that may relate to them.</p>
				<p><br/>If you are using content for editorial purposes, you must include a credit adjacent to the content or in production credits. The credit should be in the following form or as otherwise stipulated&nbsp;in the caption information accompanying the content on the Media Portal: “Image courtesy of PTO”.<br/><br/>If licensed&nbsp;content is used in an audio/visual production where credits are accorded to&nbsp;other providers of licensed material, you must include a credit in comparable&nbsp;size and&nbsp;placement.&nbsp;The credit should be in the following form or as otherwise&nbsp;stipulated in the caption information accompanying the content on the Media Portal:&nbsp;“Video courtesy of&nbsp;PTO”.<br/><br/>Where you have the rights to post across social media channels and where relevant third party must tag PTO account &nbsp;and credit ‘image courtesy of PTO’&nbsp;</p>
				<p><br/></p>
				<p>You may use the name of the PTO as necessary to give attribution.</p>
				<p>We asserts our moral right to be identified as the author of any intellectual property rights created and/or granted by the PTO to you under the terms and conditions of this agreement.</p>
				<p>This applies to any and all photographers/film crews attending with as part of an athlete camp or solo freelancers.</p>
				<p><br/></p>
				<p>The&nbsp;following are prohibited without the prior written consent of the PTO, to be&nbsp;determined in its discretion, and/or payment of a licence fee to the PTO,&nbsp;namely:</p>
				<p><br/></p>
				<p>No “On Demand” Products.&nbsp;You may not use content in&nbsp;connection with "on demand" products (e.g. products in which a&nbsp;licensed image is selected by a third party for&nbsp;customisation of such product&nbsp;on a made-to-order basis), including, without limitation, postcards, mugs,&nbsp;t-shirts, calendars, posters, screensavers or wallpapers on mobile&nbsp;telephones,&nbsp;or similar items (this includes the sale of products through custom designed&nbsp;websites).</p>
				<p><br/></p>
				<p>No Electronic Templates.&nbsp;You may not use content in&nbsp;electronic or digital templates intended for resale or other distribution (for&nbsp;example, website templates, business card&nbsp;templates, electronic greeting card&nbsp;templates, and brochure design templates).</p>
				<p><br/></p>
				<p>No Use in Trademark or Logo.&nbsp;You may not use content (in whole or&nbsp;in part) as the distinctive or distinguishing feature of a trade mark, design&nbsp;mark, trade name, business&nbsp;name, service mark or logo. Additionally, you shall&nbsp;not be entitled to register (in any jurisdiction) such content (in whole or in&nbsp;part) as a trade mark or rely on any such&nbsp;registrations, prior use, and/or&nbsp;accrued goodwill to prevent any third party use of the content or any similar&nbsp;content (including by us, our partners, or the copyright owner of&nbsp;such&nbsp;content).</p>
				<p><br/></p>
				<h5><strong>Third Party Partners (Brands/Sponsors)</strong></h5>
				<p><br/>Partners should not contact photographers directly for any images as these are owned in perpetuity by the PTO<br/><br/>If a partner wishes to bring any additional photographers / film crews, they must get prior approval by PTO’s Partnerships team. This applies to any photographers/film crews attending with&nbsp;an athlete.<br/><br/>Any photographers / film crews appointed directly by a partner must receive accreditation approval before attending an event<br/><br/>If a partner has specific requests they must submit these to PTO’s Partnership’s Team 14 days prior to the eventImages shared on the media portal can be used by partners on their own&nbsp;social channels without any prior approval.</p>
				<p>Any&nbsp;communications should be directed to Director of Partnerships&nbsp;<a href="mailto:lucy.fitzgerald@protriathletes.org" data-mce-href="mailto:lucy.fitzgerald@protriathletes.org">Lucy Fitzgerald</a><br/><br/></p>
				<p>All media, those relating to press and mainstream editorial and otherwise coverage<br/><br/>During The Collins Cup event week (23/08/20 - 28/08/20) all media and press (accredited and unaccredited) should arrange all athlete media requests through the PTO (<a href="mailto:jane.hansom@protriathletes.org" data-mce-href="mailto:jane.hansom@protriathletes.org">Jane Hansom</a>, or&nbsp;<a href="mailto:jamie.dellimore@protriathletes.org" data-mce-href="mailto:jamie.dellimore@protriathletes.org">Jamie Dellimore</a>)&nbsp;and not directly through the athlete or manager.<br/><br/>PTO reserve the right at anytime for any reason to remove accreditation/rights to access PTO events should they see fit.&nbsp;<br/><br/>If PTO feel, for any reason, the terms have been breached they reserve the right to immediately request&nbsp;removal of images.</p>
				<p><br/></p>
				<p>Following The Collins Cup event week (23/08 - 28/08/20) it is requested that all media (accredited and&nbsp;non accredited, on site and virtual) submit a Collins Cup coverage report for your title/outlet to&nbsp;<a href="mailto:communications@protriathletes.org" data-mce-href="mailto:communications@protriathletes.org">communications@protriathletes.org</a>&nbsp;in spreadsheet form detailing each piece of coverage (article &amp;&nbsp;social media) published, date published, performance/stats (if available), link to coverage.&nbsp;</p>
				<p><br/></p>
				<h5><strong>General Provisions.</strong></h5>
				<p><u>User Accounts</u></p>
				<p>You will be&nbsp;responsible for tracking all activity for your user account, and you agree to:</p>
				<p>maintain the security of the password&nbsp;and username; notify the PTO immediately of any&nbsp;unauthorised use or other breach of security; and&nbsp;accept all responsibility for&nbsp;activity that occurs under your user account.</p>
				<p><br/>The PTO&nbsp;reserves the right to monitor downloads and user activity to ensure compliance&nbsp;with the terms of this agreement. If the PTO determines that you are in breach&nbsp;of this or any other&nbsp;term of this agreement, it may immediately suspend access&nbsp;to your account and seek further legal remedies.</p>
				<p><u>Termination.</u> The PTO may terminate this agreement at any time if you breach any of the terms of this or any other agreement with the PTO, in which case you must immediately: cease using the content;&nbsp;you&nbsp;agree to remove any content from such platform or website&nbsp;and, if requested at any time after the termination of this agreement,&nbsp;delete or destroy any copies of the content and&nbsp;&nbsp;confirm to the PTO in writing that you have complied with these requirements.</p>
				<p><u>Content Withdrawal.</u> The PTO may discontinue licensing any item of content at any time in its sole discretion. On notice from the PTO, or upon your knowledge, that any content may be subject to a claim of infringement of a third party’s right for which the PTO may be liable, the PTO may require you to immediately, and at your own expense: cease using the content, delete or destroy any copies; and ensure that your clients, distributors and/or employer do likewise.&nbsp;</p>
				<p><u>Confirmation of Non-Infringement.</u> For all licensed content, the PTO confirms that your use of content in accordance with this agreement and in the form delivered by the PTO (that is, excluding any modifications, overlays or refocusing done by you) will not infringe on any copyrights or moral rights of the content owner/creator.</p>
				<p><u>Disclaimer.</u> Unless specifically confirmed above, the PTO does not grant any right or make any warranty with regard to the use of names, people, trade marks, trade dress, logos, registered, unregistered or copyrighted audio, designs, works of art or architecture depicted or contained in the content. In such cases, you are solely responsible for determining whether release(s) is/are required in connection with your proposed use of the content, and you are solely responsible for obtaining such release(s). You acknowledge that no releases are generally obtained for content identified as “editorial,” and that some jurisdictions provide legal protection against a person’s image, likeness or property being used for commercial purposes when they have not provided a release. You are also solely responsible for payment of any amounts that may be due under, and in compliance with any other terms of, any applicable collective bargaining agreements as a result of your use of the licensed content.</p>
				<p><u>Caption/Metadata Disclaimer.</u> While the PTO has made reasonable efforts to correctly categorise, keyword, caption and title the content, the PTO does not warrant the accuracy of such information, or of any metadata provided with the content.</p>
				<p><u>No Other Warranties.</u> Except as provided in this section above, the content is provided “as is” without representation, warranty or condition of any kind, either express or implied, including, but not limited to, implied representations, warranties or conditions of merchantability, or fitness for a particular purpose. The PTO does not represent or warrant that the content or the Media Portal will meet your requirements or that use of the content or the Media Portal will be uninterrupted or error free.</p>
				<p><u>Indemnification of the PTO by you.</u> You agree to defend, indemnify, and hold harmless the PTO and its parent, subsidiaries, affiliates, and content suppliers, and each of their respective officers, directors, and employees from all damages, liabilities, and expenses (including reasonable legal costs including attorney fees) arising out of or in connection with (i) your use of any content outside the scope of this agreement; (ii) any breach or alleged breach by you (or anyone acting on your behalf) of any of the terms of this or any other agreement with the PTO; and (iii) your failure to obtain any required release for your use of content.</p>
				<p><u>Indemnification of you by the PTO.</u> Provided that you are not in breach of this or any other agreement with the PTO, and as your sole and exclusive remedy for any breach of the warranties set forth in Section 7 above, the PTO agrees, subject to the terms of this Section 8, to defend, indemnify, and hold harmless you, your corporate parent, subsidiaries, and affiliates, and each of your respective officers, directors, and employees from all damages, liabilities, and expenses (including reasonable legal costs including attorney fees) arising out of or in connection with any breach or alleged breach by the PTO of its warranties in Section 7 above. This indemnification does not apply to the extent any damages, costs or losses arise out of or are a result of modifications made by you to the content or the context in which the content is used by you. This indemnification also does not apply to your continued use of content following notice from the PTO, or upon your knowledge, that the content is subject to a claim of infringement of a third party’s right.</p>
				<p>The party seeking indemnification must promptly notify in writing the other party about the claim. The indemnifying party (the one covering the costs) has the right to assume the handling, settlement, or defence of any claim or litigation. The indemnified party (the one not covering the costs) has to cooperate in any way reasonably requested by the indemnifying party. The indemnifying party will not be liable for legal costs including attorney fees and other costs incurred prior to the other party giving notice of the claim for which indemnity is sought.</p>
				<p><u>&nbsp;Limitation of Liability.</u></p>
				<p>Unless agreed otherwise in writing, the maximum liability of the PTO to you arising by reason of or in connection with the supply of the content and/or access to the Media Portal shall be limited to an amount of £100 (“Capped Amount”). The PTO shall not in any event be liable to you for: (i) any direct loss in excess of the Capped Amount; or (ii) any corruption to data or any indirect or consequential loss whatsoever even if you have been advised of or should reasonably know of the possibility of such loss. Nothing in this agreement shall exclude or limit the liability of PTO for death or personal injury due to negligence or for fraud. This does not affect your statutory rights.</p>
				<p>THE PTO WILL NOT BE LIABLE TO YOU OR ANY OTHER PERSON OR ENTITY FOR ANY LOST PROFITS, PUNITIVE, SPECIAL, INDIRECT, CONSEQUENTIAL, INCIDENTAL OR OTHER SIMILAR DAMAGES, COSTS OR LOSSES ARISING OUT OF THIS AGREEMENT, EVEN IF THE PTO HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, COSTS OR LOSSES.</p>
				<p>Data Protection.&nbsp;The PTO collect information about&nbsp;you when you enter the Media Portal. The PTO aim to collect the minimum&nbsp;personal information necessary to provide the&nbsp;Media Portal. Our&nbsp;Privacy Notice&nbsp;shall apply in addition to this agreement.</p>
				<p>Third Party Rights.&nbsp;This agreement shall not confer a benefit&nbsp;on any third party and the provisions of the Contracts (Rights of Third&nbsp;Parties) Act 1999 shall not apply to this&nbsp;agreement.</p>
				<p>Assignment.&nbsp;This agreement is personal to you&nbsp;and is not assignable by you without the PTO’s prior written consent to be&nbsp;determined in its discretion. The PTO may assign&nbsp;this agreement, without notice&nbsp;or consent, to any corporate affiliate or to any successor in interest.&nbsp;The rights granted to you are person, non-transferable and non-sub licensable, meaning that you cannot transfer or sublicense them to anyone else without the express written permission of the PTO in advance, to be determined in its discretion</p>
				<p>Electronic storage.&nbsp;You agree to retain the copyright&nbsp;symbol, the name of the PTO, the content’s identification number and any other&nbsp;information or metadata that may be&nbsp;embedded in the electronic file containing&nbsp;the original content, and to maintain appropriate security to protect the&nbsp;content from unauthorised use by third parties. You may&nbsp;make one (1) copy of&nbsp;the content for back-up purposes.</p>
				<p>Severability.&nbsp;If one or more of the provisions in&nbsp;this agreement is found to be invalid, illegal or unenforceable in any respect,&nbsp;the validity, legality and enforceability of the&nbsp;remaining provisions should&nbsp;not be affected. Such provisions should be revised only to the extent necessary&nbsp;to make them enforceable.</p>
				<p>Waiver.&nbsp;No action of either party, other&nbsp;than express written waiver, may be construed as a waiver of any provision of&nbsp;this agreement.</p>
				<p>Notice.&nbsp;All notices required to be sent to the&nbsp;PTO under this agreement should be sent via email to&nbsp;<a href="mailto:communications@protriathletes.org" data-mce-href="mailto:communications@protriathletes.org">communications@protriathletes.org</a>. All notices to you will be sent via&nbsp;email from the email set out in your account.</p>
				<p>Governing Law.&nbsp;This agreement shall be governed by&nbsp;and construed in accordance with the laws of the England. Any dispute arising&nbsp;under these terms shall be subject to&nbsp;the exclusive jurisdiction of the English&nbsp;courts.</p>
			</div>	
		)

		if(type == "media")
		{
			return (
				<div>Media</div>
			)
		}
		else if(type == "na_media")
		{
			return (
				<div>Media</div>
			)
		}
		else if(type == "partner")
		{
			return (
				<div>Partner</div>
			)
		}
	}
}
