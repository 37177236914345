import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime, getSeconds, AthletePic, ordinal_suffix_of } from "../server/components/utils"
import moment from 'moment';


export class Game_Play extends Component {
	componentWillMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		const play_fetch = await fetch(`/api/fantasy/${this.props.game_id}/play/${this.props.player_id}`)
		const play_json = await play_fetch.json()

		if(this.props.game_id == 1)
		{
			let info_req = await fetch("/api/fantasy/cc_info")
			let info_json = await info_req.json()
			this.teams = info_json.teams
			this.athletes = info_json.athletes
			this.athletes_ids = info_json.athletes_ids
			this.race_results = info_json.results

			if(play_json.play)
			{
				let data = JSON.parse(play_json.play.data)
				this.result = data
				this.setState({})
			}
		}
		if(this.props.game_id == 2)
		{
			if(play_json.play)
			{
				let data = JSON.parse(play_json.play.data)
				this.result = data.athletes
				this.setState({})
			}
		}
		else if(this.props.game_id == 3)
		{
			let game_req = await fetch("/api/fantasy/games/" + this.props.game_id)
			let game_json = await game_req.json()
			this.game_data = JSON.parse(game_json.game.data)
			this.game_results = JSON.parse(game_json.game.results)
			//console.log(this.game_results)

			if(play_json.play)
			{
				let data = JSON.parse(play_json.play.data)
				this.result = data
				this.setState({})
			}
		}
	}
	render(props, state)
	{
		if(!this.result) return

		if(props.game_id == 1)
		{
			return(
				<div class='game-play'>
					<div class='py-2 d-flex flex-wrap'>
						{[0,1,2,3,4,5,6,7,8,9,10,11].map(index => {
							let race = this.result.orders[index]
							let race_results = this.race_results[index]
							return (
								<div class='p-2 m-2' style='flex: 1 0 150px; border: 1px solid #ccc; border-radius: 8px;'>
									<div><b class='text-grey'>Match {index+1}</b></div>
									<table>
										{ race && race.map((athlete_id, index) => {
											if(athlete_id == 3300) athlete_id = 5236
											let athlete = this.athletes.find(x => x.id == athlete_id)
											let style = "font-weight:" + (race_results[index].id == athlete_id ? "bold" : "unset")
											return(<tr style={style}><td class='pr-4'>{athlete.name}</td><td>{race_results[index].id == athlete_id && race_results[index].score}</td></tr>)							 	
										})}
									</table>
								</div>
							)
						})}
					</div>
				</div>
			)

		}		
		else if(props.game_id == 2)
		{
			return(
				<div class='game-play'>
					<div class='py-2'>
						<table class='table table-results'>
							<tr>
								<th></th>
								<th>SWIM</th>
								<th>T1</th>
								<th>BIKE</th>
								<th>T2</th>
								<th>RUN</th>
								<th>FINISH</th>
							</tr>
							<tr>
								<th><b>FRODENO</b></th>
								<td>{this.result[0].times[0] != 0 && secondsToTime(this.result[0].times[0])}</td>
								<td>{this.result[0].times[1] != 0 && secondsToTime(this.result[0].times[1])}</td>
								<td>{this.result[0].times[2] != 0 && secondsToTime(this.result[0].times[2])}</td>
								<td>{this.result[0].times[3] != 0 && secondsToTime(this.result[0].times[3])}</td>
								<td>{this.result[0].times[4] != 0 && secondsToTime(this.result[0].times[4])}</td>
								<td>{this.result[0].times[5] != 0 && secondsToTime(this.result[0].times[5])}</td>
							</tr>
							<tr>
								<th><b>SANDERS</b></th>
								<td>{this.result[1].times[0] != 0 && secondsToTime(this.result[1].times[0])}</td>
								<td>{this.result[1].times[1] != 0 && secondsToTime(this.result[1].times[1])}</td>
								<td>{this.result[1].times[2] != 0 && secondsToTime(this.result[1].times[2])}</td>
								<td>{this.result[1].times[3] != 0 && secondsToTime(this.result[1].times[3])}</td>
								<td>{this.result[1].times[4] != 0 && secondsToTime(this.result[1].times[4])}</td>
								<td>{this.result[1].times[5] != 0 && secondsToTime(this.result[1].times[5])}</td>
							</tr>
						</table>
					</div>
				</div>
			)
		}
		else if(props.game_id == 3)
		{
			return(
				<div class='game-play'>
					<div class='py-2'>
						<table class='table table-results'>
							{ [0,1,2,3,4].map(index => { 
								let men = this.game_data.men.entries.find(x => x.athlete_id == this.result.men[index])
								let women = this.game_data.women.entries.find(x => x.athlete_id == this.result.women[index])
								if(!men || !women) return
								let men_results = this.game_results.men.find(x => x.athlete_id == this.result.men[index])
								let women_results = this.game_results.women.find(x => x.athlete_id == this.result.women[index])
								//let women_results = this.game_results.women.find(x => x.athlete_id == this.result.women[index])
								return(
								<tr>
									<td class='text-right' style='width: 50%;'><b>{men.title}</b><br/>{men_results && (men_results.points + " pts")} - {men_results && (men_results.rank <= 0 ? "DNF" : ordinal_suffix_of(men_results.rank))}</td>
									<td style='width: 48px;'><img src={men.profile_image} style='width: 48px;'/></td>
									<td style='width: 48px;'><img src={women.profile_image} style='width: 48px;'/></td>
									<td style='width: 50%;'><b>{women.title}</b><br/>{women_results && (women_results.points + " pts")} - {women_results && (women_results.rank <= 0 ? "DNF" : ordinal_suffix_of(women_results.rank))}</td>
								</tr>
							)})}
						</table>
					</div>
				</div>
			)
			
		}
	}
}
