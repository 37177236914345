let modal = document.getElementById('embed-dialog')
let copy = document.getElementById('embed-copy')
let input = document.getElementById('embed-input')
let form = document.getElementById('embed-form')

function updateEmbed()
{
	let kvpairs = []
	for(let e of form.elements) 
	{
		if(e.type == "radio" && !e.checked) continue
		if(e.value == "" || e.name == "") continue
		kvpairs.push(encodeURIComponent(e.name) + "=" + encodeURIComponent(e.value))
	}

	input.value = `<div class='pto-embed' data-src='${input.base_value + ( kvpairs.length ? "?" + kvpairs.join("&") : "")}'></div><script async src='https://platform.protriathletes.org/embed.js'></script>`
}


if(modal && copy && input)
{
	Array.from(document.getElementsByClassName("embed-results")).forEach(function(b) {
		b.addEventListener('click', function(e) {
			modal.modal('show')
			copy.innerHTML = "Copy"
			input.base_value = location.href + b.dataset.src
			updateEmbed()
		})
	})

	let radios = modal.querySelectorAll('input[type=radio]')
	radios.forEach( function(element, index) {
		element.addEventListener("change", (e) => {
			updateEmbed()
		})
	});


	copy.addEventListener('click', function(e) {
	//$("#share-input").attr("disabled", false)
		input.select()
		input.setSelectionRange(0, 99999)
		document.execCommand("copy")
		copy.innerHTML = "Copied!"
	})
}
