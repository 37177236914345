import { h, render, Component, createRef, Fragment } from 'preact'
import { secondsToTime } from "../server/components/utils"
import moment from 'moment';
import Router from 'preact-router';
import { route } from 'preact-router';
import { TermsConditionsContent } from "./media/terms"
import EXIF from 'exif-js';

/*class _Popover_ extends Component
{
	render(props, state)
	{
		return(<div>POPOVER!!!</div>)
	}
}

let t = <_Popover_/>
render(t, document.body)
console.log(t)
*/

class Modal extends Component {
	state = { isOpen: false }
	windowOffset = 0

	open = _ => {
		this.setState({ isOpen: true }, () => {
			this.windowOffset = window.scrollY
			document.body.style.position = 'fixed'
			document.body.style.top = `-${this.windowOffset}px`
		})
	}
	close = _ => {
		this.setState({ isOpen: false }, () => {
			document.body.style.position = ''
			document.body.style.top = ''
			window.scrollTo({ left: 0, top: parseInt(this.windowOffset || 0), behavior: 'auto'})
		})
	}

	render(props, state)
	{
		if(!state.isOpen) return

		return(
			<div class='pto-modal-container'>
				<div class='pto-modal-dialog' maximize={props.maximize}>
					<div class='pto-modal-header'>
						<div class='d-flex align-items-center'>
							<div class='pto-modal-close' onClick={this.close}><i class="fas fa-times"></i></div>
							<div class='ml-2 pto-modal-title'>{props.title}</div>
						</div>
						<div>
							{props.save && <button class='btn btn-sm btn-primary' onClick={props.onSave}>Save</button>}
						</div>
					</div>
					<div class='pto-modal-content'>
						{ props.children }
					</div>
				</div>
			</div>
		)
	}
}

let __session__ = null
let __media_user__ = null

let __form_credantial__ = [
	{ name: "Category *", type: "select", dbname: "type", value: "", options: [ 
		{ name: "Media", value: "media" },
		{ name: "Sponsors/Brands/Partners", value: "partner" },
		{ name: "Non Accredited Media", value: "na_media" },
		{ name: "Staff", value: "staff" },
		], full: true
	},
	{ name: "Organisation Name *", type: "text", dbname: "organisation_name", value: "" },
	{ name: "Job Role *", type: "text", dbname: "job_role", value: "" },
	{ name: "Work Email *", type: "email", dbname: "work_email", value: "" },
	{ name: "Mobile Phone Number *", type: "tel", dbname: "mobile_phone_number", value: "" },
	{ name: "Website", type: "url", dbname: "website", value: "" },
	{ name: "VAT Number", type: "text", dbname: "vat_number", value: "" },
	{ name: "Twitter", type: "text", dbname: "twitter", value: "" },
	{ name: "Instagram", type: "text", dbname: "instagram", value: "" },
	{ name: "Company Address", type: "textarea", dbname: "company_address", value: "" },
	{ name: "Company Description", type: "textarea", dbname: "description_company", value: "" },
	{ name: "Editor Full Name", type: "text", dbname: "editor_full_name", value: "" },
	{ name: "Editor Phone Number", type: "tel", dbname: "editor_phone_number", value: "" },
	{ name: "Editor Email", type: "email", dbname: "editor_email", value: "" },
	{ name: "Affiliation", type: "textarea", dbname: "affiliation", value: "", full: true },
]

class VideoPlayer extends Component {

	player = createRef()

	componentWillMount()
	{
		document.addEventListener('keydown', this.onKeyDown)
		document.addEventListener('fullscreenchange', this.onFullscreenChange)
	}
	componentWillUnmount()
	{
		document.removeEventListener('keydown', this.onKeyDown);
		document.removeEventListener('fullscreenchange', this.onFullscreenChange)
	}

	onFullscreenChange = e => {
		this.setState({ fullscreen: document.fullscreenElement })
	}

	onKeyDown = e => {
		if(!this.video) return

		if(e.code == "ArrowRight")
		{
			this.video.currentTime += 5
		}
		else if(e.code == "ArrowLeft")
		{
			this.video.currentTime -= 5
		}
		else if(e.code == "Space")
		{
			this.videoPlayPause()
		}
		this.setState({ 
			time: this.video.currentTime, 
			timePercent: this.video.currentTime / this.video.duration
		})
		e.preventDefault()
	}
	
	videoRef = video => {
		this.video = video

		if(!video) return

		video.onabort = function() { console.log("onabort"); };
		video.oncanplaythrough = function() { console.log("oncanplaythrough", this.duration); };
		video.onemptied = function() { console.log("onemptied"); };
		video.onended = function() { console.log("onended"); };
		video.onerror = function() { console.log("onerror"); };
		video.onloadeddata = function() { console.log("onloadeddata"); };
		video.onloadstart = function() { console.log("onloadstart"); };
		video.onplaying = function() { console.log("onplaying"); };
		video.onratechange = function() { console.log("onratechange"); };
		video.onreadystatechange = function() { console.log("onreadystatechange"); };
		video.onseeking = function() { console.log("onseeking"); };
		video.onsuspend = function() { console.log("onsuspend"); };
		video.onvolumechange = function() { console.log("onvolumechange"); };


		video.oncanplay = function() { 
			console.log("oncanplay", this.duration);
		};


		video.onstalled = function() { 
			console.log("onstalled"); 
		};

		video.onwaiting = function() { 
			console.log("onwaiting"); 
		};

		video.targetTime = -1


		let that = this

		video.onprogress = (v) => { 
			let buffered = v.target.buffered
			let end = v.target.currentTime
			for(let tri = 0; tri < buffered.length; tri++)
			{
				if(end >= buffered.start(tri) && end < buffered.end(tri))
				{
					end = buffered.end(tri)
					break				
				}
			}
			this.setState({ 
				buffered: end,
				bufferedPercent: end / v.target.duration 
			})
		}


		video.ontimeupdate = (v) => { 
			if(this.state.scrubbing) return

			/*let buffered = v.target.buffered
			let end = v.target.currentTime
			for(let tri = 0; tri < buffered.length; tri++)
			{
				if(end >= buffered.start(tri) && end < buffered.end(tri))
				{
					end = buffered.end(tri)					
				}
			}*/

			this.setState({ 
				time: v.target.currentTime, 
				timePercent: v.target.currentTime / v.target.duration, 
				//buffered: end,
				//bufferedPercent: end / v.target.duration 
			})
		}

		video.onseeked = e => { 
			if(e.target.targetTime >= 0)
			{
				e.target.currentTime = e.target.targetTime
				e.target.targetTime = -1
			}
		}

		video.onloadedmetadata = e => {
			this.setState({ time: 0, timePercent: 0, duration: this.video.duration })
		}

		video.onplaying = e => { this.setState({ paused: false }) }
		video.onplay = e => { this.setState({ paused: false }) }
		video.onpause = e => { this.setState({ paused: true }) }
		video.ondurationchange = e => { this.setState({ duration: e.target.duration }) }
	}

	videoPlayPause = e => {
		if(this.video.paused) this.video.play()
		else this.video.pause()
	}

	videoMute = e => {
		if(this.video.volume) this.video.volume = 0
		else this.video.volume = 1
		this.setState({ volume: this.video.volume })
	}

	videoFullscreen = e => {
		if(!this.player.current) return
		if (!document.fullscreenElement) 
		{
			this.player.current.requestFullscreen()
		}
		else 
		{
			if (document.exitFullscreen) document.exitFullscreen()
		}
	}

	videoPIP = e => {
		if(!this.player.current) return
		if (!document.pictureInPictureElement) 
		{
			this.video.requestPictureInPicture()
		}
		else 
		{
			if (document.exitPictureInPicture) document.exitPictureInPicture()
		}
	}

	scrubMove = e => {
		let el = this.progressbar
		let startX = 0

		while(el)
		{
			startX += el.offsetLeft
			el = el.offsetParent
		}

		let x = Math.max(0, Math.min(this.progressbar.offsetWidth, e.pageX - startX))
		//console.log("moveScrub", x / this.progressbar.offsetWidth)
		let targetTime = this.video.duration * x / this.progressbar.offsetWidth
		if(this.video.seeking)
		{
			this.video.targetTime = targetTime
		}
		else
		{
			this.video.currentTime = targetTime
		}
		this.setState({ 
			time: targetTime, 
			timePercent: targetTime / this.video.duration
		})
	}


	progressbarRef = pb => {
		if(!pb) return

		this.progressbar = pb
		pb.addEventListener('pointerdown', e => { 

			this.setState({ scrubbing: true })
			this.scrubMove(e)
			document.addEventListener('pointermove', this.scrubMove)

			document.addEventListener('pointerup', e => {
				this.setState({ scrubbing: false })
				document.removeEventListener('pointermove', this.scrubMove)
			}, { once: true, capture: false })
		})
	}

	render(props, state)
	{
		return(
			<div class='pto-video-player' ref={this.player}>
				<video _controls autoplay src={props.src} ref={this.videoRef} onClick={this.videoPlayPause}/>	
				<div class='pto-video-controls-container'>
					<div class='pto-video-progressbar-container' ref={this.progressbarRef}>
						<div class='pto-video-progressbar-padding'></div>
						<div class='pto-video-progressbar-all'></div>
						<div class='pto-video-progressbar-downloaded' style={`width: ${100*state.bufferedPercent}%`}></div>
						<div class='pto-video-progressbar-play' style={`width: ${100*state.timePercent}%`}></div>
					</div>
					<div class='pto-video-controls'>
						<div class='pto-video-controls-left'>
							<div class='pto-video-controls-playpause' onClick={this.videoPlayPause}>
								<i class={"fas " + (!state.paused ? "fa-pause" : "fa-play")}></i>
							</div>
							<div class='pto-video-controls-mute' onClick={this.videoMute}>
								<i class={"fas " + (state.volume == 0 ? "fa-volume-mute" : "fa-volume-up")}></i>
							</div>

							{state.duration && <div class='pto-video-controls-time'>
								{secondsToTime(Math.floor(state.time)).replace("--:--","00:00")} / {secondsToTime(Math.floor(state.duration))}
							</div>}
						</div>
						<div class='pto-video-controls-right'>
							<div class='pto-video-controls-fullscreen' onClick={this.videoFullscreen}>
								<i class={"fas " + (state.fullscreen ? "fa-compress" : "fa-expand")}></i>
							</div>
							<div class='pto-video-controls-pip' onClick={this.videoPIP}>
								<i class="fas fa-external-link-alt"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

class QuillEditor extends Component {
	constructor (props) {
		super(props)
		this.state = {
			html: '',
			editor: null,
		}
	}

	content = null

	componentDidMount () {
		this.editor.setText('')
		this.editor.clipboard.dangerouslyPasteHTML(0, this.props.content)
	}

	shouldComponentUpdate (nextProps, nextState) {
		this.editor.setText('')
		this.editor.clipboard.dangerouslyPasteHTML(0, nextProps.content)
		return false
	}

	init = d => {
		this.content = d
		if(!d) return
		this.editor = new Quill(d, { 
			theme: 'snow',
			modules: {
				//toolbar: ['bold', 'italic', 'underline', 'strike']
			},
		})

		this.editor.on('text-change', (delta, oldDelta, source) => {
			if(this.props.onChange && source == "user") this.props.onChange(this.editor.root.innerHTML)
		})
	}

	render (props, state) {
		return <div ref={this.init}/>
	}
}

class MediaUser extends Component {
	componentWillMount()
	{
		if(this.props.profile)
		{
			for(let f of __form_credantial__) f.value = ""
			for(let p in this.props.profile)
			{
				let f = __form_credantial__.find(x => x.dbname == p)
				if(f) f.value = this.props.profile[p] || ""
			}
		}
	}

	change = e => {
		let dbname = e.target.dataset.dbname
		let value = encodeURI(e.target.value)
		let f = __form_credantial__.find(x => x.dbname == dbname)
		if(f) f.value = value

		let data = {}
		for(let f of __form_credantial__)
		{
			data[f.dbname] = f.value != "" ? f.value : null
		}

		if(this.props.onChange) this.props.onChange(data)
	}

	render (props, state) {
		return (<form class='form-accreditation'>
			{__form_credantial__.map(x => {
				if(props.exclude && props.exclude.indexOf(x.dbname) != -1) return
				if(x.type == "textarea")
				{
					return(
						<div class="form-group" full={x.full}>
							<label for={x.dbname}>{x.name}</label>
							<textarea rows="3" class="form-control form-control-sm" id={x.dbname} value={decodeURI(x.value)} data-dbname={x.dbname} onInput={this.change}/>
						</div>
					)
				}

				if(x.type == "select")
				{
					return(
						<Fragment>
							<label class='ml-2' for={x.dbname}><b>{x.name}</b></label>
							<div class="form-group select" full={x.full}>
								<select class='form-control form-control-sm' data-dbname={x.dbname} onChange={this.change}>
									<option selected={x.value == null}>Choose...</option>
									{ x.options.map(o => {
										return(
											<option value={o.value} selected={o.value == x.value}>{o.name}</option>
										)
									})}
								</select>
							</div>
						</Fragment>
					)
				}
				return(
					<div class="form-group" full={x.full}>
						<label for={x.dbname}>{x.name}</label>
						<input type={x.type} class="form-control form-control-sm" id={x.dbname} value={decodeURI(x.value)} data-dbname={x.dbname} onInput={this.change}/>
					</div>
				)
			})}
		</form>)
	}
}

class AdminUsers extends Component {
	
	state = { profile_edit: {}, profile_edit_id: 0 }
	
	modalRef = createRef()

	componentWillMount()
	{
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		const users_fetch = await fetch("/api/media/users")
		const users_json = await users_fetch.json()

		this.users = users_json.users
		for(let u of this.users)
		{
			u.info = JSON.parse(u.info)
			for(let m in u.info)
			{
				u.info[m] = u.info[m] == null ? "" : decodeURI(u.info[m])
			}
		}

		this.setState({ loading: false })
	}

	shouldComponentUpdate(nextProps, nextState)
	{
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	changeAccess = e => {
		let userDom = e.target.closest(".user")
		let user_id = userDom.dataset.userid
		let access = e.target.value

		fetch("/api/media/user/access", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ user_id: user_id, access: access })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { })
	}

	changeType = e => {
		let userDom = e.target.closest(".user")
		let user_id = userDom.dataset.userid
		let type = e.target.value
		let user = this.users.find(x => x.user_id == user_id)

		user.info.type = type

		fetch("/api/media/user/info", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ user_id: user_id, info: user.info })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { })
	}


	edit = e => {
		let userDom = e.target.closest(".user")
		let user_id = userDom.dataset.userid
		let user = this.users.find(x => x.user_id == user_id)
		
		this.setState( { profile_edit_id: user_id, profile_edit: user.info}, () => {
			this.modalRef.current.open()
		})
	}

	saveProfile = e => {
		this.modalRef.current.close()

		let user = this.users.find(x => x.user_id == this.state.profile_edit_id)
		user.info = this.state.profile_edit

		fetch("/api/media/user/info", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ user_id: this.state.profile_edit_id, info: this.state.profile_edit })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
		})

		this.setState({})
	}

	userChange = e => {
		//this.accreditationForm = e
		this.setState({ profile_edit: e })
	}


	render(props, state)
	{
		if(!this.users) return
		let type = __form_credantial__.find(x => x.dbname == "type")
		return (
			<div class='admin-users'>
				<table class='table'>
					<tr>
						{["ID", "Name/Email", "Organisation - Role / Work Email", "Type", "Access"].map(x => <th>{x}</th>)}
					</tr>
					{this.users.map(user => {
						return(<tr class='user' data-userid={user.user_id}>
							<td>{user.user_id}</td>
							<td>{user.first} {user.last}<br/><a class='text-grey' href={"mailto:" + user.email}>{user.email}</a></td>
							<td>{user.info.organisation_name} - {user.info.job_role}<br/><a class='text-grey' href={"mailto:" + user.info.work_email}>{user.info.work_email}</a></td>
							<td><select class='form-control-sm' onChange={this.changeType}>
								{ type.options.map(o => {
									return(
										<option value={o.value} selected={o.value == user.info.type}>{o.name}</option>
									)
								}) }
							</select></td>
							<td><select class='form-control-sm' onChange={this.changeAccess}>
								{["admin", "user", "pending", "suspended"].map(x => <option value={x} selected={x == user.access}>{x}</option>)}
							</select></td>
							<td>
								<button class='btn btn-sm btn-secondary' onClick={this.edit}>Edit</button>
							</td>
						</tr>)
					})}
				</table>
				<Modal ref={this.modalRef} title={"Edit User"} save onSave={this.saveProfile}>
					<div class='media-user p-3'>
						<MediaUser onChange={this.userChange} profile={state.profile_edit}/>
					</div>
				</Modal>
			</div>
		)		
	}
}

class AdminInterface extends Component {
	state = { admin_tab: "users" }

	selectAdminTab = e => {
		let tab = e.target.dataset.tab
		this.setState({ admin_tab: tab })
	}
	
	render(props, state)
	{
		return(
			<div class='admin-interface'>
				<div class='menu-tab'>
					<button onClick={this.props.back} class='mb-4'><i class="fas fa-chevron-left mr-2"></i> Back to App</button>
					<button selected={state.admin_tab == "users"} data-tab={"users"} onClick={this.selectAdminTab}>Users</button>
					<button selected={state.admin_tab == "events"} data-tab={"events"} onClick={this.selectAdminTab}>Projects</button>
				</div>
				<div class='admin-tab'>
					{state.admin_tab == "users" && 
						<AdminUsers/>
					}
				</div>
			</div>
		)
	}
}


class EventInfo extends Component {
	componentDidMount()
	{
		if(this.event_id != this.props.event_id)
		{
			this.event_id = this.props.event_id
			this.init()
		}
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		const info_fetch = await fetch("/api/media/event/" + this.event_id)
		const info_json = await info_fetch.json()

		this.event = info_json.event

		this.setState({ loading: false })
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		if(this.event_id != nextProps.event_id)
		{
			this.event_id = nextProps.event_id
			this.init()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	change = e => {
		if(this.saveTimer != null) clearTimeout(this.saveTimer)
		this.saveTimer = setTimeout((description) => {
			this.saveTimer = null
			//console.log("save", description)
			let f = fetch("/api/media/event/" + this.event.id + "/description", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ description: encodeURI(e) })
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(_) { })

		}, 500, e)
	}

	render(props, state)
	{
		if(!this.event) return
		return (
			<div class='info'>
				<div class='description' dangerouslySetInnerHTML={{ __html: decodeURI(this.event.description)}} hidden={this.event.permissions != "viewer"}></div>
				<div hidden={this.event.permissions == "viewer"}>
					<QuillEditor placeholder={''} container='#editor' theme={'snow'} content={decodeURI(this.event.description)} onChange={this.change}/> 
				</div>
			</div>
		)		
	}
}

class EventUsers extends Component {
	componentDidMount()
	{
		if(this.event_id != this.props.event_id)
		{
			this.event_id = this.props.event_id
			this.init()
		}
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		const users_fetch = await fetch("/api/media/users")
		const users_json = await users_fetch.json()
		this.users = users_json.users

		for(let u of this.users)
		{
			u.info = JSON.parse(u.info)
			for(let m in u.info)
			{
				u.info[m] = u.info[m] == null ? "" : decodeURI(u.info[m])
				u.search = [u.first, u.last, u.info.work_email, u.info.organisation_name].join(" ").toUpperCase()
			}
		}

		const event_users_fetch = await fetch("/api/media/event/" + this.event_id + "/users")
		const event_users_json = await event_users_fetch.json()
		for(let u of event_users_json.users)
		{
			let user = this.users.find(x => x.user_id == u.user_id)
			if(user) {
				user.permissions = u.permissions
			}
		}
		this.event_users = event_users_json.users

		this.setState({ loading: false })
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		if(this.event_id != nextProps.event_id)
		{
			this.event_id = nextProps.event_id
			this.init()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	permissions = e => {
		let userDom = e.target.closest(".user")
		let user_id = userDom.dataset.userid
		let access = e.target.dataset.access
		let user = this.users.find(x => x.user_id == user_id)
		if(user.permissions == access) user.permissions = null
		else user.permissions = access

		fetch("/api/media/event/" + this.event_id + "/user/permissions", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ user_id: user_id, permissions: user.permissions })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { })

		this.setState({})
	}

	search = e => {
		let terms = e.target.value
		this.setState({ search: terms })
	}

	render(props, state)
	{
		if(!this.event_users) return
		
		let users = null 
		if(state.search)
		{
			let terms = state.search.split(" ").map(x => x.trim().toUpperCase()).filter(x => x != "")
			users = this.users.filter(x => {
				let show = true
				for(let t of terms)
				{
					if(x.search.indexOf(t) == -1)
					{
						show = false
						break
					}
				}
				return show
			})
		}
		else
		{
			users = this.users.filter(x => 
				x.permissions
			)
		}

		return (
			<div class='event-users-container'>
				<div class='filter mb-3 px-2'>
					<input class='form-control' type='search' placeholder="Name or email" onInput={this.search} value={this.state.search}/>
				</div>
				<div class='users-list'>
					{ users.map(user => {
						return(
							<div class='user px-3' data-userid={user.user_id}>
								<div class='user-info'>
									<div class='name'>{user.first + " " + user.last}</div>
									<div class='organisation'>{user.info.organisation_name} - {user.info.work_email}</div>
								</div>
								<div class='access'>
									<div class='btn-access' data-access='contributor' selected={user.permissions == "contributor"} onClick={this.permissions}>Contributor</div>
									<div class='btn-access' data-access='viewer' selected={user.permissions == "viewer"} onClick={this.permissions}>Viewer</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)		
	}
}

export class MediaFiles extends Component {
	state = { loading: true, viewer: false, selected_folder: -1 }

	event_id = 0

	componentDidMount()
	{
		if(this.event_id != this.props.event_id)
		{
			this.event_id = this.props.event_id
			this.init()
		}
	}
	componentWillUnmount()
	{
	}

	init = async () => {
		this.setState({ loading: true })

		const files_fetch = await fetch("/api/media/event/" + this.event_id + "/files")
		const files_json = await files_fetch.json()

		this.files = files_json.files
		this.folders = files_json.folders

		this.setState({ loading: false, selected_folder: -1 })
	}

	reload = async () => {
		const files_fetch = await fetch("/api/media/event/" + this.event_id + "/files")
		const files_json = await files_fetch.json()
		this.files = files_json.files
		this.setState({ })
	}

	shouldComponentUpdate(nextProps, nextState)
	{
		if(this.event_id != nextProps.event_id)
		{
			this.event_id = nextProps.event_id
			this.init()
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	download = (e) => {
		let file_container = e.target.closest(".file-container")
		let file_index = -1, file = null
		
		if(file_container) 
		{
			file_index = parseInt(file_container.dataset.index)
			file = this.files[file_index]
		}

		
		let download = e.target.closest(".download")
		let href = download.attributes.href.value
		let filename = download.attributes.download.value

		let xhr = new window.XMLHttpRequest()
		xhr.open('get', href, true)
		xhr.onload = (r) => { 
			if(file) 
			{
				file.download = undefined
				this.setState({})
			}
			let blobUrl = window.URL.createObjectURL(xhr.response)
			let a = document.createElement('a')
			a.download = filename
			a.href = blobUrl
			document.body.appendChild(a)
			a.click()
			a.remove()
		}
		xhr.responseType = 'blob'
		xhr.onerror = () => {  }
		xhr.onprogress = (evt) => {
			if(file) 
			{
				file.download = 100*evt.loaded / evt.total
				this.setState({})
			}
			if (evt.lengthComputable) console.log(100*evt.loaded / evt.total)
		}
		xhr.send();

	}

	view = e => {
		let file_container = e.target.closest(".file-container")
		let file_index = parseInt(file_container.dataset.index)
		let file = this.files[file_index]
		this.setState({ viewer_artist: "", viewer_date: ""})
		if(file.type.startsWith("image/"))
		{
			let image = new Image()
			image.onload = () => {
				EXIF.getData(image, () => {
					console.log(image.exifdata)
					console.log(image.iptcdata)
					this.setState({ 
						viewer_artist: (image.exifdata.Artist && decodeURIComponent(escape(image.exifdata.Artist))) || (image.exifdata.Copyright && decodeURIComponent(escape(image.exifdata.Copyright))) || "", 
						viewer_date: image.exifdata.DateTimeOriginal})
				})
			}
			image.crossOrigin = ""
			image.src = file.url

			this.setState({ viewer: "image", "viewer_src": file.url, "viewer_filename": file.filename })
		}
		else if(file.type.startsWith("video/"))
		{
			this.setState({ viewer: "video", "viewer_src": file.url, "viewer_filename": file.filename })
		}
		else if(file.type == "application/pdf")
		{
			this.setState({ viewer: "pdf", "viewer_src": file.url, "viewer_filename": file.filename })
		}
	}

	viewerClose = e => {

		this.setState({ viewer: false })
	}

	last_file_index = -1
	select = e => {
		if(e.detail == 2) return

		let ignore = e.target.closest(".ignore-select")
		if(ignore) return

		let file_index = -1
		let file_container = e.target.closest(".file-container")
		if(file_container) file_index = parseInt(file_container.dataset.index)

		if(e.metaKey && file_index != -1)
		{
			let file = this.files[file_index]
			file.selected = file.selected ? false : true		
		}
		else if(e.shiftKey && file_index != -1)
		{
			let start = Math.min(this.last_file_index, file_index)
			let end = Math.max(this.last_file_index, file_index)
			for(let i = start; i <= end; i++)
			{
				let file = this.files[i]
				file.selected = true
			}
		}
		else
		{
			for(let i = 0; i < this.files.length; i++)
			{
				let file = this.files[i]
				if(file_index == i)
				{
					file.selected = file.selected ? false : true		
				}
				else
				{
					file.selected = false
				}
			}
		}
		this.last_file_index = file_index
		
		this.setState({})
	}
	delete = e => {
		let selected_files = this.files.filter(x => x.selected)
		let d = confirm("Are you sure you want to delete the following files?\n\n" + selected_files.map(x => x.filename).join("\n"))

		if(d)
		{
			fetch("/api/media/files/" + selected_files.map(x => x.id).join(",") + "/delete", { method: "post" })
			this.files = this.files.filter(x => !x.selected)
			this.setState({})
		}
	}

	upload = e => {
		if(this.props.onUpload) this.props.onUpload(this.event_id, this.state.selected_folder)
	}
	
	selectFolder = e => {
		let folder = e.target.closest(".folder")
		if(!folder) return
		let folder_id = folder.dataset.id
		for(let f of this.files) f.selected = false

		this.setState({ selected_folder: folder_id })		
	}

	addFolder = async e => {
		let folder_name = prompt("Enter a folder name")
		if(!folder_name) return

		folder_name = folder_name.trim()
		if(folder_name.length == 0) return		

		let add_folder_req = await fetch("/api/media/event/" + this.event_id + "/add-folder", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ name: folder_name })
		})
		const add_folder_res = await add_folder_req.json()
		this.folders = add_folder_res.folders
		this.setState({})
	}

	moveToFolder = async e => {
		let folder = e.target.closest(".folder")
		if(!folder) return
		let folder_id = folder.dataset.id
		let selected_files = this.files.filter(x => x.selected)
		for(let f of selected_files)
		{
			f.folder_id = folder_id
			f.selected = false
		}
		console.log(folder_id)

		let move_to_folder_req = await fetch("/api/media/event/" + this.event_id + "/move-to-folder", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ folder_id: folder_id, file_ids: selected_files.map(x => x.id) })
		})

		this.closePopoverFolders()
		this.setState()
	}

	closePopoverFolders = e => {
		this.setState({ popoverFolderAnchorEl: null})
	}

	showPopoverFolders = e => {
		this.setState({ popoverFolderAnchorEl: e.target})
	}

	closePopoverFolderEdit = e => {
		this.setState({ popoverFolderEditAnchorEl: null})
	}
	showPopoverFolderEdit = e => {
		this.setState({ popoverFolderEditAnchorEl: e.target})
	}

	folderRename = async _ => {
		let selectedFolder = this.folders.find(x => x.id == this.state.selected_folder)
		if(!selectedFolder) return
		let folder_name = prompt("Rename folder", selectedFolder.name)
		if(!folder_name) return

		folder_name = folder_name.trim()
		if(folder_name.length != 0)
		{
			let add_folder_req = await fetch("/api/media/event/" + this.event_id + "/rename-folder", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ name: folder_name, folder_id: selectedFolder.id })
			})
			const add_folder_res = await add_folder_req.json()

			selectedFolder.name = folder_name
		}	

		this.setState({ popoverFolderEditAnchorEl: null})
	}

	folderRemove = async _ => {
		let selectedFolder = this.folders.find(x => x.id == this.state.selected_folder)
		if(!selectedFolder) return

		let yes = confirm("Are you sure you want to delete this folder and all its files?\n\n'" + selectedFolder.name + "'\n\nThis action cannot be undone")
		if(yes)
		{
			let add_folder_req = await fetch("/api/media/event/" + this.event_id + "/remove-folder", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ folder_id: selectedFolder.id })
			})
			this.folders = this.folders.filter(x => x.id != selectedFolder.id)
		}
		this.setState({ popoverFolderEditAnchorEl: null})
	}

	searchTimer = null
	searchInput = e => {
		if(this.searchTimer != null) clearTimeout(this.searchTimer)

		this.searchTimer = setTimeout(async (terms) => {
			this.searchTimer = null
			if(terms == undefined || terms.trim() == "") {
				this.setState({ showSearch: null })
				return
			}
			console.log(terms)
			const search_fetch = await fetch("/api/media/event/" + this.event_id + "/search?q=" + terms)
			const search_json = await search_fetch.json()

			console.log(search_json)

			this.setState({ showSearch: search_json.search.map(x => x.id) })

		}, 250, e.target.value)
	}

	render(props, state)
	{
		if(state.loading) return		

		let event = props.event
		let selected_files = this.files.filter(x => x.selected)
		let selectedFolder = this.folders.find(x => x.id == state.selected_folder)

		let last_date = null

		return(
			<div class='d-flex overflow-hidden w-100'>
				<div class='media-side'>
					<div class='event-info'>
						<div class='folder' data-id={-1} selected={state.selected_folder == -1} onClick={this.selectFolder}><i class="fas fa-info-circle mr-1"></i> Information</div>
					</div>
					<div class='event-search'>
						<input class='form-control form-control-sm' placeholder='Search...' type='search' onInput={this.searchInput}/>
					</div>
					<div class='event-folders'>
						<div class='head'>
							<div class='title'><i class="fas fa-folder mx-1"></i> Folders</div>
							<div class='add' onClick={this.addFolder}><i class="fas fa-plus-circle"></i></div>
						</div>
						<div class='folder px-4' data-id={0} selected={state.selected_folder == 0} onClick={this.selectFolder}>Default</div>
						{this.folders.map(x => { return (
							<div class='folder px-4' data-id={x.id} selected={state.selected_folder == x.id} onClick={this.selectFolder}>{x.name}</div>
						)})}
					</div>
				</div>

				{ !state.showSearch && state.selected_folder == -1 && <EventInfo event_id={props.event_id}/>}

				{ (state.showSearch || state.selected_folder >= 0) && 
					<div class='d-flex flex-column overflow-hidden w-100' onClick={this.select}>
						<div class='files-list-actions ignore-select'>
							<div>
								{ event.permissions == 'contributor' && selected_files.length == 0 && 
									<div class='position-relative'>
										<div class='d-flex align-items-center cursor-pointer' onClick={selectedFolder && this.showPopoverFolderEdit}>
											{(selectedFolder && selectedFolder.name) || "Default"}
											{selectedFolder && 
												<div class='move ml-2'>
													<i class="fas fa-caret-down"></i>
												</div>
											}
										</div>
										<Popover open={true} anchorEl={state.popoverFolderEditAnchorEl} onClose={this.closePopoverFolderEdit}>
											<div class='folder-edit list'>
												<div class='item' onClick={this.folderRename}><i class="far fa-edit"></i> Rename</div>
												<div class='sep'></div>
												<div class='item' onClick={this.folderRemove}><i class="far fa-trash-alt"></i> Remove</div>
											</div>
										</Popover>
									</div>
								}
								{ event.permissions != 'contributor' && selected_files.length == 0 && 
									<div>
										{(selectedFolder && selectedFolder.name) || "Default"}
									</div>
								}
								{ selected_files.length == 1 && <div>{selected_files[0].filename}</div>}
								{ selected_files.length > 1 && <div>{selected_files.length} files selected</div>}
							</div>
							<div>
								{ event.permissions == 'contributor' && selected_files.length == 0 && <button class='btn btn-sm btn-primary' onClick={this.upload}>Upload files</button>}
								{ event.permissions == 'contributor' && selected_files.length != 0 && <div class='actions'>
									<div class='position-relative'>
										<div class='move' onClick={this.showPopoverFolders} >
											<i class="fas fa-folder"></i>
										</div>
										<Popover open={true} right anchorEl={state.popoverFolderAnchorEl} onClose={this.closePopoverFolders}>
											<div class='move-to-folder'>
												<div class='title'>Move To</div>
												<div class='sep'></div>
												<div class='folder' data-id={0} onClick={this.moveToFolder}>Default</div>
												{this.folders.map(x => { return (
													<div class='folder' data-id={x.id} onClick={this.moveToFolder}>{x.name}</div>
												)})}
											</div>
										</Popover>
									</div>
									<div class='delete' onClick={this.delete} >
										<i class="fas fa-trash-alt"></i>
									</div>
									
								</div>}
							</div>
						</div>
						<div class='files-list'>
							{this.files.map((file, index) => {
								if(!state.showSearch && file.folder_id != state.selected_folder) return

								if(state.showSearch && state.showSearch.indexOf(file.id) == -1) return

								let date = file.created.split("T")[0]
								let showdate = false
								if(date != last_date)
								{
									showdate = true
									last_date = date
								}
								let icon = 'fa-file-alt'
								if(file.type == "application/pdf") icon = 'fa-file-pdf'
								else if(file.type.startsWith("video")) icon = 'fa-file-video'
								return(
									<Fragment>
										{ showdate && <div class='date'>{moment(date).format("DD MMM YYYY")}</div>}
										<div class='file-container' type={file.type} data-index={index} selected={file.selected} _onClick={this.select} key={"media-" + file.id}>
											<div class='thumbnail-container' onDblClick={this.view}>
												{ file.url_thumb && <div class='thumbnail'><img src={file.url_thumb}/></div> }
												{ !file.url_thumb && <div class='thumbnail'><i class={"fas " + icon}></i></div> }
											</div>
											<div class='info-container'>
												{ file.download && 
													<div class='name'>{"Downloading (" + file.download.toFixed(1) + "%)"}</div>
												}
												{ !file.download && 
													<Fragment>
														<div class='d-flex flex-column overflow-hidden flex-grow-1'>
															<div class='name' title={file.filename}>
																{file.filename}
															</div>
															<div class='size'>
																{(file.size / (1024*1024)).toFixed(1)} MB
															</div>
														</div>
														<div class='download' href={file.url} download={file.filename} onClick={this.download} >
															<i class="fas fa-file-download"></i>
														</div>
													</Fragment>
												}
											</div>
										</div>
									</Fragment>
								)
							})}
						</div>
						<div class='viewer-container' hidden={!state.viewer}>
							<div class='viewer-dialog'>
								<div class='viewer-header'>
									<div class='download' onClick={this.viewerClose} href={state.viewer_src} download={state.viewer_filename} onClick={this.download}><i class="fas fa-file-download"></i></div>
									<div> {state.viewer_artist} {state.viewer_date}</div>
									<div class='viewer-close' onClick={this.viewerClose}><i class="fas fa-times"></i></div>
								</div>
								<div class='viewer'>
									{ state.viewer == "image" && <div class='viewer-image'><img src={state.viewer_src}/></div>}
									{ state.viewer == "video" && <div class='viewer-video'><VideoPlayer src={state.viewer_src}/></div>}
									{ state.viewer == "pdf" && <div class='viewer-pdf'>
										<iframe src={state.viewer_src} width="100%" height="100%">
											<p>This browser does not support PDF!</p>
										</iframe>
									</div>}
								</div>
								<div class='viewer-info'>
									<div class='viewer-filename'>{state.viewer_filename}</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		)
	}
}

class Popover extends Component {
	state = { open: false }	
	shouldComponentUpdate (nextProps, nextState) {
		let obj = nextProps.anchorEl;
		nextState.open = obj && obj.offsetParent
		if(obj && obj.offsetParent)
		{
			//console.dir(obj)
			//console.log(obj, obj.offsetParent)
			let offsetLeft = 0, offsetTop = 0, offsetWidth = obj.offsetWidth, offsetHeight = obj.offsetHeight
			do {
				if (!isNaN(obj.offsetLeft)) offsetLeft += obj.offsetLeft - obj.scrollLeft;
				if (!isNaN(obj.offsetTop)) offsetTop += obj.offsetTop + obj.scrollTop;
			} while(obj = obj.offsetParent);

			//console.log(offsetLeft, offsetTop, offsetWidth, offsetHeight)
			let style = ""
			if(nextProps.right)
			{
				style += "left: " + offsetWidth + "px; "
			}
			else {
				style += "left: 0; "
			}
			style += "top: 100%; "
			//nextState.style = "left: " + offsetLeft + "px; " + "top: " + offsetTop + "px; "  
			nextState.style = style
		}
	}

	close = e => {
		if(this.props.onClose) this.props.onClose()
	}

	render(props, state)
	{
		return(
			<div class='popover-container' open={state.open} right={props.right}>
				<div class='popover-backdrop' onClick={this.close}></div>
				<div class='popover-content' style={state.style}>
					{props.children}
				</div>
			</div>
		)		
	}	
}



class Media extends Component {
	state = { loading: true, tab: "files" }

	uploading_files = []
	componentDidMount()
	{
		let events = this.props.events
		if(events && events.length)
		{
			this.setState({ event_id: localStorage.getItem("default_event_id") || events[0].id })
		}		
		this.events = events
		this.init()
	}
	componentWillUnmount()
	{
	}

	init = async () => {
	}

	componentDidUpdate(prevProps, prevState, snapshot)
	{
	}

	selectEvent = e => {
		let event_id = e.target.value //e.target.dataset.event_id
		localStorage.setItem("default_event_id", event_id)
		this.setState({ event_id: event_id, section: "event" })
	}

	selectTab = e => {
		let tab = e.target.dataset.tab
		this.setState({ tab: tab })
	}

	createNextUploadJob = (file, resolve_allparts) => {
		let that = this

		let part = null
		let finished = true
		let job_count = 0
		for(let p of file.parts)
		{
			if(p.upload_job == 'working')
			{
				finished = false
				job_count++
			}
			else if(p.upload_job == 'done')
			{
			}
			else
			{
				if(!part) part = p
				finished = false				
			}
		}

		if(job_count > 8) return

		if(!window.navigator.onLine)
		{
			if(!file.retryTimer)
			{
				console.log("retrying...")
				that.uploading_files[file.uploading_index].progress = "Offline, Retrying... "
				that.setState({})
				file.retryTimer = setTimeout(() => {
					file.retryTimer = null
					that.createNextUploadJob(file, resolve_allparts)
				}, 3000)
			}
			return
		}

		if(finished) 
		{
			resolve_allparts()
			return
		}

		if(!part) return null

		if(window.navigator.onLine)
		{
			setTimeout(() => {
				that.createNextUploadJob(file, resolve_allparts)
			}, 3000)
		}


		let data_multi = file.dataMulti

		//part.status = 'working'

		//let upload_job = new Promise((resolve_uploadpart, reject_uploadpart) => {
			let reader = new FileReader()
			reader.multi = { file: file, part: part, uploaded: 0, loaded: 0 }

			reader.onloadend = (e) => {
				if (e.target.readyState == FileReader.DONE)
				{
					let xhr = new window.XMLHttpRequest()
					xhr.multi = reader.multi
					xhr.open('put', reader.multi.part.url)
					xhr.timeout = 30 * 1000;
					xhr.onload = (e) => { 
						let etag = e.target.getResponseHeader("ETag")
						e.target.multi.part.etag = e.target.getResponseHeader("ETag").substring(1, etag.length - 1)
						e.target.multi.part.upload_job = 'done'
						that.createNextUploadJob(file, resolve_allparts)
					}

					xhr.ontimeout = (e) => {
						console.log("TIMEOUT", e)
						e.target.multi.part.upload_job = null
						e.target.multi.part.uploaded = 0
						that.createNextUploadJob(file, resolve_allparts)
					}

					xhr.onerror = (e) => { 
						console.log("ERROR", e)
						e.target.multi.part.upload_job = null
						e.target.multi.part.uploaded = 0
						that.createNextUploadJob(file, resolve_allparts)
					}
					xhr.upload.multi = reader.multi
					xhr.upload.addEventListener("progress", function(evt) {
						this.multi.part.uploaded = evt.loaded

						file.uploaded = file.parts.map(x => x.uploaded).reduce((a, b) => a + b, 0)
						let speed = (8 * file.uploaded / 1000) / (new Date().getTime() - file.start_time);

						//console.log(file.uploaded, file.size)
						that.uploading_files[file.uploading_index].percent = file.uploaded / file.size
						that.uploading_files[file.uploading_index].progress = "Uploading: " + parseInt(100 * file.uploaded / file.size) + "% (" + (speed.toFixed(1))  + "Mbps)"
						that.setState({})
						//console.log(100*evt.loaded / evt.total, this.multi)
					}, false);
					xhr.setRequestHeader('Content-Type', data_multi.type)
					xhr.setRequestHeader('Cache-Control', data_multi.cc)
					xhr.send(e.target.result)
				}
			}

			let blob = file.slice(part.offset, part.offset + part.size)
			reader.readAsArrayBuffer(blob)
		//})
		part.upload_job = 'working'
		
		this.createNextUploadJob(file, resolve_allparts)
		//return part
		//part.upload_job = upload_job
		//return upload_job
	}

	onUpload = (event_id, folder_id) => {
		let upload_event_id = event_id //this.state.event_id
		let that = this
		let input = document.createElement('input')
		input.type = "file"
		input.multiple = "1"
		//input.accept = "image/*"
		input.addEventListener("change", async (e) => {
			let files = e.target.files
			let uploads = []
			let chainCreateUrl = Promise.resolve()
			let chainConversion = Promise.resolve()
			for(let file of files)
			{
				file.event_id = upload_event_id
				file.uploading_index = that.uploading_files.length
				that.uploading_files.push({ file: file, progress: "Pending...", event_id: upload_event_id, percent: 0 })
				that.setState({})

				let f = fetch("/api/media/createuploadurl_multi", {
					method: "post",
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ name: file.name, size: file.size, type: file.type, lastModified: parseInt(file.lastModified / 1000) })
				})
				.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
				.then(function(data_multi) { 
					file.dataMulti = data_multi
					file.baseMulti = data_multi.base
					file.parts = data_multi.parts
					file.current_part = 0
					file.uploaded = 0
					file.start_time = new Date().getTime()

					let upload_jobs = []
					for(let part of file.parts)
					{
						part.upload_job = null
						part.uploaded = 0
					}

					return new Promise((resolve_allparts, reject_allparts) => {
						that.createNextUploadJob(file, resolve_allparts)
					})
				}).then(() => {
					that.uploading_files[file.uploading_index].progress = "Uploaded."
					that.setState({})
					console.log("Upload Done", file)
					let parts = file.parts.map(x => {return { ETag: x.etag, PartNumber: x.index }});
					console.log(parts)

					return fetch("/api/media/finish_multi", {
						method: "post",
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ base: file.baseMulti, parts: parts })
					})
				})
				.then(response => response.json() )
				.then(data_finish => {
					file.dataMulti.parts = null
					console.log(data_finish)
					that.uploading_files[file.uploading_index].progress = "Converting..."
					that.setState({})
					return fetch("/api/media/add-file", {
						method: "post",
						headers: { 'Content-Type': 'application/json' },
						body: JSON.stringify({ file: file.dataMulti, event_id: file.event_id, folder_id: folder_id })
					})
				}) 
				.then(response => response.json())
				.then(data_convert => {
					that.uploading_files[file.uploading_index].progress = ""
					that.setState({})
					if(that.mediaFilesRef.current) that.mediaFilesRef.current.reload()
					//console.log("end convert", file.name)
					//_resolve()
				})
			}
		})

		input.click()
	}

	accreditationForm = null
	userChange = e => {
		this.accreditationForm = e
	}

	saveProfile = e => {
		this.modalProfileRef.current.close()
		if(this.accreditationForm)
		{
			fetch("/api/media/user/info", {
				method: "post",
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ user_id: __media_user__.user_id, info: this.accreditationForm })
			})
			.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
			.then(function(data) { 
			})
			__media_user__.info = this.accreditationForm	
			this.setState({})
		}
	}

	/*submitAccreditation = e => {
		if(!this.accreditationForm)
		{
			alert("You need to fill out the form!")
			return
		}
		if(!this.accreditationForm.type)
		{
			alert("Type is missing!")
			return
		}
		if(!this.accreditationForm.organisation_name)
		{
			alert("Organisation Name is missing!")
			return
		}
		if(!this.accreditationForm.job_role)
		{
			alert("Job Role is missing!")
			return
		}
		if(!this.accreditationForm.work_email)
		{
			alert("Work Email is missing!")
			return
		}
		if(!this.accreditationForm.mobile_phone_number)
		{
			alert("Mobile Phone Number is missing!")
			return
		}

		let that = this
		let f = fetch("/api/media/request_accreditation", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.accreditationForm)
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			__media_user__ = data.media_user
			if(__media_user__)
			{
				__media_user__.info = JSON.parse(__media_user__.info) 	
			}
			that.setState({}, () => { window.scrollTo(0,0) })
		})
	}*/

	modalTermsRef = createRef()
	modalProfileRef = createRef()
	modalUsersRef = createRef()
	mediaFilesRef = createRef()

	closePopoverUpload = e => {
		this.setState({ popoverUploadAnchorEl: null})
	}

	showPopoverUpload = e => {
		this.setState({ popoverUploadAnchorEl: e.target})
	}

	closePopoverUser = e => {
		this.setState({ popoverUserAnchorEl: null})
	}

	showPopoverUser = e => {
		this.setState({ popoverUserAnchorEl: e.target})
	}

	showModalUsers = e => {
		this.modalUsersRef.current.open()
	}

	showModalProfile = e => {
		this.setState({ popoverUserAnchorEl: null})

		this.accreditationForm = null
		this.modalProfileRef.current.open()
	}

	showModalTerms = e => {
		this.setState({ popoverUserAnchorEl: null})
		this.modalTermsRef.current.open()
	}

	showAdmin = e => { 
		this.setState({ popoverUserAnchorEl: null})
		this.setState({ show_admin: true })
	}

	hideAdmin = e => { 
		this.setState({ show_admin: false })
	}

	render(props, state)
	{
		if(state.show_admin)
		{
			return (<AdminInterface back={this.hideAdmin}/>)	
		}
		/*if(__media_user__ == null)
		{
			return(<div class='register'>
				<div class='d-flex flex-column align-items-center'>
					<div class='h1 headline italic py-3'>
						PTO MEDIA PORTAL
					</div>
					<div class='text-center px-2'>
						<div>Please fill in this form to register for access to the PTO Media Portal</div>
					</div>
					<div class='w-100 py-4'>
						<div class='media-user bg-white p-3'>
							{ <MediaUser onChange={this.userChange} profile={{}}/> }
							<div class='w-100 d-flex justify-content-center mt-4'>
								<button class='btn btn-primary' onClick={this.submitAccreditation}>Submit</button>
							</div>
						</div>
					</div>
				</div>
			</div>)
		}
		else if(__media_user__.access == 'pending')
		{
			return(<div>
				<div class='d-flex flex-column align-items-center'>
					<div class='h1 headline italic py-3'>
						PTO MEDIA PORTAL
					</div>
					<div class='text-center px-2'>
						<h3 class='mb-2'>Media Accreditation</h3>
						<div>Your media accreditation is waiting for approval.</div>
					</div>
				</div>
			</div>)
		}*/

		let event = props.events.find(x => x.id == state.event_id)
		if(!event && state.event_id != 0) return

		let uploading = this.uploading_files.filter(x => x.progress != "")
		let upload_percent = 0
		if(uploading.length)
		{
			for(let u of uploading) upload_percent += u.percent
			upload_percent = parseInt((100 * upload_percent / uploading.length))
		}
			
		return(
			<div class='media-window'>
				<div class='media-top'>
					<div class='d-flex w-100'>
						<div class='select d-flex align-items-center'>
							<select class='select-event' onChange={this.selectEvent}>
								{props.events.map(event => {
									return(
										<option selected={event.id == state.event_id} value={event.id}>{event.name}</option>
									)
								})}
							</select>
						</div>
						{__media_user__.access == 'admin' && 
							<Fragment>
								<div class='btn-icon btn-manage-users' onClick={this.showModalUsers}><i class="fas fa-users"></i></div>
								<Modal ref={this.modalUsersRef} title="Manage Project Team">
									{ state.event_id && <EventUsers event={event} event_id={state.event_id}/> }
								</Modal>
							</Fragment>
						}
					</div>
					<div class='flex-grow-1 flex-shrink-0 d-flex justify-content-center'>
						<div class='h3 headline italic'>
							PTO MEDIA PORTAL
						</div>
					</div>
					<div class='secondary-access w-100'>
						{ uploading.length != 0 && 
							<div class='position-relative'>
								<div class='access' onClick={this.showPopoverUpload}>Uploading: {upload_percent}%</div> 
								<Popover open={true} anchorEl={state.popoverUploadAnchorEl} onClose={this.closePopoverUpload} right>
									<div class='w-100 upload-jobs py-2'>
										{this.uploading_files.map(f => {
											if(f.progress == "") return
											//if(f.event_id != state.event_id) return
											return(
												<div class='job'>
													<div class='job-name'>{f.file.name}</div>
													<div class='job-progress'>{f.progress}</div>
												</div>
											)
										})}
									</div>
								</Popover>
							</div>
						}
						<Fragment>
							<div class='position-relative'>
								<div class='d-flex align-items-center cursor-pointer' onClick={this.showPopoverUser}>
									{ __session__.first + " " + __session__.last }
									<div class='move ml-2'>
										<i class="fas fa-caret-down"></i>
									</div>
								</div>
								<Popover right open={true} anchorEl={state.popoverUserAnchorEl} onClose={this.closePopoverUser}>
									<div class='list'>
										<div class='item' onClick={this.showModalProfile}><i class="fas fa-user"></i>Profile</div>
										<div class='item' onClick={this.showModalTerms}><i class="fas fa-file-contract"></i>Terms & Conditions</div>
										{ __media_user__.access == 'admin' && 
											<Fragment>
												<div class='sep'></div>
												<div class='item' onClick={this.showAdmin}><i class="fas fa-users-cog"></i>Admin</div>
											</Fragment>
										}
									</div>
								</Popover>
							</div>
							<Modal ref={this.modalProfileRef} title="Profile" save onSave={this.saveProfile}>
								<div class='media-user bg-white p-3'>
									{ <MediaUser onChange={this.userChange} profile={__media_user__.info} exclude={["type"]}/> }
								</div>
							</Modal>
							<Modal ref={this.modalTermsRef} title="Terms & Conditions">
								<div class='p-3' style='max-width: 75ch; overflow-y: auto;'>
									<TermsConditionsContent type={__media_user__.info.type}/>
								</div>
							</Modal>
						</Fragment>
					</div>
				</div>

				<div class='media-container'>
					<div class='media-tab'>
						{state.event_id &&
							<MediaFiles event={event} event_id={state.event_id} onUpload={this.onUpload} ref={this.mediaFilesRef}/>
						}
					</div>
				</div>


			</div>
		)
	}
}


class Login extends Component {
	login = () => {
		location.href = window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback&state=media" + '&client_id=' + window.appData.oauth_client_id
	}

	render(props, state)
	{
		return (
			<div class='fantasy-container py-4'>
				<div class='py-4 d-flex flex-column align-items-center text-center'>
					<h2 class='mb-2'>PTO MEDIA PORTAL</h2>
					<button class='btn btn-primary' onClick={this.login}>Log in</button>
				</div>
			</div>
		)
	}
}

let terms_version = 2

class TermsConditions extends Component {
	accept = () => {
		//location.href = window.appData.oauth_host + '/authorize?redirect_uri=' + location.origin + "/authorize_callback&state=media" + '&client_id=' + window.appData.oauth_client_id
		let f = fetch("/api/media/terms/" + terms_version, { method: "post" })
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			location = '/media'
		})
	}
	render(props, state)
	{
		return (
			<div class='terms-container'>
				<div class='py-4 d-flex flex-column align-items-center'>
					<h2 class='mb-2 text-center'>PTO MEDIA PORTAL</h2>
					<h4 class='mb-4 text-center'>Terms & Conditions</h4>
					<TermsConditionsContent type={__media_user__.info.type}/>
					<button class='btn btn-primary' onClick={this.accept}>I accept the PTO Terms & Conditions</button>
				</div>
			</div>
		)
	}
}

class NotRegistered extends Component {

	accreditationForm = null
	userChange = e => {
		this.accreditationForm = e
	}

	submitAccreditation = e => {
		if(!this.accreditationForm)
		{
			alert("You need to fill out the form!")
			return
		}
		if(!this.accreditationForm.type)
		{
			alert("Category is missing!")
			return
		}
		if(!this.accreditationForm.organisation_name)
		{
			alert("Organisation Name is missing!")
			return
		}
		if(!this.accreditationForm.job_role)
		{
			alert("Job Role is missing!")
			return
		}
		if(!this.accreditationForm.work_email)
		{
			alert("Work Email is missing!")
			return
		}
		if(!this.accreditationForm.mobile_phone_number)
		{
			alert("Mobile Phone Number is missing!")
			return
		}

		let that = this
		let f = fetch("/api/media/request_accreditation", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(this.accreditationForm)
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) { 
			__media_user__ = data.media_user
			if(__media_user__)
			{
				__media_user__.info = JSON.parse(__media_user__.info) 	
			}
			location = '/media'
		})
	}

	render(props, state)
	{
		return(<div class='register'>
			<div class='d-flex flex-column align-items-center'>
				<div class='h1 headline italic py-3'>
					PTO MEDIA PORTAL
				</div>
				<div class='text-center px-2'>
					<div>Please fill in this form to register for access to the PTO Media Portal</div>
				</div>
				<div class='w-100 py-4'>
					<div class='media-user bg-white p-3'>
						{ <MediaUser onChange={this.userChange} profile={{}}/> }
						<div class='w-100 d-flex justify-content-center mt-4'>
							<button class='btn btn-primary' onClick={this.submitAccreditation}>Submit</button>
						</div>
					</div>
				</div>
			</div>
		</div>)
	}
}

class Pending extends Component {
	render(props, state)
	{
		return(<div>
			<div class='d-flex flex-column align-items-center'>
				<div class='h1 headline italic py-3'>
					PTO MEDIA PORTAL
				</div>
				<div class='text-center px-2'>
					<div>Your access to the PTO Media Portal is waiting for approval.</div>
				</div>
			</div>
		</div>)
	}
}


class MediaRouter extends Component {

	handleRoute = e => {
		//console.log("ROUTE", e)
		return
		if(e.url == "/login" || e.url == "/signup")
		{

		}
		else
		{
			let token = localStorage.removeItem('ptotoken')
			if(!token) route("/login", true)
			/*let data_fetch = await fetchAPI("/auth/verifysession", { method: "post" })
			let data = await data_fetch.json()
			if(data.status != 0) route("/login", true)*/
		}
		/*switch (e.url) {
			case '/profile':
				const isAuthed = await this.isAuthenticated();
				if (!isAuthed) route('/', true);
			break;
		}*/
	};
	
	render(props, state) {
		return(
			<Fragment>
				<Router onChange={this.handleRoute}>
					<Media path="/media" events={props.events}/>
				</Router>
			</Fragment>
		); 		
	}
}

function load() {
    return new Promise(function(resolve, reject) {
        window.onload = resolve;
    });
}

let media = document.getElementById('media')
if(media)
{
	Promise.resolve().then(_ => {
		return new Promise(success => {
			let linkQuill = document.createElement("link")
			linkQuill.rel = "stylesheet"
			linkQuill.href = "https://cdn.quilljs.com/1.3.6/quill.snow.css"
			document.getElementsByTagName("head")[0].appendChild(linkQuill)

			linkQuill.onload = success 
			linkQuill.onerror = () => { console.log('error 2') } 
		})
	}).then( _ => {
		return new Promise(success => {
			let scriptQuill = document.createElement("script")
			scriptQuill.src = "https://cdn.quilljs.com/1.3.6/quill.min.js"
			document.getElementsByTagName("head")[0].appendChild(scriptQuill)

			scriptQuill.onload = success 
			scriptQuill.onerror = () => { console.log('error 1') } 
		})
	}).then(_ => {
		fetch("/api/media/me")
		.then((r) => r.json())
		.then((d) => {
			if(d.session)
			{
				__session__ = d.session
				__media_user__ = d.media_user
				if(__media_user__)
				{
					__media_user__.info = JSON.parse(__media_user__.info) 	
				}

				if(__media_user__ == null)
				{
					render(<NotRegistered/>, media)
				}
				else if(__media_user__.access == "pending")
				{
					render(<Pending/>, media)
				}
				else if(__media_user__.terms_version != terms_version)
				{
					render(<TermsConditions type={__media_user__.info.type}/>, media)
				}
				else
				{
					render(<MediaRouter events={d.events}/>, media)
				}
			}
			else
			{
				render(<Login/>, media)
			}
		})
	})
}

