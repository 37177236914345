let newsSubmitInput = document.getElementById('news-submit-input')

let newsSubmitButton = document.getElementById('news-submit-button')
if(newsSubmitButton)
{
	newsSubmitButton.addEventListener("click", (e) => {
		let value = newsSubmitInput.value.trim()
		console.log(value)
		fetch("/api/news/submit", {
			method: "post",
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ link: value })
		})
		.then(function(response) { if(response.ok) return response.json(); return Promise.reject(response); })
		.then(function(data) {
			console.log(data)
		})
	})
}
