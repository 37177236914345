const tooltipButtons = document.querySelectorAll('.tooltip-button');

// Function to hide all tooltips
function hideAllTooltips() {
	tooltipButtons.forEach((button) => {
		const tooltip = document.getElementById(button.getAttribute('popovertarget'));
		tooltip.classList.add('hidden');
	});
}

tooltipButtons.forEach((button) => {
	const tooltip = document.getElementById(button.getAttribute('popovertarget'));
	tooltip.classList.add('hidden');

	button.addEventListener('click', (event) => {
		event.stopPropagation();
		const tooltipIsOpen = !tooltip.classList.contains('hidden')

		if (tooltipIsOpen) {
			hideAllTooltips();
			tooltip.classList.add('hidden');
		} else {
			tooltip.classList.remove('hidden')
		}
	});
});

document.addEventListener('click', (event) => {
	// Check if the click is outside any tooltip element
	let clickInsideTooltip = false;
	tooltipButtons.forEach((button) => {
		const tooltip = document.getElementById(button.getAttribute('popovertarget'));
		if (tooltip.contains(event.target)) {
			clickInsideTooltip = true;
		}
	});

	if (!clickInsideTooltip) {
		hideAllTooltips();
	}
});
